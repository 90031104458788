import { useApolloClient } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { validateProjectName } from "../../pages/account/view/AccountTab";
import { CreateAccountPayload } from "../../types/rest";
import { createNewAccount } from "../../utils/rest/new-account";
import styles from "./new-account-dialog.module.scss";

interface NewAccountDialogProps extends DialogProps {
  onSuccess: () => void;
  onClose: (e: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

export const NewAccountDialog: React.FC<NewAccountDialogProps> = ({
  onSuccess,
  onClose,
  ...dialogProps
}) => {
  const [details, setDetails] = useState<CreateAccountPayload>({
    displayName: "",
  });
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const [projectNameError, setProjectNameError] = useState<string | null>(null);

  function handleDisplayNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDetails({ ...details, displayName: e.target.value });
    const error = validateProjectName(e.target.value);
    if (error !== null) {
      setProjectNameError(error);
    } else {
      setProjectNameError(null);
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      const resp = await createNewAccount(details);

      if (resp.status !== 201) {
        throw new Error("Failed to create project");
      }

      // Clear the form
      setDetails({ displayName: "" });
      // Clear the apollo cache
      client.resetStore();
      // Call the onSuccess function to close the dialog.
      onSuccess();
    } catch (e) {
      console.error("Failed to create project", {
        error: e,
        details,
      });
      enqueueSnackbar("Error creating project", {
        variant: "error",
        key: "create-project-error",
      });
    }
  };

  function handleClose(e: {}, reason: "backdropClick" | "escapeKeyDown") {
    setDetails({
      displayName: "",
    });
    setProjectNameError(null);
    onClose(e, reason);
  }

  function stopPropagation(e: React.KeyboardEvent) {
    e.key !== "Escape" && e.stopPropagation();
  }

  return (
    <Dialog
      {...dialogProps}
      onClose={handleClose}
      classes={{ paper: styles.dialog }}
    >
      <Stack
        alignItems="center"
        spacing={2}
        paddingY={3}
        component="form"
        onSubmit={handleSubmit}
      >
        <Typography variant="h6" fontWeight={700}>
          Project Details
        </Typography>

        <TextField
          size="small"
          label="Project Name"
          value={details.displayName}
          autoComplete="off"
          autoFocus
          onChange={handleDisplayNameChange}
          error={!!projectNameError}
          helperText={projectNameError}
          // We need to prevent some key events from propagating to
          // the open Settings Menu listeners so the textfield doesn't lose focus.
          onKeyDown={stopPropagation}
          data-testid="new-account-name-input"
        />

        <Button
          type="submit"
          size="large"
          variant="contained"
          disabled={!!projectNameError || !details.displayName}
          data-testid="new-account-create-button"
        >
          Continue
        </Button>
      </Stack>
    </Dialog>
  );
};
