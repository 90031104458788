import { useSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import { Kind } from "../../../graphql/generated";
import { ResourceKind } from "../../../types/resources";
import { ApplyResponse } from "../../../types/rest";
import { BPParameterizedResource } from "../../../utils/classes/resource";
import { applyResources } from "../../../utils/rest/apply-resources";
import { AddToLibraryDialog } from "../AddToLibraryDialog";
import { DialogAction } from "./utils";

export interface NewResource {
  apiVersion: string;
  kind: ResourceKind;
  resourceType: string;
  parameters: { [key: string]: any };
}

interface SaveToLibraryAction extends DialogAction<ApplyResponse> {
  // The resource to save to the library
  newResource?: NewResource;

  // The resource to save as a library resource. This is used on the library page where we
  // are creating a new resource and already have a BPResourceConfiguration.
  bpResource?: BPParameterizedResource;

  // The names of the existing resources of the same kind to ensure we don't use the same
  // name.
  existingNames: string[];
}

export interface SaveToLibraryDialogContextValue {
  saveToLibrary: (action: SaveToLibraryAction) => void;
}

const defaultValue: SaveToLibraryDialogContextValue = {
  saveToLibrary: () => {
    throw new Error("SaveToLibraryDialogProvider not specified");
  },
};

export const SaveToLibraryDialogContext = createContext(defaultValue);

export const SaveToLibraryDialogProvider: React.FC = ({ children }) => {
  const [action, setAction] = useState<SaveToLibraryAction | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  async function saveToLibrary(action: SaveToLibraryAction) {
    setAction(action);
    setOpen(true);
  }

  async function save(name: string) {
    if (action == null) {
      return;
    }

    let bpResource = action.bpResource;
    if (bpResource != null) {
      // just need to add the name
      bpResource.metadata.name = name;
    } else {
      // create a BPParameterizedResource from the NewResource
      if (action.newResource == null) {
        throw new Error("library resource or new resource must be specified");
      }
      const newResource = action.newResource;

      bpResource = new BPParameterizedResource({
        apiVersion: newResource.apiVersion,
        kind: newResource.kind,
        metadata: {
          name,
          id: "",
          version: 0,
          displayName: newResource.parameters.displayName,
        },
        spec: {
          parameters: [],
          type: newResource.resourceType,
          disabled: false,
        },
      });
      bpResource.setParamsFromMap(newResource.parameters);
      bpResource.setProcessors(newResource.parameters.processors ?? []);
    }

    try {
      const result = await applyResources([bpResource]);
      enqueueSnackbar(
        `Successfully added ${bpResource.kind} ${name} to Library!`,
        {
          variant: "success",
          autoHideDuration: 3000,
        },
      );
      setOpen(false);

      action?.onSuccess(result);
    } catch (err) {
      console.error("Failed to add resource to library", {
        error: err,
        kind: bpResource.kind,
        name,
      });
      enqueueSnackbar(`Failed to add ${bpResource.kind} ${name} to Library.`, {
        variant: "error",
        autoHideDuration: 5000,
      });
      action?.onError?.(err);
    }

    // reset the state
    setAction(undefined);
  }

  function close() {
    setOpen(false);
    action?.onCancel?.();
    setAction(undefined);
  }

  const kind =
    action?.newResource?.kind ??
    (action?.bpResource?.kind as ResourceKind) ??
    Kind.Source;

  return (
    <SaveToLibraryDialogContext.Provider
      value={{
        saveToLibrary,
      }}
    >
      <AddToLibraryDialog
        onAdd={save}
        open={open}
        onClose={close}
        kind={kind} // arbitrary default, should be specified when needed
        existingNames={action?.existingNames ?? []}
      />
      {children}
    </SaveToLibraryDialogContext.Provider>
  );
};

export function useSaveToLibraryDialog(): SaveToLibraryDialogContextValue {
  return useContext(SaveToLibraryDialogContext);
}
