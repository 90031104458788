import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { APIVersion, UpdateStatus } from "../../types/resources";
import { ConfigurationResponse } from "../../types/rest";
import { applyResources } from "../../utils/rest/apply-resources";

interface ConfirmUpgradeDialogProps extends DialogProps {
  configName: string;
  requiresRouteProcessor: boolean;
  onClose: () => void;
  refetchConfiguration: () => void;
}

export const ConfirmUpgradeDialog: React.FC<ConfirmUpgradeDialogProps> = ({
  configName,
  requiresRouteProcessor,
  refetchConfiguration,
  ...dialogProps
}) => {
  const { enqueueSnackbar } = useSnackbar();
  async function handleUpgrade() {
    try {
      await upgradeToV2(configName);
      enqueueSnackbar("Configuration upgraded successfully.", {
        variant: "success",
      });
      refetchConfiguration();
      dialogProps.onClose();
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar("Failed to upgrade configuration: " + e.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Failed to upgrade configuration.", {
          variant: "error",
        });
      }
    }
  }

  if (requiresRouteProcessor) {
    return (
      <Dialog {...dialogProps}>
        <DialogTitle>
          Cannot upgrade Configuration <strong>{configName}</strong> to Version
          2 beta
        </DialogTitle>

        <DialogContent>
          <Typography>
            This configuration contains processors that are not supported in
            Version 2. Please remove any <strong>Count Telemetry</strong> or{" "}
            <strong>Extract Metric</strong> processors before upgrading.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogProps.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        Upgrade Configuration <strong>{configName}</strong> to API Version 2
        beta?
      </DialogTitle>

      <DialogContent>
        <Typography>
          API Version 2 is still in beta and may not be fully supported.
          Consider first duplicating this configuration and upgrading the
          duplicate.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogProps.onClose}>Cancel</Button>
        <Button color="primary" variant="contained" onClick={handleUpgrade}>
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * UpgradeToV2 upgrades the configuration of the given name to API version v2beta.
 * It first requests the configuration from the API, changes the apiVersion field, and
 * uses /apply to update the configuration.
 */
export async function upgradeToV2(configName: string) {
  const resp = await fetch(`/v1/configurations/${configName}`);
  if (resp.status !== 200) {
    throw new Error("failed to fetch configuration with name " + configName);
  }

  const { configuration } = (await resp.json()) as ConfigurationResponse;
  // TODO: Change to V2 for GA
  configuration.apiVersion = APIVersion.V2Beta;

  const { updates } = await applyResources([configuration]);
  if (updates.length !== 1 || updates[0].status !== UpdateStatus.CONFIGURED) {
    throw new Error(
      "failed to apply new configuration, status " +
        updates[0].status +
        ". " +
        updates[0].reason,
    );
  }
}
