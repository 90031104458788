import { gql } from "@apollo/client";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  GetAgentQuery,
  useRemoveAgentConfigurationMutation,
} from "../../graphql/generated";
import { patchConfigLabel } from "../../utils/patch-config-label";
import { ActionsSection, TitleSection } from "../DialogComponents";
import { ConfigurationSelect } from "./ConfigurationSelect";
import { Config } from "./types";
import mixins from "../../styles/mixins.module.scss";

gql`
  mutation removeAgentConfiguration($input: RemoveAgentConfigurationInput!) {
    removeAgentConfiguration(input: $input) {
      id
      configurationResource {
        rendered
      }
    }
  }
`;

interface ManageConfigFormProps {
  agent: NonNullable<GetAgentQuery["agent"]>;
  configurations: Config[];
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  selectedConfig?: Config;
  setSelectedConfig: React.Dispatch<React.SetStateAction<Config | undefined>>;
}

export const ManageConfigForm: React.FC<ManageConfigFormProps> = ({
  agent,
  configurations,
  editing,
  setEditing,
  selectedConfig,
  setSelectedConfig,
}) => {
  const snackbar = useSnackbar();

  const [removeAgentConfiguration] = useRemoveAgentConfigurationMutation({
    variables: {
      input: {
        agentId: agent.id,
      },
    },
  });

  const configResourceName = agent?.configurationResource?.metadata.name;
  const isRawConfig = configResourceName == null;

  async function onApplyConfiguration() {
    try {
      await patchConfigLabel(agent.id, selectedConfig!.metadata.name);

      setEditing(false);
    } catch (err) {
      console.error("Failed to apply new configuration", {
        error: err,
        agentId: agent.id,
        configurationName: selectedConfig!.metadata.name,
      });
      snackbar.enqueueSnackbar("Failed to change configuration.", {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  }

  function onCancelEdit() {
    setEditing(false);
    setSelectedConfig(
      configurations.find((c) => c.metadata.name === configResourceName),
    );
  }

  // Remove the 'configuration' label and refetch the agent
  async function onRemoveConfiguration() {
    try {
      await removeAgentConfiguration();
      setEditing(false);
    } catch (err) {
      setEditing(false);
      console.error("Failed to remove configuration", {
        error: err,
        agentId: agent.id,
        configurationName: configResourceName,
      });
      snackbar.enqueueSnackbar("Failed to change configuration.", {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  }

  const disableApply =
    selectedConfig == null ||
    selectedConfig.metadata.name === configResourceName;

  return (
    <Dialog
      open={editing}
      onClose={() => {
        setEditing(false);
      }}
    >
      <TitleSection
        title={"Change Configuration"}
        onClose={() => {
          setEditing(false);
        }}
      />
      <DialogContent sx={{ minWidth: "500px" }} dividers>
        <ConfigurationSelect
          setSelectedConfig={setSelectedConfig}
          selectedConfig={selectedConfig}
          configurations={configurations}
        />
      </DialogContent>
      <ActionsSection>
        {!isRawConfig && (
          <Button
            className={mixins["ml-2"]}
            variant="outlined"
            color="error"
            onClick={onRemoveConfiguration}
            data-testid="agent-detach-config-button"
          >
            Detach
          </Button>
        )}
        <Box flexGrow={1} />
        <Button variant="outlined" color="secondary" onClick={onCancelEdit}>
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={onApplyConfiguration}
          classes={{ root: mixins["ml-2"] }}
          disabled={disableApply}
        >
          Apply
        </Button>
      </ActionsSection>
    </Dialog>
  );
};
