import { memo } from "react";
import { BPResourceConfiguration } from "../../../utils/classes";
import { CardMeasurementContent } from "../../CardMeasurementContent/CardMeasurementContent";
import { InlineSourceCard } from "../../Cards/InlineSourceCard";
import { ResourceSourceCard } from "../../Cards/ResourceSourceCard";
import { MinimumRequiredConfig } from "../PipelineGraph";
import { useV1PipelineGraph } from "../PipelineGraphContext";
import { isNodeDisabled } from "./nodeUtils";

function SourceNode({
  data,
}: {
  data: {
    id: string;
    metric: string;
    attributes: Record<string, any>;
    connectedNodesAndEdges: string[];
    configuration: MinimumRequiredConfig;
    refetchConfiguration: () => void;
  };
}) {
  const {
    configuration,
    refetchConfiguration,
    setHoveredSet,
    hoveredSet,
    selectedTelemetryType,
  } = useV1PipelineGraph();
  const { metric, attributes } = data;
  const { title, subtitle, icon, paused } = attributes;

  var sourceIndex = attributes["sourceIndex"];
  if (typeof sourceIndex !== "number") {
    sourceIndex = -1;
  }

  // make sure the resource exists. There could be a timing issue where this node exists
  // but the corresponding resource has been deleted.
  const resource = configuration?.spec?.sources?.[sourceIndex];
  if (resource == null) {
    return null;
  }

  const resourceConfig = new BPResourceConfiguration(resource);

  const isDisabled = isNodeDisabled(selectedTelemetryType, attributes);
  const isNotInHoverSet =
    hoveredSet.length > 0 && !hoveredSet.find((elem) => elem === data.id);

  return (
    <div
      onMouseEnter={() => {
        setHoveredSet(data.connectedNodesAndEdges);
      }}
      onMouseLeave={() => setHoveredSet([])}
    >
      {resourceConfig.isInline() ? (
        <InlineSourceCard
          title={title}
          subtitle={subtitle}
          icon={icon}
          paused={paused}
          resource={resourceConfig}
          disabled={isDisabled || isNotInHoverSet}
          refetchConfiguration={refetchConfiguration}
          onDeleteSuccess={() => setHoveredSet([])}
        />
      ) : (
        <ResourceSourceCard
          title={title}
          subtitle={subtitle}
          icon={icon}
          paused={paused}
          resource={resourceConfig}
          disabled={isDisabled || isNotInHoverSet}
          refetchConfiguration={refetchConfiguration}
          onDeleteSuccess={() => setHoveredSet([])}
        />
      )}
      <CardMeasurementContent>{metric}</CardMeasurementContent>
    </div>
  );
}

export default memo(SourceNode);
