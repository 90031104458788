import { CircularProgress, Stack } from "@mui/material";
import { memo, useEffect } from "react";
import ReactFlow, {
  Controls,
  EdgeChange,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "reactflow";
import { PipelineType, Role } from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import colors from "../../styles/colors";
import { hasPipelineTypeFlag } from "../../types/configuration";
import {
  GRAPH_NODE_OFFSET,
  GRAPH_PADDING,
  Page,
} from "../../utils/graph/utils";
import { hasPermission } from "../../utils/has-permission";
import { ConfigDetailsMenu } from "../ConfigDetailsMenu";
import { DummyProcessorNodeV2 } from "../PipelineGraph/Nodes/DummyProcessorNodeV2";
import UIControlNode from "../PipelineGraph/Nodes/UIControlNode";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { useBPGraph } from "../PipelineGraphV2/BPGraphProvider";
import ConfigurationEdgeV2 from "../PipelineGraphV2/Components/ConfigurationEdgeV2";
import { ConnectorNode } from "../PipelineGraphV2/Components/ConnectorNode";
import DestinationNodeV2 from "../PipelineGraphV2/Components/DestinationNodeV2";
import { FailoverConnectorNodeV2 } from "../PipelineGraphV2/Components/FailoverConnectorNodeV2";
import { ProcessorListNode } from "../PipelineGraphV2/Components/ProcessorListNode";
import { ProcessorNodeV2 } from "../PipelineGraphV2/Components/ProcessorNodeV2";
import { RoundRobinConnectorNodeV2 } from "../PipelineGraphV2/Components/RoundRobinConnectorNodeV2";
import { RoutingConnectorNodeV2 } from "../PipelineGraphV2/Components/RoutingConnectorNodeV2";
import SourceNodeV2 from "../PipelineGraphV2/Components/SourceNodeV2";
import { useV2PipelineGraph } from "../PipelineGraphV2/PipelineGraphV2Context";
import { AttributeName } from "../PipelineGraphV2/types";
import { isSelectable } from "./graph";
import { layoutV2Graph } from "./layout";
import cards from "../Cards/cards.module.scss";

const nodeTypes = {
  connectorNode: ConnectorNode,
  destinationNode: DestinationNodeV2,
  dummyProcessorNode: DummyProcessorNodeV2,
  processorListNode: ProcessorListNode,
  processorNode: ProcessorNodeV2,
  failoverConnectorNode: FailoverConnectorNodeV2,
  routingConnectorNode: RoutingConnectorNodeV2,
  roundrobinConnectorNode: RoundRobinConnectorNodeV2,
  sourceNode: SourceNodeV2,
  uiControlNode: UIControlNode,
};

const edgeTypes = {
  configurationEdge: ConfigurationEdgeV2,
};

export const TARGET_OFFSET_MULTIPLIER = 300;

interface ConfigurationFlowProps {
  period: string;
  selectedTelemetry: string;
  page: Page.Agent | Page.Configuration;
  loading?: boolean;
}

/**
 * ConfigurationFlow renders the PipelineGraph for a configuration
 * @param period the period on which to query for metrics
 * @param selectedTelemetry the telemetry type on which to query for metrics
 * @param loading whether the graph is loading, will show a loading spinner if true
 * @param measurementData optional data from the ConfigurationMetrics subscription
 * @returns
 */
export const ConfigurationFlowV2: React.FC<ConfigurationFlowProps> = memo(
  ({ period, selectedTelemetry, loading }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const reactFlowInstance = useReactFlow();
    const { width, height } = useStoreApi().getState();

    const layoutAlgorithm =
      new URL(window.location.href).searchParams.get("layout") ?? "grid";

    const { readOnlyGraph, configuration, onAddSource, onAddDestination } =
      useV2PipelineGraph();
    const { graph } = useBPGraph();

    const role = useRole();

    const viewPortHeight = getViewPortHeight(configuration, selectedTelemetry);

    useEffect(() => {
      async function layoutGraph() {
        let { nodes, edges } = await layoutV2Graph(
          graph,
          !!readOnlyGraph,
          selectedTelemetry as PipelineType,
          onAddSource,
          onAddDestination,
          layoutAlgorithm,
        );

        setNodes(nodes);
        setEdges(edges);
      }
      layoutGraph();
    }, [
      period,
      readOnlyGraph,
      selectedTelemetry,
      onAddSource,
      onAddDestination,
      setEdges,
      setNodes,
      graph,
      layoutAlgorithm,
    ]);

    useEffect(() => {
      // Fit the view to the graph when the window is resized
      const fitView = function () {
        reactFlowInstance.fitView();
      };

      window.addEventListener("resize", fitView);

      return () => window.removeEventListener("resize", fitView);
    }, [reactFlowInstance]);

    useEffect(() => {
      // Refit the view when our nodes have changed, i.e. one is deleted.
      // By placing this in a setTimeout with delay 0 we
      // ensure this is called on the next event cycle, not right away.
      setTimeout(() => reactFlowInstance.fitView(), 0);
    }, [nodes, reactFlowInstance]);

    useEffect(() => {
      // Refit the view window when the bounds of the react flow have changed.
      setTimeout(() => reactFlowInstance.fitView(), 0);
    }, [height, width, reactFlowInstance]);

    if (loading) {
      return (
        <div style={{ height: viewPortHeight, width: "100%" }}>
          <Stack
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            style={{ background: colors.backgroundWhite }}
          >
            <CircularProgress />
          </Stack>
        </div>
      );
    }

    function handleEdgeChange(changes: EdgeChange[]) {
      onEdgesChange(
        changes.filter((c) => {
          if (readOnlyGraph) return false;
          if (c.type !== "select") return true;
          return isSelectable(c.id);
        }),
      );
    }

    return (
      <div
        style={{ height: viewPortHeight, width: "100%" }}
        className={cards["v2-graph"]}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          // Called by a react flow node when entering the viewport,
          // use to refit when we add a destination or source.
          onNodesChange={(c) => {
            onNodesChange(c);
            reactFlowInstance.fitView();
          }}
          onEdgesChange={handleEdgeChange}
          proOptions={{ account: "paid-pro", hideAttribution: true }}
          nodesConnectable={false}
          nodesDraggable={false}
          fitView={true}
          zoomOnScroll={false}
          zoomOnDoubleClick={false}
          zoomOnPinch={false}
          panOnDrag={false}
          preventScrolling={false}
          deleteKeyCode={[]}
          // This is a hack to hide the graph from the viewport until
          // we have called a fitView on the reactFlowInstance.  This
          // is to prevent the graph from appearing out of view on
          // first load.
          defaultViewport={{ x: 1000, y: 1000, zoom: 1 }}
        >
          <Controls
            showZoom={false}
            showInteractive={false}
            showFitView={false}
            position="bottom-right"
          >
            <ConfigDetailsMenu
              data-testid="config-menu-button"
              configName={configuration?.metadata.name ?? ""}
              iconProps={{ width: "20px", height: "20px" }}
              readOnly={readOnlyGraph || !hasPermission(Role.User, role)}
            />
          </Controls>
        </ReactFlow>
      </div>
    );
  },
);

function getViewPortHeight(
  configuration: MinimumRequiredConfig,
  telemetryType: string,
) {
  if (!configuration || !configuration.graph) {
    return 0;
  }

  const sources = configuration.graph.sources.filter((s) => {
    return hasPipelineTypeFlag(
      telemetryType,
      s.attributes[AttributeName.SupportedTypeFlags],
    );
  });
  const targets = configuration.graph.targets.filter((t) => {
    return hasPipelineTypeFlag(
      telemetryType,
      t.attributes[AttributeName.SupportedTypeFlags],
    );
  });

  return (
    GRAPH_PADDING +
    Math.max(sources?.length || 0, targets?.length || 0) * GRAPH_NODE_OFFSET
  );
}
