import { createContext, useContext, useState } from "react";
import { ResourceKind } from "../../../types/resources";
import { BPResourceConfiguration } from "../../../utils/classes";
import { MinimumRequiredConfig } from "../../PipelineGraph/PipelineGraph";
import {
  ComponentDialog,
  ComponentDialogResult,
} from "../ComponentDialog/ComponentDialog";
import { ConfirmDeleteResourceDialogProvider } from "./useConfirmDeleteResourceDialog";
import { ConfirmResourceInUseWarningDialogProvider } from "./useConfirmResourceInUseWarningDialog";

export interface EditComponentAction {
  kind: ResourceKind;
  resource: BPResourceConfiguration;
  onSuccess?: (result: ComponentDialogResult) => void;
  onCancel?: () => void;
  onDelete?: (closeDialog: () => void) => void;
}

export interface ComponentDialogContextValue {
  editComponent: (action: EditComponentAction) => void;
}

export interface ComponentDialogProviderProps {
  // configuration if editing a Component within a configuration, undefined in the library
  configuration?: MinimumRequiredConfig;
  readOnly?: boolean;
  onSuccess?: (result: ComponentDialogResult) => void;
  onCancel?: () => void;
}

const defaultValue: ComponentDialogContextValue = {
  editComponent: () => {
    throw new Error("ComponentDialogProvider not specified");
  },
};

export const ComponentDialogContext = createContext(defaultValue);

export const ComponentDialogProvider: React.FC<
  ComponentDialogProviderProps
> = ({ configuration, readOnly, onSuccess, onCancel, children }) => {
  const [action, setAction] = useState<EditComponentAction | null>(null);
  const [open, setOpen] = useState(false);

  function editComponent(action: EditComponentAction) {
    setAction(action);
    setOpen(true);
  }

  function onDialogSuccess(result: ComponentDialogResult) {
    // supports onSuccess in provider props and action
    onSuccess?.(result);
    action?.onSuccess?.(result);
    close();
  }

  function onDialogCancel() {
    // supports onCancel in provider props and action
    onCancel?.();
    action?.onCancel?.();
    close();
  }

  function close() {
    setAction(null);
    setOpen(false);
  }

  return (
    <ComponentDialogContext.Provider
      value={{
        editComponent,
      }}
    >
      <ConfirmResourceInUseWarningDialogProvider>
        <ConfirmDeleteResourceDialogProvider>
          <ComponentDialog
            configuration={configuration}
            editingKind={action?.kind ?? null}
            editingComponent={action?.resource ?? null}
            open={open}
            onSuccess={onDialogSuccess}
            onCancel={onDialogCancel}
            onDelete={
              action?.onDelete ? () => action?.onDelete?.(close) : undefined
            }
            readOnly={readOnly}
          />
          {children}
        </ConfirmDeleteResourceDialogProvider>
      </ConfirmResourceInUseWarningDialogProvider>
    </ComponentDialogContext.Provider>
  );
};

export function useComponentDialog(): ComponentDialogContextValue {
  return useContext(ComponentDialogContext);
}
