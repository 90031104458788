import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Kind,
  Maybe,
  Parameter,
  PipelineType,
  ResourceConfiguration,
  useGetResourcesLazyQuery,
  useGetResourceTypesLazyQuery,
} from "../../../graphql/generated";
import { useUlid } from "../../../graphql/shared-queries/get-ulid";
import { ResourceKind } from "../../../types/resources";
import { BPResourceConfiguration } from "../../../utils/classes";
import { resourceTypeOfKind } from "../../../utils/classes/component-type";
import { telemetryTypesValue } from "../../../utils/classes/resource-configuration";
import { useAppLoading } from "../../AppLoading/useAppLoading";
import {
  DialogResource,
  NewResourceDialog,
  ResourceType,
} from "../../ResourceDialog";
import { DialogAction } from "./utils";

// The result of the NewResourceAction will be a BPResourceConfiguration representing
// either an inline resource or a named library resource. Check
// BPResourceConfiguration.isInline() if the handling for each case needs to be different.
export interface NewResourceAction
  extends DialogAction<BPResourceConfiguration> {
  // The kind of resource that is being created.
  kind: ResourceKind;

  // The platform associated with the configuration. If unspecified "unknown" will be
  // used.
  platform?: string;

  // The pipelineType of the graph where the resource is being created. If specified, this
  // will filter the list of resources to only include resources that support the
  // specified pipeline type.
  pipelineType?: PipelineType;

  // fromLibrary is used to indicate that the resource is being created from the library
  // page.
  fromLibrary?: boolean;

  // filterResourceTypes can be used to filter the resource types that are displayed in
  // the dialog. If unspecified, resources will be filtered by platform.
  filterResourceTypes?: (resourceTypes: ResourceType[]) => ResourceType[];

  // defaultParameters can be used to set default parameters for the resource. These will
  // override defaults specified in the resource type.
  defaultParameters?: Maybe<Parameter[]>;
}

export interface NewResourceDialogContextValue {
  createNewResource: (action: NewResourceAction) => void;
  closeNewResourceDialog: () => void;
}

const defaultValue: NewResourceDialogContextValue = {
  createNewResource: () => {
    throw new Error("NewResourceDialogProvider not specified");
  },
  closeNewResourceDialog: () => {
    throw new Error("NewResourceDialogProvider not specified");
  },
};

export const NewResourceDialogContext = createContext(defaultValue);

export const NewResourceDialogProvider: React.FC = ({ children }) => {
  const [action, setAction] = useState<NewResourceAction | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>();
  const [libraryResources, setLibraryResources] = useState<DialogResource[]>();

  const { setAppLoading } = useAppLoading();
  const ulid = useUlid();

  // ----------------------------------------------------------------------
  // network requests

  function onError(e: unknown) {
    action?.onError?.(e);
    setOpen(false);
  }

  const [fetchLibraryResources] = useGetResourcesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const libraryResources = filterLibraryResources(
        data.resources.map((r) => ({
          metadata: {
            name: r.metadata.name,
          },
          spec: r.spec,
        })),
        action?.pipelineType,
      );
      setLibraryResources(libraryResources);
    },
    onError,
  });

  const [fetchResourceTypes] = useGetResourceTypesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const resourceTypes = filterResourceTypes(
        data.resourceTypes,
        action?.pipelineType,
      );
      setResourceTypes(resourceTypes);
    },
    onError,
  });

  // ----------------------------------------------------------------------

  // trigger fetching of resource types and library resources when the dialog is opened
  useEffect(() => {
    if (open && action != null) {
      setResourceTypes(undefined);
      setLibraryResources(undefined);

      fetchResourceTypes({
        variables: { kind: resourceTypeOfKind(action.kind) },
      });
      fetchLibraryResources({ variables: { kind: action.kind } });
    }
  }, [open, action, fetchResourceTypes, fetchLibraryResources]);

  // clear resource types and library resources when the dialog is closed
  useEffect(() => {
    if (!open) {
      setAction(undefined);
      setResourceTypes(undefined);
      setLibraryResources(undefined);
    }
  }, [open]);

  // determine if we have the information we need to display the dialog
  const isEditing = open && action != null;
  const isReady = resourceTypes != null && libraryResources != null;
  const isVisible = isEditing && isReady;
  useEffect(() => {
    setAppLoading(isEditing && !isReady);
  }, [isEditing, isReady, setAppLoading]);

  // ----------------------------------------------------------------------
  // handlers

  const handleSaveNew = useCallback(
    async (
      parameters: { [key: string]: any },
      resourceType: ResourceType,
      processors?: ResourceConfiguration[] | undefined,
      type?: string,
    ) => {
      const newResource = new BPResourceConfiguration();
      newResource.id = await ulid.new();
      newResource.type = resourceType.metadata.name;
      newResource.processors = processors;
      newResource.setParamsFromMap(parameters);
      await action?.onSuccess?.(newResource);
      setOpen(false);
    },
    [action, ulid],
  );

  const handleSaveExisting = useCallback(
    async (resource: DialogResource) => {
      const newResource = new BPResourceConfiguration();
      newResource.id = await ulid.new();
      newResource.name = resource.metadata.name;
      await action?.onSuccess?.(newResource);
      setOpen(false);
    },
    [action, ulid],
  );

  function onClose() {
    action?.onCancel?.();
    setOpen(false);
  }

  // ----------------------------------------------------------------------
  // context functions

  function createNewResource(action: NewResourceAction) {
    setAction(action);
    setOpen(true);
  }

  function closeNewResourceDialog() {
    setOpen(false);
  }

  return (
    <NewResourceDialogContext.Provider
      value={{ createNewResource, closeNewResourceDialog }}
    >
      <NewResourceDialog
        platform={action?.platform ?? "unknown"}
        pipelineType={action?.pipelineType}
        kind={action?.kind ?? Kind.Connector}
        resourceTypes={
          action?.filterResourceTypes
            ? action?.filterResourceTypes(resourceTypes ?? [])
            : (resourceTypes ?? [])
        }
        resources={libraryResources ?? []}
        existingResourceNames={
          libraryResources?.map((r) => r.metadata.name) ?? []
        }
        open={isVisible}
        onSaveNew={handleSaveNew}
        onSaveExisting={handleSaveExisting}
        onClose={onClose}
        fromLibrary={action?.fromLibrary}
        defaultParameters={action?.defaultParameters}
      />
      {children}
    </NewResourceDialogContext.Provider>
  );
};

export function useNewResourceDialog() {
  return useContext(NewResourceDialogContext);
}

// filterLibraryResources filters the library resources to only include resources that are
// configured for the specified pipeline type. we do this by looking for the
// telemetry_types parameter and checking if it includes the specified pipeline type. this
// relies on the fact that this parameter is consistently used to identify the pipeline
// type in resources.
function filterLibraryResources(
  resources: DialogResource[],
  pipelineType: PipelineType | undefined,
) {
  if (pipelineType == null) return resources;
  const matchValue = telemetryTypesValue(pipelineType);
  return resources.filter((r) => {
    const telemetryTypes = r.spec.parameters?.find(
      (p) => p.name === "telemetry_types",
    );
    return telemetryTypes == null || telemetryTypes.value.includes(matchValue);
  });
}

// filterResourceTypes filters the resource types to only include those that support the
// specified pipeline type.
function filterResourceTypes(
  resourceTypes: ResourceType[],
  pipelineType: PipelineType | undefined,
) {
  if (pipelineType == null) return resourceTypes;
  return resourceTypes.filter((rt) => {
    return rt.spec.telemetryTypes.includes(pipelineType);
  });
}
