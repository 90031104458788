import { gql } from "@apollo/client";
import { IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useConfigDetailsMenuQuery } from "../../graphql/generated";
import { usePipelineGraph } from "../../hooks/usePipelineGraph";
import { useRefetchOnConfigurationChange } from "../../hooks/useRefetchOnConfigurationChanges";
import { AdvancedConfigDialog } from "../../pages/configurations/configuration/AdvancedConfigDialog";
import { APIVersion } from "../../types/resources";
import { BPConfiguration } from "../../utils/classes";
import { nameAndVersion } from "../../utils/version-helpers";
import { ConfirmUpgradeDialog } from "../ConfirmUpgradeDialog";
import { CountChip } from "../CountChip";
import { ExtensionsDialog } from "../ExtensionsDialog";
import { SettingsIcon } from "../Icons";
import styles from "./config-details-menu.module.scss";

gql`
  query ConfigDetailsMenu($configName: String!) {
    configuration(name: $configName) {
      apiVersion
      metadata {
        name
        id
        version
      }
      spec {
        extensions {
          name
          id
          type
        }
      }
      status {
        latest
      }
    }

    v2Enabled: featureEnabled(flagID: "v2Configuration")
  }
`;

interface ConfigDetailsMenuProps {
  configName: string;
  menuButtonClassname?: string;
  iconProps?: React.ComponentProps<typeof SettingsIcon>;
  readOnly?: boolean;
}

export const ConfigDetailsMenu: React.FC<ConfigDetailsMenuProps> = ({
  configName,
  iconProps,
  menuButtonClassname,
  readOnly,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [advancedDialogOpen, setAdvancedDialogOpen] = useState(false);
  const [extensionsDialogOpen, setExtensionsDialogOpen] = useState(false);
  const [extensionsCount, setExtensionsCount] = useState<undefined | number>();
  const [v2UpgradeAllowed, setV2UpgradeAllowed] = useState(false);
  const [confirmUpgradeOpen, setConfirmUpgradeOpen] = useState(false);

  const { configuration, refetchConfiguration } = usePipelineGraph();
  const version = configuration?.metadata?.version;
  const requiresRouteProcessor = new BPConfiguration(
    configuration,
  ).requiresRouteReceiver();

  const versionedName = nameAndVersion(configName, version);
  const { refetch } = useConfigDetailsMenuQuery({
    variables: {
      configName: versionedName,
    },
    skip: version == null,
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (
        data.configuration?.apiVersion === APIVersion.V1 &&
        data.v2Enabled &&
        data.configuration?.status?.latest
      ) {
        setV2UpgradeAllowed(true);
      } else {
        setV2UpgradeAllowed(false);
      }
      setExtensionsCount(data.configuration?.spec.extensions?.length);
    },
  });

  useRefetchOnConfigurationChange(configName, refetch);

  async function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAdvancedDialogClick() {
    setAdvancedDialogOpen(true);
    setAnchorEl(null);
  }

  function handleExtensionsDialogClick() {
    setExtensionsDialogOpen(true);
    setAnchorEl(null);
  }

  function handleV2Upgrade() {
    setConfirmUpgradeOpen(true);
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        data-testid="config-menu-button"
        id="config-menu-button"
        aria-controls={!!anchorEl ? "config-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl ? "true" : undefined}
        onClick={handleClick}
        className={menuButtonClassname}
      >
        <SettingsIcon {...iconProps} />
      </IconButton>
      <Menu
        id="config-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "config-menu-button",
        }}
        slotProps={{
          paper: {
            className: styles.paper,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-testid="config-details-menu"
      >
        <MenuItem onClick={handleAdvancedDialogClick}>
          <ListItemText>Advanced Configuration</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleExtensionsDialogClick}>
          <ListItemText>Manage Extensions</ListItemText>
          <ListItemText className={styles.listItemChip}>
            {extensionsCount != null && <CountChip count={extensionsCount} />}
          </ListItemText>
        </MenuItem>
        {v2UpgradeAllowed && (
          <MenuItem onClick={handleV2Upgrade}>
            <ListItemText>Upgrade to v2 beta</ListItemText>
          </MenuItem>
        )}
      </Menu>

      <AdvancedConfigDialog
        open={advancedDialogOpen}
        onClose={() => {
          setAdvancedDialogOpen(false);
          setAnchorEl(null);
        }}
        onSuccess={() => {
          setAdvancedDialogOpen(false);
          setAnchorEl(null);
        }}
        readOnly={readOnly}
      />

      <ExtensionsDialog
        open={extensionsDialogOpen}
        closeDialog={() => setExtensionsDialogOpen(false)}
      />

      <ConfirmUpgradeDialog
        open={confirmUpgradeOpen}
        onClose={() => setConfirmUpgradeOpen(false)}
        configName={configName}
        requiresRouteProcessor={requiresRouteProcessor}
        refetchConfiguration={refetchConfiguration}
      />
    </>
  );
};
