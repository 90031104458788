export enum AgentStatus {
  DISCONNECTED = 0,
  CONNECTED = 1,
  ERROR = 2,
  COMPONENT_FAILED = 4,
  DELETED = 5,
  CONFIGURING = 6,
  UPGRADING = 7,
  PENDING = 8,
}

export enum AgentFeatures {
  AGENT_SUPPORTS_UPGRADE = 0x0001,
  AGENT_SUPPORTS_SNAPSHOTS = 0x0002,
  AGENT_SUPPORTS_MEASUREMENTS = 0x0004,
  AGENT_SUPPORTS_LOG_BASED_METRICS = 0x0008,
  AGENT_SUPPORTS_SNAPSHOT_SEARCH = 0x0010,
  AGENT_SUPPORTS_REMOTE_CONFIGURATION = 0x0020,
  AGENT_REPORTS_REMOTE_CONFIGURATION = 0x0040,
  AGENT_REPORTS_HEALTH_METRICS = 0x0080,
}

export function hasAgentFeature(
  agent: { features: number },
  feature: AgentFeatures,
): boolean {
  return (agent.features & feature) !== 0;
}
