import { createContext, useContext, useState } from "react";
import { FormValues } from "../../ResourceConfigForm";
import { RolloutOptionsDialog } from "../RolloutOptionsDialog.tsx/RolloutOptionsDialog";
import { DialogAction } from "./utils";

export interface RolloutOptionsDialogResult {
  rolloutType: string;
  formValues: FormValues;
}

interface EditRolloutOptions extends DialogAction<RolloutOptionsDialogResult> {
  configurationName: string;
}

export interface RolloutOptionsDialogContextValue {
  editRolloutOptions: (action: EditRolloutOptions) => void;
}

const defaultValue: RolloutOptionsDialogContextValue = {
  editRolloutOptions: () => {
    throw new Error("RolloutOptionsDialogProvider not specified");
  },
};

export const RolloutOptionsDialogContext = createContext(defaultValue);

export interface RolloutOptionsDialogProviderProps {
  readOnly?: boolean;
}

export const RolloutOptionsDialogProvider: React.FC<
  RolloutOptionsDialogProviderProps
> = ({ children, readOnly }) => {
  const [action, setAction] = useState<EditRolloutOptions | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  function editRolloutOptions(action: EditRolloutOptions) {
    setAction(action);
    setOpen(true);
  }

  function close() {
    setAction(undefined);
    setOpen(false);
  }

  return (
    <RolloutOptionsDialogContext.Provider value={{ editRolloutOptions }}>
      {action && (
        <RolloutOptionsDialog
          onCancel={close}
          onError={close}
          onSave={(rolloutType, formValues) => {
            action.onSuccess({
              rolloutType,
              formValues,
            });
            close();
          }}
          open={open}
          readOnly={readOnly}
          configName={action.configurationName}
        />
      )}
      {children}
    </RolloutOptionsDialogContext.Provider>
  );
};

export function useRolloutOptionsDialog(): RolloutOptionsDialogContextValue {
  return useContext(RolloutOptionsDialogContext);
}
