import { cloneDeep, isEmpty } from "lodash";
import {
  Kind,
  Maybe,
  Parameter,
  ParameterizedResource,
  PipelineType,
  ResourceConfiguration,
  Route,
  Routes,
} from "../../graphql/generated";
import { APIVersion } from "../../types/resources";
import { trimVersion } from "../version-helpers";
import { ComponentType } from "./component-type";
import { BPParameterizedResource } from "./resource";

export class BPResourceConfiguration implements ResourceConfiguration {
  id?: Maybe<string> | undefined;
  name?: Maybe<string> | undefined;
  displayName: Maybe<string> | undefined;
  type?: Maybe<string> | undefined;
  parameters?: Maybe<Parameter[]> | undefined;
  processors?: Maybe<ResourceConfiguration[]> | undefined;
  disabled: boolean;
  recommendation?: Maybe<string> | undefined;
  routes?: Maybe<Routes> | undefined;
  constructor(rc?: ResourceConfiguration) {
    this.id = rc?.id;
    this.name = rc?.name;
    this.displayName = rc?.displayName;
    this.type = rc?.type;
    this.parameters = rc?.parameters;
    this.processors = rc?.processors;
    this.disabled = rc?.disabled ?? false;
    this.recommendation = rc?.recommendation;
    this.routes = rc?.routes;
  }

  clone(): BPResourceConfiguration {
    return new BPResourceConfiguration(this);
  }

  isInline(): boolean {
    return isEmpty(this.name);
  }

  /**
   *
   * @returns the `id` field of the resource configuration and
   *  throws an error if its empty or null.
   */
  ID(): string {
    if (this.id == null || this.id === "") {
      throw new Error("ResourceConfiguration ID is null or empty");
    }
    return this.id;
  }

  /**
   * returns a human-readable name for the resource configuration.
   * Prefers displayName, then name, then ID.
   */
  readableName(): string {
    return this.displayName || this.name || this.ID();
  }

  /**
   * returns the component path of the resource configuration, i.e.
   * `sources/{id}`, `destinations/{id}`, or `processors/{id}`
   * @param componentType "sources", "destinations", or "processors"
   * @returns
   */
  componentPath(componentType: ComponentType): string {
    return `${componentType}/${this.ID()}`;
  }

  // setParamsFromMap will set the parameters from Record<string, any>.
  // If the "name" key is specified it will set the name field of the ResourceConfiguration.
  // If the "processors" key is specified it will set the processors value.
  // It will not set undefined or null values to parameters.
  setParamsFromMap(map: Record<string, any>) {
    // Set name field if present
    if (map.name != null && map.name !== "") {
      this.name = map.name;
      delete map.name;
    }

    // Set displayName field if present
    if (map.displayName != null) {
      this.displayName = map.displayName;
      delete map.displayName;
    }

    // Set processors field if present
    if (map.processors != null) {
      this.processors = map.processors;
      delete map.processors;
    }

    // Set the recommendation field if present
    if (map.recommendation != null) {
      this.recommendation = map.recommendation;
      delete map.recommendation;
    }

    // Set the parameters only if their values are not nullish.
    const parameters = Object.entries(map).reduce<Parameter[]>(
      (params, [name, value]) => {
        if (value != null) {
          params.push({ name, value });
        }
        return params;
      },
      [],
    );

    this.parameters = parameters;
  }

  setProcessors(values: any[]) {
    if (values.length > 0) {
      this.processors = values;
    }
  }

  /**
   * removeComponentPathFromPipeline mutates the resource configuration to remove the componentPath from the pipeline of type telemetryType.
   */
  removeComponentPathFromPipeline(
    pipelineType: PipelineType,
    componentPath: string,
    routeId?: string,
  ) {
    const newRoutes = this.copyRoutes();
    updateRouteInPipeline(
      pipelineType,
      newRoutes,
      (r) => {
        r.components = r.components.filter((c) => c !== componentPath);
      },
      routeId,
    );

    this.routes = newRoutes;
  }

  /**
   * removeComponentPathFromAllPipelines mutates the resource configuration to remove the componentPath from all pipeline types.
   */
  removeComponentPathFromAllPipelines(componentPath: string) {
    const newRoutes = this.copyRoutes();
    for (const pipelineType of Object.values(PipelineType)) {
      switch (pipelineType) {
        case PipelineType.Logs:
          newRoutes.logs = newRoutes.logs?.map((r) => ({
            ...r,
            components: r.components.filter((c) => c !== componentPath),
          }));
          break;
        case PipelineType.Metrics:
          newRoutes.metrics = newRoutes.metrics?.map((r) => ({
            ...r,
            components: r.components.filter((c) => c !== componentPath),
          }));
          break;
        case PipelineType.Traces:
          newRoutes.traces = newRoutes.traces?.map((r) => ({
            ...r,
            components: r.components.filter((c) => c !== componentPath),
          }));
          break;
      }
    }
    this.routes = newRoutes;
  }

  /**
   * addPath inserts a componentPath into the pipeline of the given telemetryType.
   * It will add the componentPath to the route at index 0 unless routeId is specified.
   * If routeId is specified, it will add the componentPath to the route with the matching ID.
   * It will create a new route if the routeId does not exist.
   * @param telemetryType
   * @param componentPath
   */
  addPath(pipelineType: PipelineType, componentPath: string, routeId?: string) {
    const newRoutes = this.copyRoutes();
    updateRouteInPipeline(
      pipelineType,
      newRoutes,
      (r) => {
        if (!r.components.some((c) => c === componentPath)) {
          r.components.push(componentPath);
        }
      },
      routeId,
    );
    this.routes = newRoutes;
  }

  toParameterizedResource(
    kind: Kind,
    name?: string,
    displayName?: string,
  ): BPParameterizedResource {
    // default name and displayName to the current name and displayName
    name ??= this.name ?? "";
    displayName ??= this.displayName ?? undefined;
    const id = this.id ?? name;

    return new BPParameterizedResource({
      apiVersion: APIVersion.V1,
      kind,
      metadata: {
        name,
        id,
        version: 0,
        displayName,
      },
      spec: {
        parameters: this.parameters,
        processors: this.processors,
        type: this.type!,
        disabled: this.disabled,
      },
    });
  }

  // toResource will convert the ResourceConfiguration to a BPResource. This is useful for
  // converting inline resources to library resources.
  //
  // It returns a new library resource and a reference to that resource that can be used
  // in a configuration.
  toLibraryResource(
    kind: Kind,
    name: string,
    displayName: string | undefined,
  ): { resource: BPParameterizedResource; reference: BPResourceConfiguration } {
    // require this.isInline() to be true?
    return {
      resource: this.toParameterizedResource(kind, name, displayName),
      reference: this.toLibraryReference(name),
    };
  }

  // toLibraryReference will convert the ResourceConfiguration to a
  // BPResourceConfiguration that references the resource by name. This is useful for
  // converting inline resources to library resources in a configuration. It will preserve
  // the id, disabled, processors, and routes but everything else will be defined in the
  // library resource.
  toLibraryReference(
    name: string, // name of the resource
  ): BPResourceConfiguration {
    return new BPResourceConfiguration({
      name,
      id: this.id,
      disabled: this.disabled,
      processors: this.processors,
      routes: this.routes,
    });
  }

  toInlineResource(
    libraryResource: ParameterizedResource,
  ): BPResourceConfiguration {
    const inline = this.clone();

    // clear the name and set the type and parameters from the library resource
    inline.name = undefined;
    inline.type = libraryResource.spec.type;
    inline.parameters = libraryResource.spec.parameters;

    return inline;
  }

  copyRoutes(): Routes {
    return cloneDeep(this.routes) ?? { logs: [], metrics: [], traces: [] };
  }
}

// isResourceType returns true if the specified ResourceConfiguration is of the given
// type, ignoring the version.
export function isResourceType(
  rc: ResourceConfiguration,
  type: string,
): boolean {
  return trimVersion(rc.type ?? "") === type;
}

// getParameterValue will return the value of the parameter with the given name or a
// defaultValue if it doesn't exist.
export function getParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  defaultValue?: T,
): T | undefined {
  const parameter = rc.parameters?.find((p) => p.name === name);
  return parameter?.value ?? defaultValue;
}

// equalsTelemetryType will return true if the ResourceConfiguration has a single
// telemetry type parameter with the given value.
export function equalsTelemetryType(
  rc: ResourceConfiguration,
  telemetryType: string,
  name: string = "telemetry_types",
): boolean {
  const value = getParameterValue<string[]>(rc, name, []);
  return value?.length === 1 && value[0] === telemetryType;
}

// equalsParameterValue will return true if the parameter with the given name has the
// given value. null and undefined are treated as equal.
export function equalsParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  value: T | undefined | null,
): boolean {
  if (value == null) {
    // handle value == null or value == undefined
    return getParameterValue(rc, name) == null;
  }
  return getParameterValue(rc, name) === value;
}

// editParameterValue will update the value of the parameter with the given name, adding a
// new parameter if it does not exist.
export function editParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  updater: (value?: T) => T,
): T {
  const parameter = rc.parameters?.find((p) => p.name === name);
  if (parameter != null) {
    parameter.value = updater(parameter.value);
    return parameter.value;
  } else {
    if (rc.parameters == null) {
      rc.parameters = [];
    }
    const value = updater(undefined);
    rc.parameters.push({ name, value });
    return value;
  }
}

/**
 * getNextComponents returns all of the component paths that are in the routes of the resource configuration.
 *
 * @param rc a resource configuration
 * @param pipelineType "logs", "metrics", or "traces"
 * @returns
 */
export function getNextComponentPaths(
  rc: ResourceConfiguration,
  pipelineType: PipelineType,
  routeId?: string,
): string[] {
  if (routeId == null) {
    return getAllNextComponentPaths(rc, pipelineType);
  }
  return getRouteForResourceConfig(rc, pipelineType, routeId).components;
}

export function getAllNextComponentPaths(
  rc: ResourceConfiguration,
  pipelineType: PipelineType,
): string[] {
  return getRoutesInPipeline(rc, pipelineType).reduce<string[]>((acc, r) => {
    return [...acc, ...r.components];
  }, []);
}

export function getRoutesInPipeline(
  rc: ResourceConfiguration,
  pipelineType: PipelineType,
): Route[] {
  switch (pipelineType) {
    case PipelineType.Logs:
      return rc.routes?.logs ?? [];
    case PipelineType.Metrics:
      return rc.routes?.metrics ?? [];
    case PipelineType.Traces:
      return rc.routes?.traces ?? [];
    default:
      return [];
  }
}

/**
 * getRoutesForResourceConfig returns the routes for the given telemetry type.
 */
export function getRouteForResourceConfig(
  rc: ResourceConfiguration,
  pipelineType: PipelineType,
  routeId?: string,
): Route {
  return (
    getRoutesInPipeline(rc, pipelineType)?.find((r) => r.id === routeId) ?? {
      components: [],
    }
  );
}

export function newEmpty(id: string): BPResourceConfiguration {
  return new BPResourceConfiguration({
    id,
    parameters: [],
    processors: [],
    disabled: false,
  });
}

/**
 * ensurePipelineType ensures that the pipelineType key exists in the passed route object.
 */
function ensurePipelineType(pipelineType: PipelineType, routes: Routes) {
  switch (pipelineType) {
    case PipelineType.Logs:
      if (routes.logs == null) {
        routes.logs = [];
      }
      break;
    case PipelineType.Metrics:
      if (routes.metrics == null) {
        routes.metrics = [];
      }
      break;
    case PipelineType.Traces:
      if (routes.traces == null) {
        routes.traces = [];
      }
  }
}

/**
 * updateRouteInPipeline updates the route for pipelineType with the given function.
 * If routeId is specified it will ensure that a route with such ID exists before
 * applying the function.  If routeId is null, it will apply the function to the first
 * route in the pipeline.
 * @param pipelineType
 * @param routes
 * @param updater
 * @param routeId
 */
function updateRouteInPipeline(
  pipelineType: PipelineType,
  routes: Routes,
  updater: (r: Route) => void,
  routeId?: string,
) {
  ensurePipelineType(pipelineType, routes);
  switch (pipelineType) {
    case PipelineType.Logs:
      if (routeId) {
        if (!routes.logs!.some((r) => r.id === routeId)) {
          routes.logs!.push({ id: routeId, components: [] });
        }
        updater(routes.logs!.find((r) => r.id === routeId)!);
        break;
      }
      if (!routes.logs?.[0]) {
        routes.logs![0] = { components: [] };
      }
      updater(routes.logs![0]);
      break;
    case PipelineType.Metrics:
      if (routeId) {
        if (!routes.metrics!.some((r) => r.id === routeId)) {
          routes.metrics!.push({ id: routeId, components: [] });
        }
        updater(routes.metrics!.find((r) => r.id === routeId)!);
        break;
      }
      if (!routes.metrics?.[0]) {
        routes.metrics![0] = { components: [] };
      }
      updater(routes.metrics![0]);

      break;
    case PipelineType.Traces:
      if (routeId) {
        if (!routes.traces!.some((r) => r.id === routeId)) {
          routes.traces!.push({ id: routeId, components: [] });
        }
        updater(routes.traces!.find((r) => r.id === routeId)!);
        break;
      }
      if (!routes.traces?.[0]) {
        routes.traces![0] = { components: [] };
      }
      updater(routes.traces![0]);
  }
}

// telemetryTypesParameter returns a parameter with the given pipeline types.
// If the pipeline type is undefined, it will be ignored.
export function telemetryTypesParameter(
  ...pipelineTypes: (PipelineType | undefined)[]
): Parameter {
  return {
    name: "telemetry_types",
    value: pipelineTypes
      .map((pt) => telemetryTypesValue(pt))
      .filter((v) => v !== undefined),
  };
}

// telemetryTypesValue returns the value for the telemetry types parameter.
export function telemetryTypesValue(
  pipelineTypes: PipelineType | undefined,
): string | undefined {
  switch (pipelineTypes) {
    case PipelineType.Logs:
      return "Logs";
    case PipelineType.Metrics:
      return "Metrics";
    case PipelineType.Traces:
      return "Traces";
    default:
      return undefined;
  }
}
