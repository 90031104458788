import { Position } from "reactflow";
import { GetConfigurationWithRoutesQuery } from "../../graphql/generated";

export type V2Config = GetConfigurationWithRoutesQuery["configuration"];

export enum AttributeName {
  // Nodes and Edges
  ActiveTypeFlags = "activeTypeFlags",
  SupportedTypeFlags = "supportedTypeFlags",

  // Nodes Only
  AgentCount = "agentCount",
  Configuration = "configuration",
  ComponentPath = "componentPath",
  SnapshotID = "snapshotID",
  DestinationIndex = "destinationIndex",
  SourceIndex = "sourceIndex",
  ProcessorIndex = "processorIndex",
  Recommendations = "recommendations",
  ResourceID = "resourceId",
  Resource = "resource",

  // Edges Only
  PipelineType = "pipelineType",
  RouteID = "routeID",
  MetricID = "metricID",
  ShowStartMetric = "showStartMetric",
  ShowEndMetric = "showEndMetric",

  // UI
  Title = "title",
  Subtitle = "subtitle",
  Icon = "icon",
  Paused = "paused",
}

type V2NodeAttributes = Partial<Record<AttributeName, any>>;

export interface V2NodeData {
  attributes: V2NodeAttributes;
  telemetryType: string;

  // UI Control Nodes
  buttonText?: string;
  handlePosition?: Position;
  handleType?: "source" | "target";
  onClick?: () => void;
  isButton?: boolean;

  // Overview Page Nodes
  label?: string;
}

export interface V2EdgeData {
  attributes: {
    [AttributeName.ActiveTypeFlags]: number;
    [AttributeName.SupportedTypeFlags]: number;
    [AttributeName.PipelineType]: string;
    [AttributeName.RouteID]?: string;
    [AttributeName.MetricID]: string;
    [AttributeName.ShowStartMetric]?: boolean;
    [AttributeName.ShowEndMetric]?: boolean;
  };
}

export interface DummyProcessorNodeData {}
