import { gql } from "@apollo/client";
import { Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetConfigurationMonitoringSummaryQuery } from "../../graphql/generated";
import { CONFIGURATIONS_PAGE_QUERY_PARAM } from "../../pages/configurations";
import colors from "../../styles/colors";
import { NumberChip } from "../NumberChip";
import styles from "../SummaryPageCard/summary-page-card.module.scss";

gql`
  query GetConfigurationMonitoringSummary {
    configurationMonitoring {
      total
      errored
      pending
    }
  }
`;

export const ConfigurationMonitoring: React.FC = () => {
  const navigate = useNavigate();

  const { data, loading } = useGetConfigurationMonitoringSummaryQuery({
    onError(err) {
      console.error("Error fetching configuration monitoring summary", {
        error: err,
      });
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  function onTotalClick() {
    navigate("/configurations");
  }

  function onPendingClick() {
    const params = new URLSearchParams();
    params.set(CONFIGURATIONS_PAGE_QUERY_PARAM, "rollout-status:Pending");
    navigate(`/configurations?${params.toString()}`);
  }

  function onErroredClick() {
    const params = new URLSearchParams();
    params.set(CONFIGURATIONS_PAGE_QUERY_PARAM, "rollout-status:Error");
    navigate(`/configurations?${params.toString()}`);
  }

  return (
    <Stack>
      <Divider className={styles["divider"]} />
      <Stack paddingLeft={2} paddingRight={2}>
        <Typography variant="h6" fontSize={16} paddingTop={1} paddingBottom={1}>
          Configurations
        </Typography>
        <Stack direction="row" spacing={1}>
          <NumberChip
            loading={loading}
            label="Total"
            value={data?.configurationMonitoring.total}
            onClick={onTotalClick}
            color={colors.lightBlue}
          />
          <NumberChip
            loading={loading}
            label="Pending Rollout"
            value={data?.configurationMonitoring.pending}
            color={colors.neonCarrot}
            onClick={onPendingClick}
          />
          <NumberChip
            loading={loading}
            label="Errored Rollout"
            value={data?.configurationMonitoring.errored}
            color={"error"}
            onClick={onErroredClick}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
