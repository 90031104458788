import { createContext, useContext, useState } from "react";
import { V2Config } from "../components/PipelineGraphV2/types";
import { useGetConfigurationWithRoutesQuery } from "../graphql/generated";
import {
  nameAndVersion,
  resourceVersion,
  trimVersion,
} from "../utils/version-helpers";

type ConfigurationEditDataContextValue = {
  live: V2Config | null;
  draft: V2Config | null;
  refetch: () => Promise<any>;
};

const defaultValue: ConfigurationEditDataContextValue = {
  live: null,
  draft: null,
  refetch: () => Promise.resolve(),
};

const ConfigurationEditDataContext =
  createContext<ConfigurationEditDataContextValue>(defaultValue);

export interface ConfigurationEditDataProviderProps {
  configurationName: string;
  live?: number;
  draft?: number;
}

/**
 * @param configurationName the name of the configuration, versioned is okay but not necessary
 * @param live the live version of the configuration
 * @param draft the draft version of the configuration
 * @returns
 */
export const ConfigurationEditDataProvider: React.FC<
  ConfigurationEditDataProviderProps
> = ({ children, configurationName, live, draft }) => {
  const [queriedLiveVersion, setQueriedLiveVersion] = useState<number>();
  const [queriedDraftVersion, setQueriedDraftVersion] = useState<number>();

  if (live == null && draft == null) {
    // get the version from the configuration
    live = parseInt(resourceVersion(configurationName) || "0");
  }

  const [versions, setVersions] = useState<
    Omit<ConfigurationEditDataContextValue, "refetch">
  >({
    live: null,
    draft: null,
  });

  useGetConfigurationWithRoutesQuery({
    variables: {
      name: nameAndVersion(trimVersion(configurationName), live),
    },
    skip: queriedLiveVersion === live,
    onCompleted(data) {
      setVersions((prev) => ({ ...prev, live: data.configuration }));
      setQueriedLiveVersion(live);
    },
  });

  const { refetch } = useGetConfigurationWithRoutesQuery({
    variables: {
      name: nameAndVersion(trimVersion(configurationName), draft),
    },
    skip: draft == null || queriedDraftVersion === draft,
    onCompleted(data) {
      setVersions((prev) => ({ ...prev, draft: data.configuration }));
      setQueriedDraftVersion(draft);
    },
  });

  const value = { ...versions, refetch };

  return (
    <ConfigurationEditDataContext.Provider value={value}>
      {children}
    </ConfigurationEditDataContext.Provider>
  );
};

/**
 * useConfigurationEditData is a hook that fetches the live and draft versions of a configuration
 * and returns them along with a refetch function.  It's used to reduce re-renders from changing
 * between "live" and "draft" tabs while editing a configuration.
 *
 * @param configurationName the name of the configuration, versioned is okay but not necessary
 * @param live the live version of the configuration
 * @param draft the draft version of the configuration
 * @returns
 */
export function useConfigurationEditData(): ConfigurationEditDataContextValue {
  const context = useContext(ConfigurationEditDataContext);
  if (context === undefined) {
    throw new Error(
      "useConfigurationEditData must be used within a ConfigurationEditDataProvider",
    );
  }
  return context;
}
