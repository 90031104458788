import { Handle, NodeProps, Position } from "reactflow";
import { ConfigurationCard } from "../../../components/Cards/ConfigurationCard";
import { V2NodeData } from "../../../components/PipelineGraphV2/types";

export function ConfigurationNode(params: NodeProps<V2NodeData>) {
  return (
    <div>
      <ConfigurationCard {...params} />
      <Handle type={"source"} position={Position.Right} />
    </div>
  );
}

export function formatAgentCount(agentCount: number): string {
  switch (agentCount) {
    case 0:
      return "";
    case 1:
      return "1 agent";
    default:
      return `${agentCount} agents`;
  }
}
