import { ApolloError, gql } from "@apollo/client";
import { Card, Paper, Stack } from "@mui/material";
import { EnqueueSnackbar, useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { ReactFlowProvider } from "reactflow";
import {
  Kind,
  PipelineType,
  useConfigurationMetricsSubscription,
  usePreviewFeatureGateQuery,
} from "../../graphql/generated";
import { useConfigurationEditData } from "../../hooks/useConfigurationEditData";
import { ShowPageConfig } from "../../pages/configurations/configuration";
import { UpdateStatus } from "../../types/resources";
import { BPConfiguration, BPResourceConfiguration } from "../../utils/classes";
import { Page } from "../../utils/graph/utils";
import { periodOptionsFromConfig } from "../../utils/period-options-from-config";
import { trimVersion } from "../../utils/version-helpers";
import { ComponentDialogProvider } from "../Dialogs/hooks/useComponentDialog";
import { useNewResourceDialog } from "../Dialogs/hooks/useNewResourceDialog";
import { MaxValueMap } from "../GraphComponents";
import { LiveOrDraftSwitcher } from "../LiveOrDraftSwitcher";
import { DEFAULT_PERIOD } from "../MeasurementControlBar";
import {
  DEFAULT_TELEMETRY_TYPE,
  MeasurementControlBar,
} from "../MeasurementControlBar/MeasurementControlBar";
import { ProcessorDialogContextProvider } from "../ResourceDialog/ProcessorDialogContext";
import { ConfigurationFlow } from "./ConfigurationFlow";
import { PipelineGraphProvider } from "./PipelineGraphContext";
import styles from "./pipeline-graph.module.scss";

gql`
  query PreviewFeatureGate {
    featureGate(id: "realtime-processor-preview")
  }

  subscription ConfigurationMetrics(
    $period: String!
    $name: String!
    $agent: String
  ) {
    configurationMetrics(period: $period, name: $name, agent: $agent) {
      metrics {
        name
        nodeID
        value
        unit
      }
      maxMetricValue
      maxLogValue
      maxTraceValue
    }
  }
`;

export type MinimumRequiredConfig = Partial<ShowPageConfig>;

interface PipelineGraphProps {
  // configurationName is the versioned configuration name
  configurationName: string;
  // agentId can be specified to show the pipeline/telemetry for an agent
  agentId?: string;
  // readOnly will set edit dialogs to be read only
  readOnly?: boolean;

  tab: "live" | "draft";
  onTabChange: (tab: "live" | "draft") => void;
  showLiveDraftSwitcher: boolean;

  // skipMeasurements will skip the subscription for pipeline measurements
  skipMeasurements?: boolean;
}

export const PipelineGraph: React.FC<PipelineGraphProps> = ({
  configurationName,
  agentId,
  readOnly,
  skipMeasurements,
  showLiveDraftSwitcher,
  tab,
  onTabChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createNewResource } = useNewResourceDialog();

  const [selectedTelemetry, setSelectedTelemetry] = useState<string>(
    DEFAULT_TELEMETRY_TYPE,
  );
  const [period, setPeriod] = useState<string>(DEFAULT_PERIOD);
  const [maxValues, setMaxValues] = useState<MaxValueMap>({
    maxMetricValue: 0,
    maxLogValue: 0,
    maxTraceValue: 0,
  });

  function onError(err: ApolloError) {
    console.error("Failed to fetch measurements", {
      error: err,
      name: trimVersion(configurationName),
      agentId,
    });
    enqueueSnackbar(err.message, { variant: "error" });
  }

  const {
    live,
    draft,
    refetch: refetchConfiguration,
  } = useConfigurationEditData();

  const { data: measurementData } = useConfigurationMetricsSubscription({
    variables: {
      period,
      name: trimVersion(configurationName),
      agent: agentId,
    },
    onError,
    onData({ data }) {
      if (data.data?.configurationMetrics) {
        setMaxValues({
          maxMetricValue: data.data.configurationMetrics.maxMetricValue,
          maxLogValue: data.data.configurationMetrics.maxLogValue,
          maxTraceValue: data.data.configurationMetrics.maxTraceValue,
        });
      }
    },
    skip: skipMeasurements,
  });

  const [previewEnabled, setPreviewEnabled] = useState(false);
  usePreviewFeatureGateQuery({
    onCompleted(data) {
      setPreviewEnabled(data.featureGate);
    },
    onError(err) {
      console.error("Failed to get feature gate info", {
        error: err,
      });
      enqueueSnackbar("Error getting feature gate info", {
        variant: "error",
        key: "get-feature-gate-error",
      });
    },
  });

  const configuration = tab === "live" ? live : draft;
  const platform = configuration?.metadata.labels?.platform;

  const onAddSource = useCallback(() => {
    createNewResource({
      kind: Kind.Source,
      platform,
      onSuccess: async (newSource: BPResourceConfiguration) => {
        const updatedConfig = new BPConfiguration(configuration);
        updatedConfig.addSource(newSource);
        await saveConfiguration(
          Kind.Destination,
          updatedConfig,
          refetchConfiguration,
          enqueueSnackbar,
        );
      },
      onError: (err) => enqueueSnackbar(err, { variant: "error" }),
    });
  }, [
    configuration,
    createNewResource,
    enqueueSnackbar,
    refetchConfiguration,
    platform,
  ]);

  const onAddDestination = useCallback(() => {
    createNewResource({
      kind: Kind.Destination,
      platform,
      onSuccess: async (newDestination: BPResourceConfiguration) => {
        const updatedConfig = new BPConfiguration(configuration);
        const additionalResources = [];

        const existingDestination = newDestination.type == null;
        if (existingDestination) {
          updatedConfig.addDestination(newDestination);
        } else {
          const { resource, reference } = newDestination.toLibraryResource(
            Kind.Destination,
            newDestination.name ?? "",
            newDestination.displayName ?? undefined,
          );
          updatedConfig.addDestination(reference);
          additionalResources.push(resource);
        }

        await saveConfiguration(
          Kind.Destination,
          updatedConfig,
          refetchConfiguration,
          enqueueSnackbar,
          ...additionalResources,
        );
      },
      onError: (err) => enqueueSnackbar(err, { variant: "error" }),
    });
  }, [
    configuration,
    createNewResource,
    enqueueSnackbar,
    refetchConfiguration,
    platform,
  ]);

  return (
    <PipelineGraphProvider
      selectedTelemetryType={selectedTelemetry || DEFAULT_TELEMETRY_TYPE}
      configuration={configuration}
      refetchConfiguration={refetchConfiguration}
      onAddSource={onAddSource}
      onAddDestination={onAddDestination}
      readOnly={readOnly}
      maxValues={maxValues}
      agentID={agentId}
    >
      <ComponentDialogProvider
        configuration={configuration}
        onSuccess={() => refetchConfiguration()}
        readOnly={readOnly}
      >
        <ProcessorDialogContextProvider
          configuration={configuration}
          refetchConfiguration={refetchConfiguration}
          readOnly={!!readOnly}
          pipelineType={selectedTelemetry as PipelineType}
          withProcessorPreview={!!previewEnabled}
        >
          <MeasurementControlBar
            telemetry={selectedTelemetry!}
            onTelemetryTypeChange={setSelectedTelemetry}
            period={period ?? DEFAULT_PERIOD}
            onPeriodChange={setPeriod}
            periodOptions={periodOptionsFromConfig(configuration)}
          >
            <Stack>
              {showLiveDraftSwitcher && (
                <LiveOrDraftSwitcher tab={tab} onChange={onTabChange} />
              )}
            </Stack>
          </MeasurementControlBar>
          <GraphContainer>
            <Card className={styles.card}>
              <ReactFlowProvider>
                <ConfigurationFlow
                  period={period}
                  selectedTelemetry={selectedTelemetry}
                  page={agentId ? Page.Agent : Page.Configuration}
                  loading={configuration == null}
                  configurationName={trimVersion(configurationName)}
                  measurementData={measurementData}
                />
              </ReactFlowProvider>
            </Card>
          </GraphContainer>
        </ProcessorDialogContextProvider>
      </ComponentDialogProvider>
    </PipelineGraphProvider>
  );
};

const GraphContainer: React.FC = ({ children }) => {
  return (
    <Paper classes={{ root: styles.container }} elevation={1}>
      {children}
    </Paper>
  );
};

async function saveConfiguration(
  kind: Kind,
  updatedConfig: BPConfiguration,
  refetchConfiguration: () => Promise<void>,
  enqueueSnackbar: EnqueueSnackbar,
  ...additionalResources: any[]
) {
  try {
    const update = await updatedConfig.apply(...additionalResources);
    if (update.status === UpdateStatus.INVALID) {
      console.error(update);
      throw new Error(`failed to add ${kind} to configuration.`, {
        cause: update.reason,
      });
    }
    await refetchConfiguration();
  } catch (err) {
    console.error("Failed to save configuration", {
      error: err,
      kind,
      configurationName: updatedConfig.metadata.name,
    });
    enqueueSnackbar(`Failed to save ${kind}.`, {
      variant: "error",
    });
  }
}
