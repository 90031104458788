import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoginPageProps } from ".";
import colors from "../../styles/colors";
import { VerifyEmailPage } from "./VerifyEmailPage";
import { handleLoginResponse } from "./handleLoginResponse";

/**
 * Body to send to BindPlane to login
 */
interface Auth0Body extends Auth0Token {
  email?: string;
  invitation: string | null;
}

/**
 * Response from Auth0 to requesting access token
 */
interface Auth0Token {
  idToken: string;
  accessToken: string;
}

export const invitationKey = "bindPlaneInvitation";

export const Auth0LoginPage: React.FC<Required<LoginPageProps>> = ({
  defaultAction,
}) => {
  const [error, setError] = useState<null | string>(null);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    user,
    logout,
  } = useAuth0();
  const [token, setToken] = useState<Auth0Token | null>(null);
  const invitation = searchParams.get("invitation");
  if (invitation) {
    localStorage.setItem(invitationKey, invitation);
  }

  const handleReturnToLogin = useCallback(async () => {
    await logout({ openUrl: false });
    setToken(null);
    localStorage.removeItem("user");
  }, [logout]);

  const getTokenAndLogin = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        detailedResponse: true,
      });
      setToken({
        idToken: accessToken.id_token,
        accessToken: accessToken.access_token,
      });

      try {
        if (error) {
          setError(null);
        }

        if (user != null && user.email_verified !== true) {
          enqueueSnackbar("Please verify email", { variant: "info" });
          return;
        }

        const body: Auth0Body = {
          idToken: accessToken.id_token,
          accessToken: accessToken.access_token,
          email: user?.email,
          invitation:
            localStorage.getItem(invitationKey) ??
            searchParams.get("invitation"),
        };

        const resp = await fetch("/login", {
          method: "POST",
          body: JSON.stringify(body),
        });

        handleLoginResponse({
          status: resp.status,
          onSuccess: async () => {
            if (error) {
              setError(null);
            }
            Sentry.setUser({ email: user?.email });

            const next = searchParams.get("next") || "/agents";
            localStorage.setItem("user", "auth0User");
            localStorage.removeItem(invitationKey);
            navigate(next);
          },
          on401: async () => {
            setError("Failed to login.");
          },
          on403: async () => {
            if (error) {
              setError(null);
            }
            localStorage.setItem("user", "auth0User");
            localStorage.removeItem(invitationKey);
            Sentry.setUser({ email: user?.email });
            navigate("/organization/new");
          },
          on409: async () => {
            if (error) {
              setError(null);
            }
            enqueueSnackbar("Sorry, the invitation is invalid.", {
              variant: "error",
            });
            localStorage.removeItem(invitationKey);
          },
          onFailure: async () => {
            setError("Failed to login.");
          },
        });
      } catch (err) {
        console.error("Failed to login", {
          error: err,
        });
        enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
      }
    } catch (e) {
      console.error("Failed to get token", {
        error: e,
      });
      setError("Failed to get token");
    }
  }, [
    enqueueSnackbar,
    error,
    getAccessTokenSilently,
    navigate,
    searchParams,
    setToken,
    user,
  ]);

  /**
   * Get token once authenticated, and if token is not already set
   */
  useEffect(() => {
    if (isAuthenticated && token == null) {
      getTokenAndLogin();
    } else if (!isAuthenticated && !token && !user && !isLoading) {
      loginWithRedirect({
        authorizationParams: {
          screen_hint:
            defaultAction === "signup" || invitation ? "signup" : "page",
          redirect_uri: `${window.location.origin}/login`,
        },
      });
    }
  }, [
    defaultAction,
    getTokenAndLogin,
    isAuthenticated,
    token,
    user,
    loginWithRedirect,
    isLoading,
    invitation,
  ]);

  if (user != null && user.email_verified !== true) {
    return <VerifyEmailPage onReturnClick={handleReturnToLogin} />;
  }

  return (
    <Stack
      height="100vh"
      alignItems="center"
      justifyContent={"center"}
      bgcolor={colors.backgroundWhite}
    >
      <CircularProgress size={100} />
    </Stack>
  );
};
