import { Typography } from "@mui/material";
import { useMemo } from "react";
import { getSaasEnabled } from "../../utils/get-saas-enabled";
import { edition, version } from "./utils";
import styles from "./version.module.scss";

interface BindplaneVersionProps {
  licenseType?: string;
  saasEnabled?: boolean;
}

// Version displays the server version received from the /version endpoint.
export const BindplaneVersion: React.FC<BindplaneVersionProps> = ({
  licenseType,
  saasEnabled,
}) => {
  const license = useMemo(() => {
    if (licenseType) {
      return licenseType;
    }

    return edition();
  }, [licenseType]);

  const isSaasEnabled = useMemo(() => {
    if (saasEnabled != null) {
      return saasEnabled;
    }

    return getSaasEnabled();
  }, [saasEnabled]);

  let label = useMemo(() => {
    if (license === "Honeycomb") {
      return `Honeycomb Telemetry Pipeline ${version()}`;
    }

    if (!isSaasEnabled) {
      return `${license} Edition ${version()}`;
    }

    // saasVersionHashExtraction is a regex that will extract out the git hash of a snapshot commit
    // while persisting any full version tags which are not release candidates
    const saasVersionHashExtractionRegex =
      /(v\d+\.\d+\.\d+$)|(?:v\d+\.\d+\.\d+-SNAPSHOT-(.*))/gm;

    // extract out git hash for saas
    const gitHashMatch = saasVersionHashExtractionRegex.exec(version());
    if (!gitHashMatch || gitHashMatch.length !== 3) {
      return `${license} Edition ${version()}`;
    }

    // gitHashMatch[1] == a conventional semver | gitHashMatch[2] == git hash
    return `${license} Edition ${gitHashMatch[1] || gitHashMatch[2]}`;
  }, [license, isSaasEnabled]);

  return (
    <Typography
      variant="body2"
      fontWeight={300}
      classes={{ root: styles.root }}
    >
      {label}
    </Typography>
  );
};
