import { CircularProgress, Stack } from "@mui/material";
import { createBrowserHistory } from "history";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import colors from "../../styles/colors";
import { invitationKey } from "./Auth0Login";

type RedirectResponse = {
  url: string;
};

export const OIDCLogin: React.FC = () => {
  const history = createBrowserHistory();
  const [searchParams] = useSearchParams();
  const invitation = searchParams.get("invitation");
  if (invitation) {
    localStorage.setItem(invitationKey, invitation);
  }

  const getToken = useCallback(async () => {
    try {
      const resp = await fetch("/auth/oidc/login");
      if (!resp.ok) {
        console.error("request not ok", { resp });
        return;
      }
      const tokenResponse = (await resp.json()) as RedirectResponse;
      history.push(tokenResponse.url);
    } catch (e) {
      console.error("request failed", { e });
    }
  }, [history]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return (
    <Stack
      height="100vh"
      alignItems="center"
      justifyContent={"center"}
      bgcolor={colors.backgroundWhite}
    >
      <CircularProgress size={100} />
    </Stack>
  );
};
