import { Fade, IconButton } from "@mui/material";
import { EdgeLabelRenderer } from "reactflow";
import colors from "../../../styles/colors";
import { ComponentType } from "../../../utils/classes/component-type";
import { PlusCircleIcon } from "../../Icons";
import { useBPGraph } from "../BPGraphProvider";
import { useRouting } from "./RoutingContext";

interface AddRouteButtonProps {
  labelX: number;
  labelY: number;
  nodeID: string;
  componentType: ComponentType;
  componentPath: string;
  routeId?: string;
  fadeIn: boolean;
  hide: boolean;
}

export const AddRouteButton: React.FC<AddRouteButtonProps> = ({
  labelX,
  labelY,
  nodeID,
  componentType,
  componentPath,
  routeId,
  fadeIn,
  hide,
}) => {
  const { onRouteButtonClick } = useRouting();
  const { onMouseEnterNode, onMouseExitNode } = useBPGraph();
  return (
    <EdgeLabelRenderer>
      <Fade in={fadeIn}>
        <div
          className={"nopan"}
          style={{
            transform: `translate(${labelX}px,${labelY}px)`,
            pointerEvents: fadeIn ? "all" : "none",
            position: "absolute",
            backgroundColor: colors.white,
            borderRadius: "50%",
            zIndex: 1,
            opacity: fadeIn ? 1 : 0,
          }}
        >
          {!hide && (
            <IconButton
              onMouseEnter={() => onMouseEnterNode(nodeID)}
              onMouseLeave={onMouseExitNode}
              onClick={() =>
                onRouteButtonClick(componentType, componentPath, routeId)
              }
              color="primary"
            >
              <PlusCircleIcon />
            </IconButton>
          )}
        </div>
      </Fade>
    </EdgeLabelRenderer>
  );
};
