import { Dialog, DialogProps } from "@mui/material";
import { isFunction } from "lodash";
import { useEffect } from "react";
import { DialogResource } from ".";
import {
  AdditionalInfo,
  Kind,
  Maybe,
  Parameter,
  ParameterDefinition,
  ResourceConfiguration,
} from "../../graphql/generated";
import { ResourceKind } from "../../types/resources";
import { ResourceConfigurationView } from "../ResourceConfigForm";
import {
  ResourceDialogContextProvider,
  useResourceDialog,
} from "./ResourceDialogContext";

export interface EditResourceDialogProps extends DialogProps {
  resourceType?: string;
  resourceTypeDisplayName: string;
  displayName?: string;
  resourceNameField?: string;
  description: string;
  additionalInfo?: AdditionalInfo | null;
  resourceDocLink?: string;
  onSave: (values: { [key: string]: any }) => void;
  onDelete?: () => void;
  onCancel: () => void;
  parameters: Parameter[];
  parameterDefinitions: ParameterDefinition[];
  // If present the form will use these values as defaults
  processors?: Maybe<ResourceConfiguration[]>;
  includeNameField?: boolean;
  kind: ResourceKind;
  stability?: string;
  // The supported telemetry types of the resource type that is
  // being configured.  a subset of ['logs', 'metrics', 'traces']
  telemetryTypes?: string[];
  paused?: boolean;
  onTogglePause?: () => void;
  readOnly?: boolean;
  showLibraryBookmark?: boolean;
  onAddToLibrary?: (values: { [key: string]: any }, name: string) => void;
  onUnlinkFromLibrary?: (values: { [key: string]: any }, name: string) => void;
  libraryResources?: DialogResource[];
}

const EditResourceDialogComponent: React.FC<EditResourceDialogProps> = ({
  displayName,
  resourceNameField,
  onSave,
  onDelete,
  onTogglePause,
  onCancel,
  resourceType,
  resourceTypeDisplayName,
  description,
  additionalInfo,
  resourceDocLink,
  parameters,
  parameterDefinitions,
  processors,
  kind,
  telemetryTypes,
  includeNameField = false,
  paused = false,
  readOnly,
  showLibraryBookmark,
  stability,
  onAddToLibrary,
  onUnlinkFromLibrary,
  libraryResources,
  ...dialogProps
}) => {
  const { setInLibrary } = useResourceDialog();
  useEffect(() => {
    setInLibrary?.(true);
  }, [setInLibrary]);
  return (
    <Dialog
      {...dialogProps}
      onClose={onCancel}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          height: "85vh",
        },
      }}
    >
      <ResourceConfigurationView
        includeNameField={includeNameField}
        stability={stability}
        resourceNameField={resourceNameField}
        includeDisplayNameField={kind !== Kind.Destination}
        resourceType={resourceType}
        resourceTypeDisplayName={resourceTypeDisplayName}
        displayName={displayName}
        additionalInfo={additionalInfo}
        resourceDocLink={resourceDocLink}
        description={description}
        kind={kind}
        parameterDefinitions={parameterDefinitions}
        parameters={parameters}
        processors={processors}
        onSave={onSave}
        onDelete={onDelete}
        telemetryTypes={telemetryTypes}
        paused={paused}
        onTogglePause={onTogglePause}
        readOnly={readOnly}
        showLibraryBookmark={showLibraryBookmark}
        onAddToLibrary={onAddToLibrary}
        onUnlinkFromLibrary={onUnlinkFromLibrary}
        libraryResources={libraryResources}
      />
    </Dialog>
  );
};

export const EditResourceDialog: React.FC<EditResourceDialogProps> = (
  props,
) => {
  function handleClose() {
    if (isFunction(props.onClose)) {
      props.onClose({}, "backdropClick");
    }
  }
  return (
    <ResourceDialogContextProvider purpose="edit" onClose={handleClose}>
      <EditResourceDialogComponent {...props} />
    </ResourceDialogContextProvider>
  );
};
