import { cloneDeep } from "lodash";
import {
  Kind,
  Metadata,
  ParameterizedSpec,
  ResourceConfiguration,
} from "../../graphql/generated";
import { APIVersion, ResourceStatus } from "../../types/resources";
import { applyResources } from "../rest/apply-resources";

// BaseResource is the base class for Sources, Destinations, and Processors.
export class BPBaseResource {
  apiVersion: string;
  kind: Kind;
  metadata: Metadata;
  spec: ParameterizedSpec;

  constructor() {
    this.apiVersion = APIVersion.V1;
    this.kind = Kind.Unknown;
    this.metadata = {
      id: "",
      version: 1,
      name: "",
    };

    this.spec = {
      type: "unknown",
      disabled: false,
    };
  }

  name(): string {
    return this.metadata.name;
  }

  // setParamsFromMap sets the spec.parameters from Record<string, any>.
  // If the "name" key is specified it will ignore it.
  setParamsFromMap(values: Record<string, any>) {
    const params: ParameterizedSpec["parameters"] = [];
    const newSpec = cloneDeep(this.spec);
    for (const [k, v] of Object.entries(values)) {
      // Ignore values that are not set
      if (v == null) {
        continue;
      }

      switch (k) {
        case "name": // read-only
          break;
        case "displayName":
          this.metadata.displayName = v;
          break;
        case "processors": // saved in configuration
          break;
        default:
          params.push({
            name: k,
            value: v,
          });
      }
    }

    newSpec.parameters = params;
    this.spec = newSpec;
  }

  setProcessors(values: ResourceConfiguration[]) {
    const processors: ParameterizedSpec["processors"] = [];
    for (const v of values) {
      if (v == null) {
        continue;
      }
      processors.push(v);
    }
    const newSpec = cloneDeep(this.spec);
    newSpec.processors = processors;
    this.spec = newSpec;
  }

  toggleDisabled() {
    const newSpec = cloneDeep(this.spec);
    newSpec.disabled = !newSpec.disabled;
    this.spec = newSpec;
  }

  getParameterValue<T>(name: string, defaultValue?: T): T | undefined {
    const param = this.spec.parameters?.find((p) => p.name === name);
    if (param == null) {
      return defaultValue;
    }
    return param.value as T;
  }

  async apply(): Promise<ResourceStatus> {
    const { updates } = await applyResources([this]);
    const update = updates.find(
      (u) => u.resource.metadata.name === this.name(),
    );
    if (update == null) {
      throw new Error(
        `failed to apply configuration, no update with name ${this.name()}`,
      );
    }
    return update;
  }

  /**
   * asResourceConfig returns the BPBaseResource as a resource configuration
   * that can be referenced in a configuration.
   *
   * @returns BPResourceConfiguration
   */
  asResourceConfig(): ResourceConfiguration {
    return {
      name: this.metadata.name,
      id: this.metadata.id,
      disabled: this.spec.disabled,
      processors: this.spec.processors,
    };
  }
}
