import { ApolloError } from "@apollo/client";
import {
  Card,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Divider,
  Collapse,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Kind,
  ResourceConfiguration,
  useGetResourceTypeQuery,
  useGetResourceWithTypeQuery,
} from "../../graphql/generated";
import colors from "../../styles/colors";
import { BPResourceConfiguration } from "../../utils/classes";
import { ConfirmDeleteResourceDialog } from "../ConfirmDeleteResourceDialog";
import {
  BookmarkIcon,
  ChevronDown,
  EditIcon,
  MenuIcon,
  ProcessorIcon,
  TrashIcon,
} from "../Icons";
import { getStabilityChip } from "../StabilityChip";
import styles from "./resource-configuration-label-card.module.scss";

interface LabelCardProps {
  index: number;
  item: ResourceConfiguration;
  resourceKind: Kind.Processor | Kind.Extension;
  dragDropRef?: React.RefObject<HTMLDivElement>;
  isHovered?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  readOnly: boolean;
}

export const ResourceConfigurationLabelCard: React.FC<LabelCardProps> = ({
  index,
  resourceKind,
  item,
  dragDropRef,
  isHovered,
  onEdit,
  onDelete,
  readOnly,
}) => {
  const resourceConfig = new BPResourceConfiguration(item);
  const { enqueueSnackbar } = useSnackbar();
  const [resourceTypeDisplayName, setResourceTypeDisplayName] =
    useState<string>("");
  const [resourceNameOrDescription, setResourceNameOrDescription] =
    useState<string>();
  const [resourceTypeStability, setResourceTypeStability] = useState<string>();
  const [icon, setIcon] = useState<string>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  let isProcessorBundle = (item.processors?.length ?? 0) > 0;
  function onError(error: ApolloError) {
    console.error(error);
    enqueueSnackbar("Error retrieving Resource Type", {
      variant: "error",
      key: "Error retrieving Resource Type",
    });
  }

  const resourceType =
    resourceKind === Kind.Processor ? Kind.ProcessorType : Kind.ExtensionType;

  useGetResourceTypeQuery({
    variables: { kind: resourceType, name: resourceConfig.type! },
    skip: !resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceTypeDisplayName(data.resourceType!.metadata!.displayName!);
      setResourceNameOrDescription(item.displayName ?? "");
      setResourceTypeStability(data.resourceType?.metadata.stability ?? "");
      setIcon(data.resourceType!.metadata!.icon!);
    },
  });

  useGetResourceWithTypeQuery({
    variables: { kind: resourceKind, name: resourceConfig.name! },
    skip: resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceTypeDisplayName(
        data?.resourceWithType!.resourceType!.metadata!.displayName!,
      );
      setResourceNameOrDescription(
        data?.resourceWithType?.resource?.metadata?.name,
      );
      setResourceTypeStability(
        data.resourceWithType.resourceType?.metadata.stability ?? "",
      );
      setIcon(data.resourceWithType!.resourceType!.metadata!.icon!);
    },
  });

  const labelTitle = `${resourceTypeDisplayName}${resourceNameOrDescription ? `: ${resourceNameOrDescription}` : ""}`;
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded((prev) => !prev);

  return (
    <Stack
      direction={"row"}
      sx={{ width: "100%" }}
      position="relative"
      overflow="visible"
      alignItems={"center"}
    >
      <Card
        variant="outlined"
        ref={dragDropRef}
        style={{
          border: isHovered
            ? `1px solid ${colors.pixelPointBlue}` // Blue border if hovered
            : isProcessorBundle
              ? `2px solid ${colors.pixelPointBlue}` // Grey border if processor bundle
              : undefined,
          marginRight: "4px",
          width: "100%",
        }}
        classes={{ root: styles.card }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          spacing={1}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            spacing={1}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            width={"100%"}
            alignItems={"center"}
          >
            <MenuIcon className={styles["hover-icon"]} />
            {icon ? (
              <span
                className={styles.icon}
                style={{ backgroundImage: `url(${icon})` }}
              />
            ) : (
              <span className={styles.icon}>
                <ProcessorIcon />
              </span>
            )}
            <Tooltip title={labelTitle}>
              <Typography
                fontWeight={600}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                maxWidth={"95%"}
              >
                {resourceNameOrDescription ? (
                  <>
                    <span className={styles.displayName}>
                      {resourceTypeDisplayName}:{" "}
                    </span>
                    <span className={styles.shortDescription}>
                      {resourceNameOrDescription}
                    </span>
                  </>
                ) : (
                  <span className={styles.displayName}>
                    {resourceTypeDisplayName}
                  </span>
                )}
              </Typography>
            </Tooltip>
          </Stack>

          <Stack direction="row" spacing={0.6} alignItems="center">
            {getStabilityChip(resourceTypeStability)}
            {resourceConfig.name && (
              <BookmarkIcon
                width={18}
                height={18}
                stroke={colors.pixelPointBlue}
                fill={colors.pixelPointBlue}
                style={{ position: "relative", top: "-14px", right: "6px" }}
              />
            )}
            {/* Toggle Button */}
            {isProcessorBundle && (
              <>
                <IconButton
                  onClick={handleToggle}
                  disabled={readOnly}
                  className={styles["card-button"]}
                  style={{
                    transform: expanded ? "rotate(-180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                >
                  <ChevronDown className={styles["chevron"]} />
                </IconButton>
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <IconButton
              onClick={onEdit}
              data-testid={`edit-${resourceKind.toLowerCase()}-${index}`}
              className={styles["card-button"]}
            >
              <EditIcon className={styles["card-button-icon"]} />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              onClick={() => setDeleteDialogOpen(true)}
              disabled={readOnly}
              className={styles["card-button"]}
            >
              <TrashIcon className={styles["card-button-icon"]} />
            </IconButton>
          </Stack>
        </Stack>
        {/* Collapsible Section */}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Stack spacing={1} marginTop={2}>
            {item.processors?.map((processor, idx) => (
              <Card
                key={processor.id ?? idx} // Ensure each processor has a unique key, fallback to idx if not
                variant="outlined"
                style={{
                  display: "flex",
                  alignItems: "center",
                  // padding: "8px",
                  borderRadius: "4px",
                  gap: "8px",
                }}
              >
                <SubProcessor
                  processor={processor}
                  onEdit={onEdit}
                  onError={onError}
                />
              </Card>
            ))}
          </Stack>
        </Collapse>
      </Card>

      <ConfirmDeleteResourceDialog
        onDelete={() => {
          onDelete();
          setDeleteDialogOpen(false);
        }}
        onCancel={() => setDeleteDialogOpen(false)}
        action={"delete"}
        open={deleteDialogOpen}
      >
        <Typography>
          Are you sure you want to delete this{" "}
          {resourceKind === Kind.Processor ? "processor" : "extension"}?
        </Typography>
      </ConfirmDeleteResourceDialog>
    </Stack>
  );
};

// Child component to handle individual processor query
const SubProcessor: React.FC<{
  processor: ResourceConfiguration;
  onEdit: () => void;
  onError: (error: ApolloError) => void;
}> = ({ processor, onEdit, onError }) => {
  const [resourceTypeDisplayName, setResourceTypeDisplayName] =
    useState<string>("");
  const [resourceNameOrDescription, setResourceNameOrDescription] =
    useState<string>();

  const [icon, setIcon] = useState<string>();

  const resourceConfig = new BPResourceConfiguration(processor);

  useGetResourceTypeQuery({
    variables: { kind: Kind.ProcessorType, name: resourceConfig.type! },
    onCompleted(data) {
      if (data.resourceType) {
        setResourceTypeDisplayName(
          data.resourceType.metadata.displayName ?? "",
        );
        setIcon(data.resourceType.metadata.icon ?? "");
      }
      setResourceNameOrDescription(processor.displayName ?? "");
    },
  });

  useGetResourceWithTypeQuery({
    variables: { kind: Kind.Processor, name: resourceConfig.name! },
    skip: resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceTypeDisplayName(
        data?.resourceWithType!.resourceType!.metadata!.displayName!,
      );
      setResourceNameOrDescription(
        data?.resourceWithType?.resource?.metadata?.name ?? "",
      );
      setIcon(data.resourceWithType!.resourceType!.metadata!.icon!);
    },
  });
  return (
    <Stack
      direction={"row"}
      spacing={1}
      overflow={"hidden"}
      textOverflow={"ellipsis"}
      width={"100%"}
      alignItems={"center"}
      padding={"8px"}
    >
      {icon ? (
        <span
          className={styles.icon}
          style={{ backgroundImage: `url(${icon})` }}
        />
      ) : (
        <span className={styles.icon}>
          <ProcessorIcon />
        </span>
      )}

      <Typography
        fontWeight={600}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        whiteSpace={"nowrap"}
        maxWidth={"100%"}
      >
        {resourceNameOrDescription ? (
          <>
            <span className={styles.displayName}>
              {resourceTypeDisplayName}:{" "}
            </span>
            <span className={styles.shortDescription}>
              {resourceNameOrDescription}
            </span>
          </>
        ) : (
          <span className={styles.displayName}>{resourceTypeDisplayName}</span>
        )}
      </Typography>
      {resourceConfig.name && (
        <BookmarkIcon
          width={18}
          height={18}
          stroke={colors.pixelPointBlue}
          fill={colors.pixelPointBlue}
          style={{
            position: "relative",
            top: "-13px",
            flexShrink: 0,
          }}
        />
      )}
    </Stack>
  );
};
