import { memo, useEffect, useMemo, useRef } from "react";
import { Edge, EdgeProps, getBezierPath, useEdges } from "reactflow";
import {
  edgeAnimationDuration,
  edgeStrokeOffsetSpan,
  skipFrames,
} from "../../components/GraphComponents/CustomEdge";
import { useBPGraph } from "../../components/PipelineGraphV2/BPGraphProvider";
import { useGraphMetrics } from "../../components/PipelineGraphV2/GraphMetricsProvider";
import {
  AttributeName,
  V2EdgeData,
} from "../../components/PipelineGraphV2/types";
import { EdgeMetricV2 } from "../../graphql/generated";
import colors from "../../styles/colors";
import { hasPipelineTypeFlag } from "../../types/configuration";
import { formatMetric } from "../../utils/graph/utils";
import { classes } from "../../utils/styles";
import { useOverviewPage } from "./OverviewPageContext";
import styles from "../../components/PipelineGraphV2/Components/configuration-edge-v2.module.scss";

const OverviewEdgeV2: React.FC<EdgeProps<V2EdgeData>> = ({
  id,
  data,
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}) => {
  // TODO(dsvanlani): Might not want to throw here.. make sure all edges have data
  if (!data) throw new Error("missing data for edge");
  const { attributes } = data;

  const hiddenRef = useRef(false);
  const pathRef = useRef<SVGPathElement>(null);
  const active = hasPipelineTypeFlag(
    attributes[AttributeName.PipelineType],
    attributes[AttributeName.ActiveTypeFlags],
  );
  const graphMetrics = useGraphMetrics();
  const metricData = graphMetrics?.data() ?? null;
  const { selectedTelemetry, selectedPeriod } = useOverviewPage();

  // hoveredSet logic for the overview page is not currently working but will be revisited in the future
  const { hoveredSet, hoveredEdge, onMouseEnterEdge, onMouseExitEdge } =
    useBPGraph();
  const edges = useEdges();

  const dimmed = hoveredSet.length > 0 && !hoveredSet.includes(id);
  const metricID = data?.attributes.metricID ?? "";

  // Animation effect
  useEffect(() => {
    let frameCount = 0; // Counter for frame skipping

    // Fun fact:
    //    function animate(time: number) {
    // seems to cause a 10-20% CPU usage increase compared to:
    const animate = (time: number) => {
      frameCount++;

      if (frameCount > skipFrames) {
        if (!pathRef.current) return;

        const currentTime = time % edgeAnimationDuration;
        const progress = currentTime / edgeAnimationDuration;
        const currentOffset = progress * edgeStrokeOffsetSpan;
        pathRef.current.style.strokeDashoffset = `${currentOffset}`;

        frameCount = 0; // Reset the frame counter
      }

      if (active && !hiddenRef.current) {
        requestAnimationFrame(animate);
      }
    };

    if (active && !hiddenRef.current) {
      requestAnimationFrame(animate);
    }
  }, [active]);

  const [path, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const pathClasses = useMemo(() => {
    return makePathClass({
      active,
      dimmed,
      metric: graphMetrics?.data().metric(metricID, attributes.pipelineType),
      maxValue: graphMetrics?.data().maxValue(attributes.pipelineType) ?? 0,
    });
  }, [active, dimmed, graphMetrics, metricID, attributes.pipelineType]);

  const idParts = id.split("|");
  const validID = idParts.length === 3;
  let sourceID = "";
  let targetID = "";
  if (validID) {
    sourceID = idParts[0];
    targetID = idParts[1];
  }

  const validMetric =
    metricData !== null &&
    metricData.metric(metricID, attributes.pipelineType) !== undefined;

  let startMetric: EdgeMetricV2 | undefined;
  let endMetric: EdgeMetricV2 | undefined;

  if (validMetric) {
    startMetric = metricData?.metric(metricID, selectedTelemetry);
    endMetric = metricData?.metric(metricID, selectedTelemetry);
  }

  if (!validMetric && data.attributes.showStartMetric) {
    startMetric = metricData?.overviewSourceNodeMetric(
      sourceID,
      attributes.pipelineType,
    );
  }

  if (!validMetric && data.attributes.showEndMetric) {
    endMetric = metricData?.overviewTargetNodeMetric(
      targetID,
      attributes.pipelineType,
    );
  }

  const showStartMetric =
    startMetric !== undefined && data.attributes.showStartMetric;
  const showEndMetric =
    endMetric !== undefined && data.attributes.showEndMetric;

  const showMiddleMetric =
    validMetric &&
    validID &&
    sourceID.startsWith("gateway") &&
    targetID.startsWith("gateway");

  return (
    <>
      {showStartMetric &&
        renderStartMetric(id, edges, selectedPeriod, startMetric)}
      {showMiddleMetric &&
        hoveredEdge === id &&
        renderMiddleMetric(
          id,
          edges,
          selectedPeriod,
          labelX,
          labelY,
          sourceY,
          targetY,
          path,
          metricData.metric(metricID, selectedTelemetry),
        )}
      {showEndMetric && renderEndMetric(id, edges, selectedPeriod, endMetric)}

      {/** An invisible line to increase the hover distance */}
      <path
        style={{ opacity: 0, strokeWidth: 25 }}
        d={path}
        onMouseEnter={() => {
          onMouseEnterEdge(id);
        }}
        onMouseLeave={onMouseExitEdge}
      />

      {/** The actual Edge */}
      <path
        onMouseEnter={() => {
          onMouseEnterEdge(id);
        }}
        onMouseLeave={onMouseExitEdge}
        ref={pathRef}
        id={id}
        d={path}
        className={pathClasses}
      />
    </>
  );
};

/**
 *
 * @param metric
 * @param edgeID
 * @param period
 * @returns
 */
export function renderStartMetric(
  edgeID: string,
  edges: Edge[],
  period: string,
  metric?: EdgeMetricV2,
) {
  if (!metric) {
    return null;
  }

  const edge = edges.find((edge) => edge.id === edgeID);
  if (!edge) {
    return null;
  }

  var startOffset = "0%";
  var textAnchor = "start";

  return (
    <g
      key={`${edgeID}-start`}
      transform={`translate(0 -20)`}
      className="pipeline-metric-label"
    >
      <text>
        <textPath
          className={styles.metric}
          href={`#${edgeID}`}
          startOffset={startOffset}
          textAnchor={textAnchor}
          spacing="auto"
        >
          {formatMetric({ value: metric.startValue, unit: "B/s" }, period)}
        </textPath>
      </text>
    </g>
  );
}

/**
 *
 * @param metric
 * @param edgeID
 * @param period
 * @returns
 */
export function renderMiddleMetric(
  edgeID: string,
  edges: Edge[],
  period: string,
  labelX: number,
  labelY: number,
  sourceY: number,
  targetY: number,
  path: string,
  metric?: EdgeMetricV2,
) {
  if (!metric) {
    return null;
  }

  const edge = edges.find((edge) => edge.id === edgeID);
  if (!edge) {
    return null;
  }

  var startOffset = "50%";
  var textAnchor = "middle";

  const rectWidth = 75;
  const rectHeight = 22;

  // get angle of curve at middle of edge
  const angleInDegrees = calculateAngleAtMidpoint(path, sourceY, targetY);
  const angleInRadians = angleInDegrees * (Math.PI / 180);

  // Calculate translation coordinates
  const translateX = 20 * Math.sin(angleInRadians);
  const translateY = -20 * Math.cos(angleInRadians);

  const rectX = labelX - rectWidth / 2 + 24 * Math.sin(angleInRadians);
  const rectY = labelY - rectHeight / 2 - 24 * Math.cos(angleInRadians);

  return (
    <>
      {/* Background rectangle */}
      <rect
        style={{ transformOrigin: "center", transformBox: "fill-box" }}
        width={rectWidth}
        height={rectHeight}
        x={rectX}
        y={rectY}
        transform={`rotate(${angleInDegrees})`}
        fill={colors.backgroundWhite}
        rx={6}
        ry={6}
      />
      <g
        key={`${edgeID}-middle`}
        transform={`translate(${translateX} ${translateY})`}
        className="pipeline-metric-label"
      >
        <text>
          <textPath
            className={styles.metric}
            href={`#${edgeID}`}
            startOffset={startOffset}
            textAnchor={textAnchor}
            spacing="auto"
          >
            {formatMetric({ value: metric.value, unit: "B/s" }, period)}
          </textPath>
        </text>
      </g>
    </>
  );
}

/**
 *
 * @param metric
 * @param edgeID
 * @param period
 * @returns
 */
export function renderEndMetric(
  edgeID: string,
  edges: Edge[],
  period: string,
  metric?: EdgeMetricV2,
) {
  if (!metric) {
    return null;
  }

  const edge = edges.find((edge) => edge.id === edgeID);
  if (!edge) {
    return null;
  }

  var startOffset = "100%";
  var textAnchor = "end";

  return (
    <g
      key={`${edgeID}-end`}
      transform={`translate(0 -20)`}
      className="pipeline-metric-label"
    >
      <text>
        <textPath
          className={styles.metric}
          href={`#${edgeID}`}
          startOffset={startOffset}
          textAnchor={textAnchor}
          spacing="auto"
        >
          {formatMetric({ value: metric.endValue, unit: "B/s" }, period)}
        </textPath>
      </text>
    </g>
  );
}

const parseBezierPath = (path: string) => {
  const regex =
    /M([\d.]+),([\d.]+)\s+C([\d.]+),([\d.]+)\s+([\d.]+),([\d.]+)\s+([\d.]+),([\d.]+)/;
  const match = path.match(regex);

  if (match) {
    const [, x1, y1, x2, y2, x3, y3, x4, y4] = match.map(Number);
    return { x1, y1, x2, y2, x3, y3, x4, y4 };
  }
  return null;
};

// Calculate the cubic bezier derivative at t
const bezierDerivative = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
  t: number,
) => {
  const dx =
    (1 - t) ** 2 * (x2 - x1) + 2 * (1 - t) * t * (x3 - x2) + t ** 2 * (x4 - x3);
  const dy =
    (1 - t) ** 2 * (y2 - y1) + 2 * (1 - t) * t * (y3 - y2) + t ** 2 * (y4 - y3);
  return { dx, dy };
};

// Calculate the angle of the bezier curve at the midpoint (t = 0.5)
const calculateAngleAtMidpoint = (
  path: string,
  sourceY: number,
  targetY: number,
) => {
  if (sourceY === targetY) {
    return 0;
  }
  const parsed = parseBezierPath(path);
  if (parsed) {
    const { x1, y1, x2, y2, x3, y3, x4, y4 } = parsed;
    const t = 0.5;
    const { dx, dy } = bezierDerivative(x1, y1, x2, y2, x3, y3, x4, y4, t);
    const angle = Math.atan2(dy, dx); // Returns the angle in radians
    const angleInDegrees = (angle * 180) / Math.PI; // Convert to degrees
    return angleInDegrees;
  }

  return 0;
};

type makePathClassArg = {
  active: boolean;
  dimmed: boolean;
  metric?: EdgeMetricV2;
  maxValue: number;
  selected?: boolean;
};

function makePathClass({ active, dimmed, metric, maxValue }: makePathClassArg) {
  const classNames = [styles.noFill];

  active
    ? classNames.push(styles.activeRoute)
    : classNames.push(styles.inactiveRoute);

  active && classNames.push(getWeightedClassName(maxValue, metric));
  dimmed && classNames.push(styles.dimmed);
  return classes(classNames);
}

export const getWeightedClassName = (
  maxValue: number,
  metric?: EdgeMetricV2,
) => {
  if (metric == null) {
    return styles.inactive;
  }

  return getWidthClass(metric.value, maxValue);
};

function getWidthClass(rawValue: number, maxValue: number) {
  const ratio = rawValue / maxValue;
  if (ratio >= 1) {
    return styles.w5;
  }
  const scaled = Math.floor(ratio * 5 + 1);
  const widthStyle = `w${scaled}`;
  return styles[widthStyle];
}

export default memo(OverviewEdgeV2);
