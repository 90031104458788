/**
 * Returns the Bindplane version
 *
 * @returns the Bindplane version from the global variable, or "unknown" if it is not set
 */
export function version(): string {
  try {
    return __BINDPLANE_VERSION__;
  } catch (err) {
    return "unknown";
  }
}

export function edition(): string {
  try {
    return __EDITION__;
  } catch (err) {
    return "unknown";
  }
}
