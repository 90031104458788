import { Stack } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridDensity,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { isFunction } from "lodash";
import { memo, useEffect, useState } from "react";
import { TELEMETRY_SIZE_METRICS } from "../MeasurementControlBar/MeasurementControlBar";
import { AgentMetrics, TableAgent } from "./types";

export interface EEAgentsDataGridProps {
  onAgentsSelected?: (agentIds: GridRowSelectionModel) => void;
  isRowSelectable?: (params: GridRowParams<TableAgent>) => boolean;
  clearSelectionModelFnRef?: React.MutableRefObject<(() => void) | null>;
  density?: GridDensity;
  loading: boolean;
  minHeight?: string;
  maxHeight?: string;
  agents?: TableAgent[];
  agentMetrics?: AgentMetrics;
  allowSelection: boolean;

  paginationModel: GridPaginationModel;
  onPaginationModelChange: DataGridProProps["onPaginationModelChange"];

  sortModel: GridSortModel;
  onSortModelChange: DataGridProProps["onSortModelChange"];

  rowCount: number;

  // autoHeight is used in testing to enable the autoHeight prop.
  autoHeight?: boolean;

  // When passed, the noRowsOverlay will override the default
  // no rows overlay.
  noRowsOverlay?: React.FC<{}>;

  columns: GridColDef<TableAgentWithTelemetry>[];
}
export interface TableAgentWithTelemetry extends TableAgent {
  logs: number;
  metrics: number;
  traces: number;
}

export const EEAgentsDataGrid: React.FC<EEAgentsDataGridProps> = memo(
  ({
    clearSelectionModelFnRef,
    onAgentsSelected,
    isRowSelectable,
    minHeight,
    maxHeight,
    loading,
    agents = [],
    agentMetrics = [],
    allowSelection,
    onPaginationModelChange,
    paginationModel,
    sortModel,
    onSortModelChange,
    rowCount,
    autoHeight,
    noRowsOverlay,
    density = "standard",
    columns,
  }) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
      [],
    );

    useEffect(() => {
      if (clearSelectionModelFnRef == null) {
        return;
      }
      clearSelectionModelFnRef.current = function () {
        setSelectionModel([]);
      };
    }, [setSelectionModel, clearSelectionModelFnRef]);

    function getTelemetry(agentID: string, telemetryType: string): number {
      if (agentMetrics == null) {
        return 0;
      }
      // should probably have a lookup table here rather than interpolate in two places
      const metricName = telemetryType;

      // get all metrics for this agent that match the pattern /^destination\/\w+$/
      // those are metrics for data received by a destination, ignoring values before the processors
      const metrics = agentMetrics.filter(
        (m) =>
          m.name === metricName &&
          m.agentID! === agentID &&
          m.nodeID.startsWith("destination/") &&
          !m.nodeID.endsWith("/processors"),
      );
      if (metrics == null) {
        return 0;
      }
      // to make this sortable, we use the raw value and provide a valueFormatter implementation to show units
      return metrics.reduce((a, b) => a + b.value, 0);
    }

    const agentsWithTelemetry = agents.map((agent) => {
      return {
        ...agent,
        logs: getTelemetry(agent.id, TELEMETRY_SIZE_METRICS.logs),
        metrics: getTelemetry(agent.id, TELEMETRY_SIZE_METRICS.metrics),
        traces: getTelemetry(agent.id, TELEMETRY_SIZE_METRICS.traces),
      };
    });

    function handleSelect(s: GridRowSelectionModel) {
      setSelectionModel(s);

      isFunction(onAgentsSelected) && onAgentsSelected(s);
    }

    return (
      <DataGridPro<TableAgentWithTelemetry>
        checkboxSelection={isFunction(onAgentsSelected) && allowSelection}
        isRowSelectable={isRowSelectable}
        onRowSelectionModelChange={handleSelect}
        rowSelectionModel={selectionModel}
        density={density}
        slots={{
          noRowsOverlay: noRowsOverlay ?? DefaultNoRowsOverlay,
        }}
        style={{ minHeight, maxHeight }}
        loading={loading}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        disableRowSelectionOnClick
        columns={columns}
        rows={agentsWithTelemetry}
        paginationMode="server"
        paginationModel={paginationModel}
        pagination
        onPaginationModelChange={onPaginationModelChange}
        rowCount={rowCount}
        data-testid="agents-table"
        autoHeight={autoHeight}
        sortingOrder={["asc", "desc"]}
      />
    );
  },
);

const DefaultNoRowsOverlay: React.FC = () => {
  return (
    <Stack
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      No Agents
    </Stack>
  );
};
