import { Box, Button, DialogContent, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { DialogResource, ResourceType } from ".";
import { Kind, PipelineType } from "../../graphql/generated";
import { ResourceKind, Stability } from "../../types/resources";
import { telemetryTypesValue } from "../../utils/classes/resource-configuration";
import { metadataSatisfiesSubstring } from "../../utils/metadata-satisfies-substring";
import { TitleSection } from "../DialogComponents";
import { PlusCircleIcon, ProcessorIcon } from "../Icons";
import {
  ResourceTypeButton,
  ResourceTypeButtonContainer,
} from "../ResourceTypeButton";
import { useResourceDialog } from "./ResourceDialogContext";
import { someResourceOfType } from "./utils";
import styles from "./resource-dialog.module.scss";

interface SelectViewProps {
  resourceTypes: ResourceType[];
  resources: DialogResource[];
  setSelected: (t: ResourceType) => void;
  setCreateNew: (b: boolean) => void;
  kind: ResourceKind;
  platform: string;
  pipelineType?: PipelineType;
}

const otherString = "Other";
export const SelectView: React.FC<SelectViewProps> = ({
  platform,
  resourceTypes,
  resources,
  setSelected,
  setCreateNew,
  kind,
  pipelineType,
}) => {
  const advancedString = "Advanced";

  const [resourceSearchValue, setResourceSearch] = useState("");
  const { onClose } = useResourceDialog();

  const memoizedResourceTypes: ResourceType[] = useMemo(() => {
    const copy = resourceTypes.slice();
    return copy
      .filter((rt) => filterByPlatform(platform, kind, rt))
      .sort((a, b) =>
        a.metadata
          .displayName!.toLowerCase()
          .localeCompare(b.metadata.displayName!.toLowerCase()),
      );
  }, [resourceTypes, kind, platform]);

  const categorizedResourceTypes: {
    [category: string]: ResourceType[];
  } = resourceTypesByCategory(memoizedResourceTypes);

  const title = pipelineType
    ? `Add ${telemetryTypesValue(pipelineType)} ${kind}`
    : `Add ${kind}`;
  return (
    <>
      <TitleSection title={title} onClose={onClose} />

      <DialogContent style={{ marginBottom: "10px" }}>
        <Stack
          spacing={1}
          style={{ flexGrow: 1, height: "100%", overflow: "hidden" }}
        >
          <ResourceTypeButtonContainer
            onSearchChange={(v: string) => setResourceSearch(v)}
          >
            {/* Display Add Processor Bundle button */}
            {Object.keys(categorizedResourceTypes)
              .filter((category) => category === "Bundle")
              .map((category) => (
                <Stack
                  key={category}
                  onClick={() => {
                    const bundleItems = categorizedResourceTypes[category];
                    setSelected(bundleItems[0]);
                    setCreateNew(true);
                  }}
                  alignItems="center"
                  justifyContent="center"
                  padding={0.5}
                >
                  <Button
                    size="small"
                    variant="text"
                    startIcon={<PlusCircleIcon />}
                    sx={{ width: "100%" }}
                  >
                    Add processor bundle
                  </Button>
                </Stack>
              ))}

            {Object.keys(categorizedResourceTypes).length === 1 &&
            Object.keys(categorizedResourceTypes)[0] === otherString ? (
              <CategoryResourceButtons
                key={"No Category"}
                category={undefined}
                items={categorizedResourceTypes[otherString]}
                resourceSearchValue={resourceSearchValue}
                resources={resources}
                setCreateNew={setCreateNew}
                setSelected={setSelected}
              />
            ) : (
              <>
                {Object.keys(categorizedResourceTypes)
                  .filter(
                    (category) =>
                      category !== advancedString && category !== "Bundle",
                  )
                  .map((category) => (
                    <CategoryResourceButtons
                      key={category}
                      category={category}
                      items={categorizedResourceTypes[category]}
                      resourceSearchValue={resourceSearchValue}
                      resources={resources}
                      setCreateNew={setCreateNew}
                      setSelected={setSelected}
                    />
                  ))}
                {categorizedResourceTypes[advancedString] && (
                  <CategoryResourceButtons
                    key={advancedString}
                    category={advancedString}
                    items={categorizedResourceTypes[advancedString]}
                    resourceSearchValue={resourceSearchValue}
                    resources={resources}
                    setCreateNew={setCreateNew}
                    setSelected={setSelected}
                  />
                )}
              </>
            )}
          </ResourceTypeButtonContainer>
        </Stack>
      </DialogContent>
    </>
  );
};

function filterByPlatform(
  platform: string,
  kind: ResourceKind,
  resourceType: ResourceType,
) {
  if (kind !== Kind.Source) {
    return true;
  }

  if (platform === "unknown") {
    return true;
  }

  return resourceType.spec.supportedPlatforms.some((p) => p === platform);
}

function resourceTypesByCategory(resourceTypes: ResourceType[]): {
  [category: string]: ResourceType[];
} {
  return resourceTypes.reduce(
    (acc: { [key: string]: ResourceType[] }, p: ResourceType[][0]) => {
      const category: string =
        p.metadata.labels?.category?.replaceAll("-", " ") ?? otherString;
      if (!acc[category]) {
        acc[category] = [p];
      } else {
        acc[category] = [...acc[category]!, p];
      }

      return acc;
    },
    {},
  );
}

interface CategoryResourceButtonsProps {
  category: string | undefined;
  items: ResourceType[];
  resources: DialogResource[];
  resourceSearchValue: string;
  setCreateNew: (b: boolean) => void;
  setSelected: (t: ResourceType) => void;
}

function CategoryResourceButtons({
  category,
  items,
  resources,
  resourceSearchValue,
  setCreateNew,
  setSelected,
}: CategoryResourceButtonsProps) {
  const filteredItems = items
    // Filter resource types by the resourceSearchValue
    .filter((rt) => metadataSatisfiesSubstring(rt, resourceSearchValue));
  return (
    <>
      {/* Only display the category label if there is a unique category and the category has items*/}
      {category !== undefined && filteredItems.length > 0 && (
        <Box className={styles.category}>
          <Typography fontSize={18} fontWeight={600}>
            {category}
          </Typography>
        </Box>
      )}
      {
        // map the results to categorized resource buttons
        filteredItems.map((resourceType) => {
          const matchingResourcesExist = someResourceOfType(
            resources,
            resourceType,
          );

          // Either we send the directly to the form if there are no existing resources
          // of that type, or we send them to the Choose View by just setting the selected.
          function onSelect() {
            setSelected(resourceType);
            if (!matchingResourcesExist) {
              setCreateNew(true);
            }
          }

          return (
            <ResourceTypeButton
              icon={resourceType.metadata.icon}
              iconOverrideSVG={
                <ProcessorIcon className={styles.overrideIcon} />
              }
              key={resourceType.metadata.name}
              displayName={resourceType.metadata.displayName!}
              onSelect={() => onSelect()}
              telemetryTypes={resourceType.spec.telemetryTypes}
              stability={resourceType.metadata.stability ?? Stability.UNKNOWN}
            />
          );
        })
      }
    </>
  );
}
