import { FormHelperText, Stack, TextField } from "@mui/material";
import { ChangeEvent, memo, useState } from "react";
import { ParameterDefinition } from "../../../graphql/generated";
import { SafeLink } from "../../SafeLink";
import { useValidationContext } from "../ValidationContext";
import { validateStrIntField } from "../validation-functions";
import { ParamInputProps } from "./ParameterInput";

const IntParamInputComponent: React.FC<ParamInputProps<number>> = ({
  definition,
  value,
  readOnly,
  onValueChange,
}) => {
  const { errors, setError, touch, touched } = useValidationContext();
  const [inputValue, setInputValue] = useState<string>(
    getInitValue(definition, value),
  );

  function handleInputValueChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;

    if (newValue === "") {
      setInputValue("");
      onValueChange && onValueChange(0);
      return;
    }

    // check the last character is a number
    const lastChar = newValue[newValue.length - 1];
    if (isNaN(Number(lastChar))) {
      return;
    }

    setInputValue(newValue);
    setError(definition.name, validateStrIntField(definition, newValue));
    onValueChange && onValueChange(Number(newValue));
  }

  return (
    <TextField
      value={inputValue}
      onChange={handleInputValueChange}
      name={definition.name}
      fullWidth
      disabled={readOnly}
      slotProps={{
        htmlInput: {
          inputMode: "numeric",
        },
        formHelperText: {
          sx: { marginLeft: "-2px" },
        },
      }}
      size="small"
      label={definition.label}
      onBlur={() => touch(definition.name)}
      helperText={
        <>
          {errors[definition.name] && touched[definition.name] && (
            <FormHelperText sx={{ marginLeft: 0 }} component="span" error>
              {errors[definition.name]}
            </FormHelperText>
          )}
          <FormHelperText sx={{ marginLeft: 2 }} component="span">
            {definition.description}
          </FormHelperText>
          {definition.documentation && (
            <Stack component={"span"}>
              {definition.documentation.map((d) => (
                <SafeLink
                  href={d.url}
                  rel="noreferrer"
                  target="_blank"
                  key={d.url}
                >
                  {d.text}
                </SafeLink>
              ))}
            </Stack>
          )}
        </>
      }
      required={definition.required}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      data-testid={`int-param-input-${definition.name}`}
    />
  );
};

function getInitValue(definition: ParameterDefinition, value?: number) {
  if (value) return `${value}`;
  if (definition.default) return `${definition.default}`;
  return "0";
}

export const IntParamInput = memo(IntParamInputComponent);
