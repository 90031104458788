import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import { capitalize } from "lodash";
import { useMemo, useState } from "react";
import { ReactFlowProvider } from "reactflow";
import { Graph, PipelineType } from "../../graphql/generated";
import { hasPipelineTypeFlag } from "../../types/configuration";
import { Page } from "../../utils/graph/utils";
import { ConfigurationFlowV2 } from "../ConfigurationFlowV2";
import { BPGraph } from "../ConfigurationFlowV2/graph";
import { ChevronDown } from "../Icons";
import { ProcessorDialogContextProvider } from "../ResourceDialog/ProcessorDialogContext";
import { BPGraphProvider } from "./BPGraphProvider";
import { GraphMetricsProvider } from "./GraphMetricsProvider";
import { useV2PipelineGraph } from "./PipelineGraphV2Context";
import { RoutingContextProvider } from "./RoutingContext/RoutingContext";
import { AttributeName } from "./types";
import styles from "./pipeline-graph-v2.module.scss";

interface PipelineGraphAccordionProps {
  period: string;
  loading: boolean;
  pipelineType: PipelineType; // logs, metrics, or traces
  processorPreviewEnabled: boolean;
  expanded: boolean;
  onChange: () => void;
}

const StyledAccordion = styled(Accordion)({
  "&:before": { display: "none" },
  border: "none",
});

const bassSummary = `${styles.accordionSummary}`;
const expandedSummary = `${styles.accordionSummary} ${styles.expanded}`;

export const PipelineGraphAccordion: React.FC<PipelineGraphAccordionProps> = ({
  period,
  loading,
  pipelineType,
  processorPreviewEnabled,
  ...accordionProps
}) => {
  const [summaryClass, setSummaryClass] = useState<string>(
    accordionProps.expanded ? expandedSummary : bassSummary,
  );

  const { readOnlyGraph, configuration, refetchConfiguration } =
    useV2PipelineGraph();

  const graphForPipeline = useMemo(
    () =>
      newBPGraphForPipeline(pipelineType as PipelineType, configuration?.graph),
    [configuration?.graph, pipelineType],
  );
  return (
    <BPGraphProvider
      graph={graphForPipeline}
      pipelineType={pipelineType as PipelineType}
    >
      <GraphMetricsProvider
        telemetryType={pipelineType}
        page={Page.Configuration}
      >
        <ReactFlowProvider>
          <RoutingContextProvider
            readOnly={!!readOnlyGraph}
            configuration={configuration}
            pipelineType={pipelineType}
          >
            <ProcessorDialogContextProvider
              configuration={configuration}
              refetchConfiguration={refetchConfiguration}
              readOnly={!!readOnlyGraph}
              pipelineType={pipelineType}
              withProcessorPreview={processorPreviewEnabled}
            >
              <StyledAccordion
                {...accordionProps}
                data-testid={`pipeline-graph-${pipelineType}`}
              >
                <AccordionSummary
                  expandIcon={<ChevronDown />}
                  className={summaryClass}
                  onTransitionEnd={() => {
                    accordionProps.expanded
                      ? setSummaryClass(expandedSummary)
                      : setSummaryClass(bassSummary);
                  }}
                >
                  <Typography variant="h6">
                    {capitalize(pipelineType)}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails classes={{ root: styles.card }}>
                  <ConfigurationFlowV2
                    period={period}
                    selectedTelemetry={pipelineType}
                    page={Page.Configuration}
                    loading={loading}
                  />
                </AccordionDetails>
              </StyledAccordion>
            </ProcessorDialogContextProvider>
          </RoutingContextProvider>
        </ReactFlowProvider>
      </GraphMetricsProvider>
    </BPGraphProvider>
  );
};

function newBPGraphForPipeline(
  pipelineType: PipelineType,
  graph?: Graph | null,
): BPGraph {
  const allSignals = new BPGraph({
    sources: graph?.sources ?? [],
    targets: graph?.targets ?? [],
    intermediates: graph?.intermediates ?? [],
    edges:
      graph?.edges.filter(
        (e) => e.attributes[AttributeName.PipelineType] === pipelineType,
      ) || [],
    attributes: graph?.attributes || {},
  });

  return new BPGraph({
    sources: allSignals
      .sources()
      .filter((node) =>
        hasPipelineTypeFlag(pipelineType, allSignals.supportedTypeFlags(node)),
      ),
    targets: allSignals
      .targets()
      .filter((node) =>
        hasPipelineTypeFlag(pipelineType, allSignals.supportedTypeFlags(node)),
      ),
    intermediates: allSignals
      .intermediates()
      .filter((node) =>
        hasPipelineTypeFlag(pipelineType, allSignals.supportedTypeFlags(node)),
      ),
    edges:
      allSignals
        .edges()
        .filter(
          (e) => e.attributes[AttributeName.PipelineType] === pipelineType,
        ) || [],
    attributes: graph?.attributes || {},
  });
}
