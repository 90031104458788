import { Dialog, DialogContent, DialogProps, Stack } from "@mui/material";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { hasPermission, useRole } from "../../contexts/RBAC";
import {
  Kind,
  PipelineType,
  ResourceConfiguration,
  Role,
} from "../../graphql/generated";
import { usePipelineGraph } from "../../hooks/usePipelineGraph";
import { BPConfiguration } from "../../utils/classes";
import { TitleSection } from "../DialogComponents";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { ResourceConfigurationEditor } from "../ResourceConfigurationEditor";

interface ExtensionsDialogProps {
  open: boolean;
  closeDialog: () => void;
}

export const ExtensionsDialog: React.FC<ExtensionsDialogProps> = ({
  open,
  closeDialog,
}) => {
  const { configuration, readOnlyGraph, refetchConfiguration } =
    usePipelineGraph();

  const role = useRole();

  if (configuration == null) {
    return null;
  }

  return (
    <ExtensionsDialogComponent
      open={open}
      closeDialog={closeDialog}
      extensions={configuration.spec?.extensions ?? []}
      configuration={configuration}
      readOnly={readOnlyGraph || !hasPermission(Role.User, role)}
      refetchConfiguration={refetchConfiguration}
    />
  );
};

interface ExtensionsDialogComponentProps extends Omit<DialogProps, "onClose"> {
  extensions: ResourceConfiguration[];
  closeDialog: () => void;
  configuration: NonNullable<MinimumRequiredConfig>;
  refetchConfiguration: () => void;
  readOnly: boolean;
}

const ExtensionsDialogComponent: React.FC<ExtensionsDialogComponentProps> = ({
  extensions: extensionsProp,
  configuration,
  readOnly,
  closeDialog,
  refetchConfiguration,
  ...dialogProps
}) => {
  const [extensions, setExtensions] =
    useState<ResourceConfiguration[]>(extensionsProp);
  const { enqueueSnackbar } = useSnackbar();

  // If the extensions prop changes, update the extensions state
  useEffect(() => {
    if (extensionsProp.length !== 0) {
      setExtensions(extensionsProp);
    }
  }, [extensionsProp, setExtensions]);

  // handleClose is called when a user clicks off the dialog or the "X" button
  function handleClose() {
    if (!isEqual(extensions, extensionsProp)) {
      const ok = window.confirm("Discard changes?");
      if (!ok) {
        return;
      }
      // reset form values if chooses to discard.
      setExtensions(extensionsProp);
    }

    closeDialog();
  }

  const title = `Configuration ${configuration.metadata!.name}: Extensions`;
  const description = `Extensions provide capabilities on top of the primary \
functionality of the agent. They will be started in the order they appear below \
and will be stopped in reverse order.`;

  async function handleUpdateInlineExtensions(
    newExtensions: ResourceConfiguration[],
  ) {
    const bpConfiguration = new BPConfiguration(configuration);
    bpConfiguration.setExtensions(newExtensions);

    try {
      await bpConfiguration.apply();
    } catch (e) {
      enqueueSnackbar("Failed to update extensions", { variant: "error" });
      console.error("Failed to update extensions", {
        error: e,
        configurationName: configuration.metadata?.name,
      });
      return false;
    }
    return true;
  }

  return (
    <Dialog {...dialogProps} maxWidth="md" fullWidth onClose={handleClose}>
      <Stack>
        <TitleSection
          title={title}
          onClose={handleClose}
          description={description}
        />
        <DialogContent>
          <ResourceConfigurationEditor
            closeDialog={closeDialog}
            readOnly={readOnly}
            initItems={extensionsProp}
            items={extensions}
            kind={Kind.Extension}
            onItemsChange={setExtensions}
            refetchConfiguration={refetchConfiguration}
            telemetryTypes={[
              PipelineType.Logs,
              PipelineType.Metrics,
              PipelineType.Traces,
            ]}
            updateInlineItems={handleUpdateInlineExtensions}
          />
        </DialogContent>
      </Stack>
    </Dialog>
  );
};
