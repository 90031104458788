import { gql } from "@apollo/client";

export const FullParameterDefinitionFragment = gql`
  fragment FullParameterDefinition on ParameterDefinition {
    name
    label
    description
    relevantIf {
      name
      operator
      value
    }
    documentation {
      text
      url
    }
    advancedConfig
    required
    type
    validValues
    default
    options {
      creatable
      multiline
      trackUnchecked
      sectionHeader
      subHeader
      horizontalDivider
      gridColumns
      labels
      metricCategories {
        label
        column
        metrics {
          name
          description
          kpi
        }
      }
      password
      processorContext
      sensitive
      variant
    }
    properties
  }
`;
