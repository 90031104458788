import { gql, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Divider,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { RBACWrapper } from "../../../contexts/RBAC";
import {
  Role,
  useChangeAccountMutation,
  useSettingsMenuQuery,
} from "../../../graphql/generated";
import { AuthType } from "../../../types/auth";
import { getAuthType } from "../../../utils/get-auth-type";
import { AccountSwitcher } from "../../AccountSwitcher";
import { BindplaneVersion } from "../../BindplaneVersion/BindplaneVersion";
import {
  BuildingIcon,
  CheckIcon,
  ListIcon,
  LogoutIcon,
  UsersIcon,
} from "../../Icons";
import { SubMenuItem } from "../../SubMenuItem";
import styles from "./settings-menu.module.scss";

gql`
  query settingsMenu {
    organizations {
      metadata {
        id
        name
        version
      }
      licenseType
      accounts {
        metadata {
          id
          name
          version
          displayName
        }
      }
    }
    organization {
      metadata {
        id
        name
        version
      }
      licenseType
    }
    user {
      metadata {
        id
        name
        version
        displayName
      }
      spec {
        activeAccount
      }
      isOrgAdmin
    }

    multiAccountEnabled: featureGate(id: "multiaccount")
    auditTrailEnabled: featureGate(id: "audit-trail")
  }

  mutation changeAccount($input: ChangeAccountInput!) {
    changeAccount(input: $input) {
      metadata {
        name
        id
        version
        displayName
      }
      spec {
        activeAccount
      }
    }
  }
`;

/**
 * Settings menu for the top navigation bar.
 */
export const EESettingsMenu: React.FC<MenuProps> = ({
  anchorEl,
  open,
  onClose,
}) => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth0();

  const [changeAccount] = useChangeAccountMutation({
    onError(err) {
      console.error("Failed to change project", {
        error: err,
      });
      enqueueSnackbar("Error changing project", {
        variant: "error",
        key: "change-project-error",
      });
    },
  });

  const { data: userInfo } = useSettingsMenuQuery({
    onError: (err) => {
      console.error("Failed to get user info", {
        error: err,
      });
      enqueueSnackbar("Error getting user info", {
        variant: "error",
        key: "get-user-info-error",
      });
    },
  });

  async function handleChangeAccount(accountId: string) {
    await changeAccount({
      variables: {
        input: {
          accountId,
        },
      },
    });
    window.location.pathname = "/overview";
  }

  async function handleLogout() {
    try {
      if (getAuthType() === AuthType.Auth0) {
        await logout({ openUrl: false });
      }
      localStorage.removeItem("user");
      await apolloClient.resetStore();
      await fetch("/logout", {
        method: "PUT",
      });
      navigate("/login");
    } catch (err) {
      console.error("Failed to logout", { error: err });
    }
  }

  const otherOrgs =
    userInfo?.organizations
      ?.filter((org) => org.metadata.id !== userInfo.organization.metadata.id)
      .sort((a, b) =>
        a.metadata.name
          .toLowerCase()
          .localeCompare(b.metadata.name.toLowerCase()),
      ) ?? [];

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      style={{
        left: "1.475rem",
      }}
      slotProps={{
        paper: {
          className: styles["settings-paper"],
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        "aria-labelledby": "settings-button",
      }}
      classes={{ paper: styles.menu }}
    >
      <div>
        <Stack className={styles["info-stack"]} marginBottom={1}>
          <Typography variant="h6">
            {userInfo?.organization?.metadata.name}
          </Typography>
        </Stack>
        <AccountSwitcher
          accounts={
            userInfo?.organizations?.find(
              (org) => org.metadata.id === userInfo?.organization.metadata.id,
            )?.accounts
          }
          activeAccountId={userInfo?.user?.spec.activeAccount}
          orgAdmin={userInfo?.user.isOrgAdmin}
          multiAccountEnabled={userInfo?.multiAccountEnabled}
          handleChangeAccount={handleChangeAccount}
        />
      </div>

      <MenuList>
        {userInfo?.user.isOrgAdmin && (
          <MenuItem
            onClick={() => navigate("/organization")}
            data-testid={`org-page-menu-button`}
          >
            <BuildingIcon style={{ height: 24, width: 32 }} />
            Organization
          </MenuItem>
        )}
        <RBACWrapper requiredRole={Role.Admin}>
          <MenuItem
            onClick={() => navigate("/project")}
            data-testid={`project-page-menu-button`}
          >
            <UsersIcon className={styles["settings-icon"]} />
            Project
          </MenuItem>
        </RBACWrapper>
        {userInfo?.auditTrailEnabled && (
          <MenuItem
            onClick={() => navigate("/audit-logs")}
            data-testid={`audit-trail-menu-button`}
          >
            <ListIcon className={styles["settings-icon"]} />
            Audit Logs
          </MenuItem>
        )}
        <Divider />
        {otherOrgs?.length > 0 && (
          <SubMenuItem label={"Switch Organization"}>
            <MenuItem
              disabled
              selected
              classes={{ root: styles.current }}
              value={userInfo?.organization.metadata.id}
              data-testid={`current-org-${userInfo?.organization.metadata.id}`}
            >
              <Typography className={styles["current-typo"]}>
                {userInfo?.organization.metadata.name}
              </Typography>
              <CheckIcon className={styles.check} />
            </MenuItem>
            {otherOrgs.map((org) => (
              <MenuItem
                key={org.metadata.id}
                onClick={() => handleChangeAccount(org.accounts[0].metadata.id)}
                data-testid={`other-org-${org.metadata.id}`}
              >
                {org.metadata.name}
              </MenuItem>
            ))}
          </SubMenuItem>
        )}
        <MenuItem onClick={handleLogout} data-testid={`logout-menu-button`}>
          <LogoutIcon className={styles["settings-icon"]} />
          Logout
        </MenuItem>
      </MenuList>
      <Divider />

      <Stack
        direction="row"
        justifyContent="flex-end"
        paddingTop={1}
        paddingX={1}
      >
        <BindplaneVersion licenseType={userInfo?.organization.licenseType} />
      </Stack>
    </Menu>
  );
};
