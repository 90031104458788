import { createContext, useContext, useState } from "react";
import { MaxValueMap } from "../GraphComponents";
import { DEFAULT_TELEMETRY_TYPE } from "../MeasurementControlBar/MeasurementControlBar";
import { V2Config } from "../PipelineGraphV2/types";

export interface PipelineGraphContextValue {
  configuration: V2Config;
  refetchConfiguration: () => void;

  selectedTelemetryType: string;
  hoveredSet: string[];
  setHoveredSet: React.Dispatch<React.SetStateAction<string[]>>;

  maxValues: MaxValueMap;

  readOnlyGraph?: boolean;

  onAddSource: () => void;
  onAddDestination: () => void;

  agentID?: string;
}

export interface PipelineGraphProviderProps {
  configuration: V2Config;
  refetchConfiguration: () => void;
  selectedTelemetryType: string;
  readOnly?: boolean;
  onAddSource: () => void;
  onAddDestination: () => void;
  maxValues: MaxValueMap;
  agentID?: string;
}

const defaultValue: PipelineGraphContextValue = {
  configuration: null,
  refetchConfiguration: () => {},
  selectedTelemetryType: DEFAULT_TELEMETRY_TYPE,
  hoveredSet: [],
  setHoveredSet: () => {},
  maxValues: {
    maxMetricValue: 0,
    maxLogValue: 0,
    maxTraceValue: 0,
  },
  onAddSource: () => {
    throw new Error("PipelineGraphProvider not specified");
  },
  onAddDestination: () => {
    throw new Error("PipelineGraphProvider not specified");
  },
  agentID: undefined,
};

export const PipelineContext = createContext(defaultValue);

export const PipelineGraphProvider: React.FC<PipelineGraphProviderProps> = ({
  children,
  selectedTelemetryType,
  configuration,
  refetchConfiguration,
  onAddSource,
  onAddDestination,
  readOnly,
  maxValues,
  agentID,
}) => {
  const [hoveredSet, setHoveredSet] = useState<string[]>([]);

  return (
    <PipelineContext.Provider
      value={{
        configuration,
        refetchConfiguration,
        setHoveredSet,
        hoveredSet,
        selectedTelemetryType,
        readOnlyGraph: readOnly,
        onAddSource,
        onAddDestination,
        maxValues,
        agentID,
      }}
    >
      {children}
    </PipelineContext.Provider>
  );
};

export function useV1PipelineGraph(): PipelineGraphContextValue {
  return useContext(PipelineContext);
}
