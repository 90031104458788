import { gql } from "@apollo/client";
import { Kind } from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { useComponentDialog } from "../Dialogs/hooks/useComponentDialog";
import { ResourceCard } from "./ResourceCard";

gql`
  query SourceType($name: String!) {
    sourceType(name: $name) {
      metadata {
        id
        name
        version
        displayName
        icon
        displayName
        description
        additionalInfo {
          message
          documentation {
            text
            url
          }
        }
        resourceDocLink
      }
      spec {
        parameters {
          label
          name
          description
          required
          type
          default
          documentation {
            text
            url
          }
          relevantIf {
            name
            operator
            value
          }
          advancedConfig
          validValues
          options {
            creatable
            trackUnchecked
            sectionHeader
            subHeader
            horizontalDivider
            gridColumns
            labels
            metricCategories {
              label
              column
              metrics {
                name
                description
                kpi
              }
            }
            password
            processorContext
            sensitive
            variant
          }
        }
      }
    }
  }
`;

interface InlineSourceCardProps {
  title: string;
  subtitle: string;
  icon: string;
  paused: boolean;
  resource: BPResourceConfiguration;
  disabled?: boolean;
  refetchConfiguration: () => void;
  onDeleteSuccess?: () => void;
}

export const InlineSourceCard: React.FC<InlineSourceCardProps> = ({
  title,
  subtitle,
  icon,
  paused,
  resource,
  disabled,
  refetchConfiguration,
  onDeleteSuccess,
}) => {
  const { editComponent } = useComponentDialog();

  return (
    <ResourceCard
      dataTestID={`source-card-${resource.id}`}
      title={title}
      subtitle={subtitle}
      icon={icon}
      disabled={disabled || paused}
      paused={paused}
      onClick={() =>
        editComponent({
          kind: Kind.Source,
          resource,
          onSuccess: ({ operation }) => {
            refetchConfiguration();
            if (operation === "delete") {
              onDeleteSuccess?.();
            }
          },
        })
      }
    />
  );
};
