import { gql } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { RBACWrapper } from "../../components/RBACWrapper/RBACWrapper";
import {
  Role,
  useGetConfigurationVersionsQuery,
} from "../../graphql/generated";
import { ConfigurationEditDataProvider } from "../../hooks/useConfigurationEditData";
import { useRefetchOnConfigurationChange } from "../../hooks/useRefetchOnConfigurationChanges";
import { useRole } from "../../hooks/useRole";
import { hasPermission } from "../../utils/has-permission";
import { nameAndVersion } from "../../utils/version-helpers";
import { DiffDialog } from "../DiffDialog/DiffDialog";
import { DiscardDialog } from "../DiscardDialog";
import { EditingControlBar } from "../EditingControlBar";
import { EditingState } from "../EditingControlBar/EditingControlBar";
import { OtelConfigEditor } from "../OtelConfigEditor/OtelConfigEditor";
import { V2Switch } from "../PipelineGraph/V2Switch";
import { RolloutProgress } from "../RolloutProgress";
import { VersionsData } from "./versions-data";
import styles from "../PipelineGraph/pipeline-graph.module.scss";

gql`
  query getConfigurationVersions($name: String!) {
    configurationVersions(name: $name) {
      apiVersion
      metadata {
        name
        id
        version
      }

      activeTypes

      status {
        current
        pending
        latest
      }
    }
  }
`;

interface ConfigurationEditorProps {
  configurationName: string;
  isOtel: boolean;
  hideRolloutActions?: boolean;
  historyVersion?: number;
  setHistoryVersion: (n?: number) => void;
  diffDialogOpen: boolean;
  setDiffDialogOpen: (b: boolean) => void;
}

/**
 * ConfigurationEditor is a component used to edit and show information about a
 * BPOP Configuration.  It can show a YAML editor for an oTel configuration
 * or a pipeline graph for a pipeline configuration.
 *
 * @param configurationName should be the non-versioned name of the config
 * @param isOtel is a boolean that determines whether to display a PipelineGraph
 * or OtelConfig component.
 * @param hideRolloutActions is a boolean that determines whether to display the
 * rollout actions (i.e pause, resume, start)
 * @param historyVersion is used to sync RolloutHistory with DiffDialog
 * @param setHistoryVersion is used to sync RolloutHistory with DiffDialog
 * @param diffDialogOpen is used to sync RolloutHistory with DiffDialog
 * @param setDiffDialogOpen is used to sync RolloutHistory with DiffDialog
 * @returns
 */
export const ConfigurationEditor: React.FC<ConfigurationEditorProps> = ({
  configurationName,
  isOtel,
  hideRolloutActions,
  historyVersion,
  setHistoryVersion,
  diffDialogOpen,
  setDiffDialogOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [versionsData, setVersionsData] = useState<VersionsData>();
  const [view, setView] = useState<"live" | "draft">("live");
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [versionState, setVersionState] = useState<
    "current" | "new" | "pending"
  >();

  const [showCompareVersions, setShowCompareVersions] =
    useState<boolean>(false);

  const [editingState, setEditingState] =
    useState<EditingState>("pastCompleted");

  const role = useRole();

  const { refetch } = useGetConfigurationVersionsQuery({
    variables: {
      name: configurationName,
    },
    onError(error) {
      console.error("Error fetching configuration versions", {
        error,
        configurationName,
      });
      enqueueSnackbar("Failed to fetch configuration versions.", {
        variant: "error",
      });
    },
    fetchPolicy: "network-only",
    onCompleted(data) {
      const newVersionsData = new VersionsData(data);
      if (newVersionsData.findNew()) {
        setVersionState("new");
      } else if (newVersionsData.findPending()) {
        setVersionState("pending");
      } else if (newVersionsData.findCurrent()) {
        setVersionState("current");
      }
      setVersionsData(newVersionsData);

      if (newVersionsData.draftVersion()) {
        setView("draft");
      }
    },
  });

  useRefetchOnConfigurationChange(configurationName, refetch);

  const handleLiveDraftSwitcherChange = useCallback(
    (v: "live" | "draft") => {
      setView(v);
      setEditingState(v);
    },
    [setView],
  );

  const readOnly = useMemo(() => {
    return (
      (view === "live" && versionsData?.draftVersion() != null) ||
      !hasPermission(Role.User, role)
    );
  }, [role, versionsData, view]);

  const discardTooltip = useMemo(() => {
    if (versionsData?.liveVersion() === undefined) {
      return "No previous version exists";
    }

    if (versionState === "pending") {
      return "Cannot discard draft after rollout begins";
    }

    if (versionState === "current") {
      return "No changes since last version";
    }

    return "";
  }, [versionsData, versionState]);

  const compareTooltip = useMemo(() => {
    if (versionsData?.liveVersion() === undefined) {
      return "No previous version exists";
    }

    if (versionState === "current") {
      return "No changes since last version";
    }

    return "";
  }, [versionsData, versionState]);

  if (versionState == null || versionsData == null) {
    return (
      <Card className={styles.card}>
        <Stack height={300} alignItems="center" justifyContent={"center"}>
          <CircularProgress size={100} />
        </Stack>
      </Card>
    );
  }

  const EditorComponent = isOtel ? OtelConfigEditor : V2Switch;

  const configurationNameAndVersion =
    view === "draft"
      ? nameAndVersion(configurationName, versionsData.draftVersion())
      : nameAndVersion(configurationName, versionsData.liveVersion());

  async function handleDiscardConfiguration() {
    try {
      await discardConfiguration(configurationName);
      setDiscardDialogOpen(false);
      setEditingState("discarding");
      enqueueSnackbar("Discarded draft!", {
        variant: "success",
      });
      setView("live");
      refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to discard draft", {
        variant: "error",
      });
    }
  }

  async function discardConfiguration(configurationName: string) {
    const url = `/v1/configurations/${configurationName}/revert`;
    const resp = await fetch(url, { method: "PUT" });

    if (!resp.ok) {
      throw new Error(
        `Failed to discard draft: ${resp.status} ${resp.statusText}`,
      );
    }
  }

  return (
    <>
      <RBACWrapper requiredRole={Role.User}>
        <EditingControlBar
          editingState={editingState}
          setEditingState={setEditingState}
          bottomMargin
        >
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            data-testid="edit-config-bar"
          >
            <Tooltip title={discardTooltip}>
              <span>
                <Button
                  variant="outlined"
                  disabled={
                    versionsData.liveVersion() === undefined ||
                    versionState === "pending" ||
                    versionState === "current"
                  }
                  onClick={() => setDiscardDialogOpen(true)}
                  color="error"
                  data-testid="config-edit-discard-button"
                >
                  Discard
                </Button>
              </span>
            </Tooltip>
            <Tooltip title={compareTooltip}>
              <span>
                <Button
                  variant="outlined"
                  disabled={!showCompareVersions}
                  onClick={() => setDiffDialogOpen(true)}
                  data-testid="config-edit-compare-button"
                >
                  Compare
                </Button>
              </span>
            </Tooltip>
            <RolloutProgress
              configurationName={configurationName}
              configurationVersion={
                versionState === "new" ? "latest" : versionState
              }
              hideActions={hideRolloutActions || versionState === "current"}
              setShowCompareVersions={setShowCompareVersions}
              setEditingState={setEditingState}
              editingState={editingState}
              view={view}
            />
          </Stack>
        </EditingControlBar>
      </RBACWrapper>

      <DiscardDialog
        open={discardDialogOpen}
        onDiscard={handleDiscardConfiguration}
        onClose={() => setDiscardDialogOpen(false)}
      />
      <DiffDialog
        onClose={() => setDiffDialogOpen(false)}
        configurationName={configurationName}
        open={diffDialogOpen}
        historyVersion={historyVersion}
        setHistoryVersion={setHistoryVersion}
      />

      <Box className={styles.card}>
        <ConfigurationEditDataProvider
          configurationName={configurationNameAndVersion}
          live={versionsData.liveVersion() ?? 0}
          draft={versionsData.draftVersion()}
        >
          <EditorComponent
            configurationName={configurationNameAndVersion}
            readOnly={readOnly}
            showLiveDraftSwitcher={
              versionsData.hasDraft() && versionsData.hasLive()
            }
            tab={view}
            onTabChange={handleLiveDraftSwitcherChange}
          />
        </ConfigurationEditDataProvider>
      </Box>
    </>
  );
};
