import { useMemo } from "react";
import { PipelineType } from "../../../graphql/generated";
import { ComponentType } from "../../../utils/classes/component-type";
import { findResource } from "../../../utils/classes/configuration";
import { BPParameterizedResource } from "../../../utils/classes/resource";
import { getNextComponentPaths } from "../../../utils/classes/resource-configuration";
import { size as nodeSize } from "../../ConfigurationFlowV2/layout-grid";
import { useBPGraph } from "../BPGraphProvider";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { V2Config } from "../types";
import { AddRouteButton } from "./AddRouteButton";
import { useRouting } from "./RoutingContext";
import styles from "./routing-node-wrapper.module.scss";

interface RoutingNodeWrapperProps {
  componentType: ComponentType;
  componentPath: string;
  nodeType: string;
  nodeID: string;
  xPos: number;
  yPos: number;
  nodeResource?: BPParameterizedResource | null;
}

/**
 * RoutingNodeWrapper is a wrapper component that renders the Routing connection
 * buttons for a connectable node and handles the click event to connect the node.
 */
export const RoutingNodeWrapper: React.FC<RoutingNodeWrapperProps> = ({
  children,
  componentPath,
  componentType,
  nodeID,
  nodeType,
  nodeResource,
  xPos,
  yPos,
}) => {
  const { configuration } = useV2PipelineGraph();
  const { hoveredNode, pipelineType } = useBPGraph();

  const {
    canConnectToCurrentRouting: canConnect,
    routeHasAvailableConnections: hasAvailableConnections,
    isConnecting,
    readOnly,
    onConnect,
  } = useRouting();

  const connectable = componentPath && canConnect(componentPath);
  const classNames = [styles.container];
  if (connectable) {
    classNames.push(styles.highlighted);
  }
  function handleConnectClick() {
    if (!connectable) {
      return;
    }
    onConnect(componentPath!);
  }

  const isRoutableType = ["sources", "connectors", "processors"].includes(
    componentType,
  );
  const hovered = nodeID === hoveredNode;

  const routeButtons = useMemo(() => {
    if (!isRoutableType) return [];

    if (componentType !== "connectors") {
      const orphaned = nodeIsOrphaned(
        configuration,
        componentPath,
        pipelineType,
      );
      const fadeIn = (hovered || orphaned) && !readOnly && !isConnecting;

      const labelX = xPos + nodeSize(nodeType).width + 2;
      const labelY = yPos + nodeSize(nodeType).height / 2 - 19; // 12 is half the height of the button
      return [
        <AddRouteButton
          key={nodeID}
          labelX={labelX}
          labelY={labelY}
          nodeID={nodeID}
          componentPath={componentPath}
          componentType={componentType}
          fadeIn={fadeIn}
          hide={!hasAvailableConnections(nodeID)}
        />,
      ];
    }

    if (nodeResource == null)
      throw new Error("Node resource is required for connectors");

    const labelX = xPos + nodeSize(nodeType).width + 2;
    const startY = yPos + 5;
    return nodeResource.routeIDsFromParameters().map((id, i) => {
      if (id == null) throw new Error("Route ID is required");

      const orphaned = nodeIsOrphaned(
        configuration,
        componentPath,
        pipelineType,
        id,
      );
      const fadeIn = (hovered || orphaned) && !readOnly && !isConnecting;

      return (
        <AddRouteButton
          key={`${nodeID}-sub-route-${i}`}
          labelX={labelX}
          labelY={startY + (i + 1) * 38.5}
          nodeID={nodeID}
          componentType={componentType}
          componentPath={componentPath}
          fadeIn={fadeIn}
          routeId={id}
          hide={!hasAvailableConnections(nodeID, id)}
        />
      );
    });
  }, [
    isRoutableType,
    componentType,
    componentPath,
    nodeID,
    nodeType,
    nodeResource,
    xPos,
    yPos,
    configuration,
    pipelineType,
    hovered,
    readOnly,
    isConnecting,
    hasAvailableConnections,
  ]);

  return (
    <div onClick={handleConnectClick} className={styles.container}>
      <div className={connectable ? styles.highlighted : undefined}>
        {children}
      </div>

      {routeButtons}
    </div>
  );
};

function nodeIsOrphaned(
  configuration: V2Config,
  componentPath: string,
  pipelineType: PipelineType,
  routeId?: string,
): boolean {
  if (!configuration) return false;
  const rc = findResource(configuration, componentPath);
  return (
    rc != null && getNextComponentPaths(rc, pipelineType, routeId).length === 0
  );
}
