import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import {
  GetResourceTypesQuery,
  Kind,
  ResourceType,
  useGetResourceTypesQuery,
} from "../../graphql/generated";
import { usePipelineGraph } from "../../hooks/usePipelineGraph";
import { isResourceV2, Stability } from "../../types/resources";
import { processorTypeNeedsRouteReceiver } from "../../utils/classes/configuration";
import { metadataSatisfiesSubstring } from "../../utils/metadata-satisfies-substring";
import { ActionsSection } from "../DialogComponents";
import { PlusCircleIcon, ProcessorIcon } from "../Icons";
import { useResourceDialog } from "../ResourceDialog/ResourceDialogContext";
import {
  ResourceTypeButton,
  ResourceTypeButtonContainer,
} from "../ResourceTypeButton";
import { ViewHeading } from "./ViewHeading";
import mixins from "../../styles/mixins.module.scss";
import styles from "./select-view.module.scss";

interface SelectViewProps {
  resourceType?: ResourceType;
  resourceKind: Kind.Processor | Kind.Extension;

  // The supported telemetry types of the source that the processor will be added to
  telemetryTypes?: string[];

  onBack?: () => void;
  onSelect: (rt: GetResourceTypesQuery["resourceTypes"][0]) => void;
  context?: string | null;
}

export const SelectView: React.FC<SelectViewProps> = ({
  onBack,
  onSelect,
  telemetryTypes,
  resourceType,
  resourceKind,
  context,
}) => {
  const { data, loading, error } = useGetResourceTypesQuery({
    variables:
      resourceKind === Kind.Processor
        ? { kind: Kind.ProcessorType }
        : { kind: Kind.ExtensionType },
  });

  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { configuration } = usePipelineGraph();
  const isV2Configuration = isResourceV2(configuration);

  useEffect(() => {
    if (error != null) {
      enqueueSnackbar("Error retrieving data for Resource Type.", {
        variant: "error",
        key: "Error retrieving data for Resource Type.",
      });
    }
  }, [enqueueSnackbar, error, context]);
  const isProcessor = resourceKind === Kind.Processor;
  const title = `Add ${isProcessor ? "a" : "an"} ${resourceKind.toLowerCase()}`;
  const description = isProcessor
    ? `Choose a processor you'd like to ${context === "bundle" ? "add to the Processor Bundle" : "configure for this component"}.`
    : `Choose an extension you'd like to configure.`;

  // Filter the list of support resource types down
  // to those who are supported by the version of the current configuration
  const supportedResourceTypesForAPIVersion: GetResourceTypesQuery["resourceTypes"] =
    useMemo(
      () =>
        isV2Configuration && isProcessor
          ? (data?.resourceTypes.filter(
              (pt) => !processorTypeNeedsRouteReceiver(pt),
            ) ?? [])
          : (data?.resourceTypes ?? []),
      [data?.resourceTypes, isV2Configuration, isProcessor],
    );

  // Filter the list of supported resource types down
  // to those whose telemetry matches the telemetry of the
  // source. i.e. don't show a log processor for a metric source
  const supportedResourceTypesForTelemetryType: GetResourceTypesQuery["resourceTypes"] =
    useMemo(
      () =>
        telemetryTypes
          ? supportedResourceTypesForAPIVersion.filter((rt) =>
              rt.spec.telemetryTypes.some((t) => telemetryTypes.includes(t)),
            )
          : supportedResourceTypesForAPIVersion,
      [supportedResourceTypesForAPIVersion, telemetryTypes],
    );

  // Filter the list of supported resource types down to those matching the search,
  // and sort them in alphabetical order by display name
  const matchingResourceTypes: GetResourceTypesQuery["resourceTypes"] = useMemo(
    () =>
      supportedResourceTypesForTelemetryType
        .filter((rt) => metadataSatisfiesSubstring(rt, search))
        .sort((a, b) =>
          (a.metadata.displayName?.toLowerCase() ?? "").localeCompare(
            b.metadata.displayName?.toLowerCase() ?? "",
          ),
        ),
    [supportedResourceTypesForTelemetryType, search],
  );
  const categorizedResourceTypes = resourceTypesByCategory(
    matchingResourceTypes,
  );

  return (
    <Stack
      className={mixins["flex-grow"]}
      spacing={2}
      onKeyDown={(event) => {
        if (event.key === "Escape" && onBack) {
          event.stopPropagation();
          onBack();
        }
      }}
    >
      <ViewHeading heading={title} subHeading={description} />
      <ResourceTypeButtonContainer
        onSearchChange={(v: string) => setSearch(v)}
        placeholder={
          resourceKind === Kind.Processor
            ? "Search for a processor..."
            : resourceKind === Kind.Extension
              ? "Search for an extension..."
              : ""
        }
      >
        {loading && (
          <Box display="flex" justifyContent={"center"} marginTop={2}>
            <CircularProgress />
          </Box>
        )}
        {Object.keys(categorizedResourceTypes)
          .sort((a, b) => a.localeCompare(b))
          .filter((k) => k !== "Advanced")
          .map((k) => (
            <ResourceCategory
              key={k}
              title={k}
              items={categorizedResourceTypes[k]}
              onSelect={onSelect}
              context={context}
            />
          ))}
        {categorizedResourceTypes["Advanced"] && (
          <ResourceCategory
            key="Advanced"
            title="Advanced"
            items={categorizedResourceTypes["Advanced"]}
            onSelect={onSelect}
            context={context}
          />
        )}
      </ResourceTypeButtonContainer>
      {onBack && (
        <ActionsSection>
          <Button variant="outlined" color="secondary" onClick={onBack}>
            Back
          </Button>
        </ActionsSection>
      )}
    </Stack>
  );
};

function resourceTypesByCategory(
  resourceTypes: GetResourceTypesQuery["resourceTypes"],
): {
  [category: string]: GetResourceTypesQuery["resourceTypes"];
} {
  return resourceTypes.reduce(
    (
      acc: { [key: string]: GetResourceTypesQuery["resourceTypes"] },
      p: GetResourceTypesQuery["resourceTypes"][0],
    ) => {
      const category: string =
        p.metadata.labels?.category?.replaceAll("-", " ") ?? "Other";
      if (!acc[category]) {
        acc[category] = [p];
      } else {
        acc[category] = [...acc[category]!, p];
      }

      return acc;
    },
    {},
  );
}

interface ResourceCategoryProps {
  title: string;
  items: GetResourceTypesQuery["resourceTypes"];
  onSelect: (rt: GetResourceTypesQuery["resourceTypes"][0]) => void;
  context?: string | null;
}

function ResourceCategory({
  onSelect,
  items,
  title,
  context,
}: ResourceCategoryProps) {
  const { inLibrary } = useResourceDialog();
  return (
    <>
      {title !== "Bundle" && (
        <Box className={styles.category}>
          <Typography fontSize={18} fontWeight={600}>
            {title}
          </Typography>
        </Box>
      )}

      {items.map((p) =>
        p.metadata.name === "processor_bundle" ? (
          context !== "bundle" &&
          !inLibrary && (
            <Stack
              key={p.metadata.name}
              onClick={() => onSelect(p)}
              alignItems="center"
              justifyContent="center"
              padding={0.5}
            >
              <Button
                size="small"
                variant="text"
                startIcon={<PlusCircleIcon />}
                sx={{ width: "100%" }}
              >
                Add processor bundle
              </Button>
            </Stack>
          )
        ) : (
          <ResourceTypeButton
            icon={p.metadata.icon}
            iconOverrideSVG={<ProcessorIcon className={styles.overrideIcon} />}
            key={p.metadata.name}
            displayName={p.metadata.displayName!}
            onSelect={() => onSelect(p)}
            telemetryTypes={p.spec.telemetryTypes}
            stability={p.metadata.stability ?? Stability.UNKNOWN}
          />
        ),
      )}
    </>
  );
}
