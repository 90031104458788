import { createContext, useContext, useState } from "react";

interface ResourceDialogContextValue {
  onClose: () => void;
  purpose: "create" | "edit" | null;
  inLibrary?: boolean;
  setInLibrary?: (value: boolean) => void;
}

const defaults: ResourceDialogContextValue = {
  onClose: () => {},
  purpose: null,
};

const ResourceDialogContext = createContext(defaults);

export const useResourceDialog = () => {
  return useContext(ResourceDialogContext);
};

interface Props {
  onClose: () => void;
  purpose: "create" | "edit";
}

export const ResourceDialogContextProvider: React.FC<Props> = ({
  children,
  onClose,
  purpose,
}) => {
  const [inLibrary, setInLibrary] = useState<boolean>(false);
  return (
    <ResourceDialogContext.Provider
      value={{
        purpose,
        onClose,
        inLibrary,
        setInLibrary,
      }}
    >
      {children}
    </ResourceDialogContext.Provider>
  );
};
