import { isEmpty } from "lodash";
import { GetConfigurationNamesQuery } from "../../../../graphql/generated";
import { Platform } from "./types";

/**
 * Check if the platform is a k8s platform
 * @param platform Reported platform
 */
export function platformIsKubernetes(platform: string): boolean {
  return (
    platform === Platform.KubernetesDaemonset ||
    platform === Platform.KubernetesDeployment ||
    platform === Platform.kubernetesGateway
  );
}

export function platformIsK8sGateway(platform: string): boolean {
  return platform === Platform.kubernetesGateway;
}

/**
 * Check if the platform is an OpenShift platform
 * @param platform Reported platform
 */
export function platformIsOpenShift(platform: string): boolean {
  return (
    platform === Platform.OpenShiftDaemonset ||
    platform === Platform.OpenShiftDeployment ||
    platform === Platform.OpenShiftGateway
  );
}

/**
 * Check if the platform is a container platform
 * @param platform Reported platform
 */
export function platformIsContainer(platform: string): boolean {
  return platformIsKubernetes(platform) || platformIsOpenShift(platform);
}

export const AGENT_LABEL_INSTALL_ID = "install_id";

export function installCommandUrl(params: {
  platform: string;
  configuration: string | null;
  installId: string | null;
  agentType: string;
}): string {
  const url = new URL(window.location.href);
  url.pathname = "/v1/agent-versions/latest/install-command";

  const searchParams: {
    platform: string;
    labels?: string;
    "agent-type": string;
  } = {
    platform: params.platform,
    "agent-type": params.agentType,
  };

  let labels = "";
  if (params.configuration) {
    labels += `configuration=${params.configuration}`;
  }

  if (params.installId) {
    if (labels) {
      labels += ",";
    }
    labels += `${AGENT_LABEL_INSTALL_ID}=${params.installId}`;
  }

  if (!isEmpty(labels)) {
    searchParams.labels = labels;
  }

  url.search = new URLSearchParams(searchParams).toString();
  return url.href;
}

export function filterConfigurationsByPlatform(
  configs: GetConfigurationNamesQuery["configurations"]["configurations"],
  platform: string,
): GetConfigurationNamesQuery["configurations"]["configurations"] {
  switch (platform) {
    case Platform.KubernetesDaemonset:
      return configs.filter(
        (c) => c.metadata.labels?.platform === Platform.KubernetesDaemonset,
      );
    case Platform.KubernetesDeployment:
      return configs.filter(
        (c) => c.metadata.labels?.platform === Platform.KubernetesDeployment,
      );
    case Platform.kubernetesGateway:
      return configs.filter(
        (c) => c.metadata.labels?.platform === Platform.kubernetesGateway,
      );
    case Platform.Linux:
      return configs.filter((c) => c.metadata.labels?.platform === "linux");
    case Platform.macOS:
      return configs.filter((c) => c.metadata.labels?.platform === "macos");
    case Platform.OpenShiftDaemonset:
      return configs.filter(
        (c) => c.metadata.labels?.platform === Platform.OpenShiftDaemonset,
      );
    case Platform.OpenShiftDeployment:
      return configs.filter(
        (c) => c.metadata.labels?.platform === Platform.OpenShiftDeployment,
      );
    case Platform.OpenShiftGateway:
      return configs.filter(
        (c) => c.metadata.labels?.platform === Platform.OpenShiftGateway,
      );
    case Platform.Windows:
      return configs.filter((c) => c.metadata.labels?.platform === "windows");
    default:
      return [];
  }
}
