import Elk, { ElkNode } from "elkjs/lib/elk.bundled.js";
import { Edge, Node } from "reactflow";
import { Node as GraphNode } from "../../graphql/generated";
import { V2NodeData } from "../PipelineGraphV2/types";
import { BPGraph, NodeId } from "./graph";
import { LayoutGrid, size } from "./layout-grid";
import { Layout } from "./layout-interface";

const elk = new Elk();

export class LayoutElk implements Layout {
  graph: BPGraph;
  grid: LayoutGrid;
  nodes: Node[];
  edges: Edge[];
  nodeMap?: Map<NodeId, Node>;
  constructor(graph: BPGraph) {
    this.graph = graph;

    // use the normal layout to get the initial positions and layers of the nodes
    this.grid = new LayoutGrid(this.graph);

    this.nodes = graph.getAllNodes().map((n) => ({
      ...n,
      position: this.grid.getPosition(n.id),
      data: {},
    }));
    this.edges = graph
      .edges()
      .filter(
        (e) =>
          graph.findNode(e.source) &&
          e.target != null &&
          graph.findNode(e.target),
      ) as Edge[];

    this.addButtons();
  }

  getPosition(nodeID: NodeId, _?: string): { x: number; y: number } {
    if (!this.nodeMap) {
      throw new Error(
        "Layout has not been initialized, need to call perform first",
      );
    }
    return this.nodeMap.get(nodeID)?.position ?? { x: 0, y: 0 };
  }

  async perform(): Promise<void> {
    const graph: ElkNode = {
      id: "elk-root",
      layoutOptions: {
        "elk.algorithm": "layered",
        "elk.direction": "RIGHT",
        "elk.spacing.nodeNode": "100",
        "elk.layered.spacing.nodeNodeBetweenLayers": "200",
        "elk.layered.layering.strategy": "LONGEST_PATH",
        "elk.padding": "1000",
      },
      children: [
        ...this.nodes.map((n) => toElkNode(n, this.grid.getLayer(n.id))),
      ],
      edges: this.edges.map((edge) => ({
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
      })),
    };

    const root = await elk.layout(graph);
    const layoutNodes = toNodeMap(root);

    // Determine the position for add-source and add-destination nodes
    // while traversing the layout tree.
    let cardWidth = 0;
    let maxSourceY = 0;
    let maxDestinationY = 0;
    let maxDestinationX = 0;

    const nextNodes = this.nodes.map((node) => {
      const elkNode = layoutNodes.get(node.id)!;
      const position = { x: elkNode.x!, y: elkNode.y! };

      if (node.type === "sourceNode") {
        cardWidth = elkNode.width!;
        maxSourceY = Math.max(maxSourceY, position.y);
      }

      if (node.type === "destinationNode") {
        cardWidth = elkNode.width!;
        maxDestinationY = Math.max(maxDestinationY, position.y);
        maxDestinationX = Math.max(maxDestinationX, position.x);
      }

      return {
        ...node,
        position,
      };
    });

    // Set the button location if they are not cards.
    if (this.graph.sources().length > 0) {
      nextNodes.find((n) => n.id === "add-source")!.position = {
        x: cardWidth / 2 - cardWidth * 0.1,
        y: maxSourceY + 1.5 * cardWidth,
      };
    }

    if (this.graph.targets().length > 0) {
      nextNodes.find((n) => n.id === "add-destination")!.position = {
        x: maxDestinationX + cardWidth / 2 - cardWidth * 0.185,
        y: maxDestinationY + 1.5 * cardWidth,
      };
    }

    this.nodes = nextNodes;
    this.nodeMap = new Map(this.nodes.map((n) => [n.id, n]));
  }

  /**
   * addButtons adds both the add-source and add-destination buttons to the graph.
   * If there are no sources or destinations it will add and connect a dummy
   * processor node to the respective button.
   */
  addButtons(): void {
    this.nodes.push(addSourceNode);
    this.nodes.push(addDestinationNode);

    // If there are no sources, we need to add our dummy processor nodes
    if (this.graph.sources().length === 0) {
      this.nodes.push(addSourceProcessorNode);
      this.edges.push({
        id: "add-source_add-source-proc",
        source: "add-source",
        target: "add-source-proc",
      });

      // if there are targets, connect the add-source-proc to all other intermediates (destination processors)
      if (this.graph.targets().length > 0) {
        for (const node of this.graph.intermediates()) {
          this.edges.push({
            id: `add-source-proc_${node.id}`,
            source: "add-source-proc",
            target: node.id,
          });
        }
      } else {
        // connect add-source-proc to add-destination-proc if there are no targets
        this.edges.push({
          id: "add-source-proc_add-destination-proc",
          source: "add-source-proc",
          target: "add-destination-proc",
        });
      }
    }
    if (this.graph.targets().length === 0) {
      this.nodes.push(addDestinationProcessorNode);
      this.edges.push({
        id: "add-destination_add-destination-proc",
        source: "add-destination",
        target: "add-destination-proc",
      });
    }
    this.nodes.push({
      id: "add-destination-proc",
      position: { x: 0, y: 0 },
      type: "add-destination",
      data: {},
    });
  }
}

function toElkNode(n: GraphNode | Node<V2NodeData>, layer: number): ElkNode {
  let { width, height } = size(n.type);
  // The uiControlNode defaults to the size of the button (40 x 40)
  // if its not a button but rather the card its dimension is 120 x 120
  if (n.type === "uiControlNode" && !(n as Node<V2NodeData>).data?.isButton) {
    [width, height] = [120, 120];
  }

  const node: ElkNode = {
    id: n.id,
    width,
    height,
  };

  node.layoutOptions = {};

  switch (n.type) {
    case "sourceNode":
      node.layoutOptions["elk.layered.layering.layerConstraint"] = "FIRST";
      break;
    case "destinationNode":
      node.layoutOptions["elk.layered.layering.layerConstraint"] = "LAST";
      break;
  }

  if (layer >= 0) {
    node.layoutOptions["elk.layered.layerIndex "] = `${layer}`;
  }

  return node;
}

function toNodeMap(node: ElkNode): Map<NodeId, ElkNode> {
  // Have to recurse through the children, if they exist
  // to get all the nodes
  const map = new Map<NodeId, ElkNode>();
  const setChildrenOnMap = (n: ElkNode) => {
    if (n.children) {
      for (const child of n.children) {
        setChildrenOnMap(child);
      }
    } else {
      map.set(n.id, n);
    }
  };

  setChildrenOnMap(node);
  return map;
}

const addDestinationNode: Node = {
  id: "add-destination",
  position: { x: 0, y: 0 },
  type: "uiControlNode",
  data: {},
};

const addDestinationProcessorNode: Node = {
  id: "add-destination-proc",
  position: { x: 0, y: 0 },
  type: "dummyProcessorNode",
  data: {},
};

const addSourceNode: Node = {
  id: "add-source",
  position: { x: 0, y: 0 },
  type: "uiControlNode",
  data: {},
};

const addSourceProcessorNode: Node = {
  id: "add-source-proc",
  position: { x: 0, y: 0 },
  type: "dummyProcessorNode",
  data: {},
};
