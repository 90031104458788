import { EnqueueSnackbar } from "notistack";
import { MinimumRequiredConfig } from "../../components/PipelineGraph/PipelineGraph";
import { FormValues } from "../../components/ResourceConfigForm";
import { Kind, ParameterizedResource } from "../../graphql/generated";
import { UpdateStatus } from "../../types/resources";
import { BPConfiguration, BPResourceConfiguration } from "../classes";
import { componentTypeOfKind } from "../classes/component-type";
import { BPParameterizedResource } from "../classes/resource";
import { applyResources } from "../rest/apply-resources";
import { nameAndVersion, trimVersion } from "../version-helpers";

interface saveComponentOptions<T> {
  // Required for inline components to save the component to the configuration.
  configuration?: MinimumRequiredConfig;

  // The component to modify.
  component: BPResourceConfiguration;

  // The library resource
  resource?: ParameterizedResource;

  // The kind of the component.
  kind: Kind;

  // The new form values to apply to the component.
  formValues: FormValues;

  // Called on successful save.
  onSuccess: (result: T) => void;

  // Called on failed save.
  onError?: (e: any) => void;

  // Optional snackbar to display messages.
  enqueueSnackbar?: EnqueueSnackbar;
}

/**
 * Save the component to the server. On success or error, the appropriate callback is
 * called.
 *
 * If the component is a library resource, the resource will be saved. Configuration that
 * use this resource will be automatically updated on the server but it may be necessary
 * to refetch the latest if a configuration is visible.
 */
export async function saveComponent({
  kind,
  component,
  resource,
  configuration,
  formValues,
  onSuccess,
  onError,
  enqueueSnackbar,
}: saveComponentOptions<{ updated: BPResourceConfiguration }>) {
  const componentText = component.isInline()
    ? `${kind}`
    : `${kind} ${resource?.metadata.name}`;

  await updateComponent({
    configuration,
    component,
    resource,
    kind,
    onSuccess,
    onError,
    enqueueSnackbar,
    updateResource: (resource) => {
      formValues.processors && formValues.processors.length > 0
        ? resource.setProcessors(formValues.processors ?? [])
        : resource.setParamsFromMap(formValues);
    },
    updateComponent: (component) => {
      formValues.processors && formValues.processors.length > 0
        ? component.setProcessors(formValues.processors ?? [])
        : component.setParamsFromMap(formValues);
    },
    successMessage: `Successfully saved ${componentText}!`,
    errorMessage: `Failed to save ${componentText}.`,
  });
}

// ----------------------------------------------------------------------

interface saveComponentToLibraryOptions
  extends saveComponentOptions<{
    resource: BPParameterizedResource;
    reference: BPResourceConfiguration;
  }> {
  // The name of the component to save.
  name: string;
}

export async function saveComponentToLibrary({
  kind,
  name,
  component,
  configuration,
  formValues,
  onSuccess,
  onError,
  enqueueSnackbar,
}: saveComponentToLibraryOptions) {
  // create the component for the library
  const { resource, reference } = component.toLibraryResource(
    kind,
    name,
    formValues.displayName,
  );
  resource.setParamsFromMap(formValues);
  resource.setProcessors(formValues.processors ?? []);

  // update the configuration to use the library component
  const updatedConfig = new BPConfiguration(configuration);
  updatedConfig.updateComponent(reference, componentTypeOfKind(kind));

  // save them both to the server
  try {
    const { updates } = await applyResources([resource, updatedConfig]);
    if (updates.some((u) => u.status === UpdateStatus.INVALID)) {
      console.error(updates);
      throw new Error(`failed to save ${kind} on configuration`);
    }
    enqueueSnackbar?.(`Successfully added ${kind} ${name} to Library!`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    onSuccess({ resource, reference });
  } catch (err) {
    enqueueSnackbar?.(`Failed to add ${kind} ${name} to Library.`, {
      variant: "error",
      autoHideDuration: 5000,
    });
    console.error(err);
    onError && onError(err);
  }
}

// ----------------------------------------------------------------------

interface unlinkComponentFromLibraryOptions
  extends saveComponentOptions<{ inline: BPResourceConfiguration }> {
  // The name of the component to unlink.
  name: string;
  // The library resource
  resource: ParameterizedResource;
}

export async function unlinkComponentFromLibrary({
  kind,
  name,
  component,
  resource,
  configuration,
  formValues,
  onSuccess,
  onError,
  enqueueSnackbar,
}: unlinkComponentFromLibraryOptions) {
  if (resource == null) {
    enqueueSnackbar?.(`Cannot unlink ${kind}.`, { variant: "error" });
    console.error(`no resource found when requesting ${kind} and type`);
    return { inline: component };
  }

  const inline = component.toInlineResource(resource);
  inline.setParamsFromMap(formValues);
  inline.setProcessors(formValues.processors ?? []);

  const updatedConfig = new BPConfiguration(configuration);

  try {
    updatedConfig.updateComponent(inline, componentTypeOfKind(kind));
    const update = await updatedConfig.apply();
    if (update.status === UpdateStatus.INVALID) {
      console.error(update);
      throw new Error(`failed to save ${kind} on configuration`);
    }
    enqueueSnackbar?.(
      `Successfully unlinked ${trimVersion(name)} from Library!`,
      {
        variant: "success",
        autoHideDuration: 3000,
      },
    );
    onSuccess({ inline });
  } catch (err) {
    enqueueSnackbar?.(`Failed to update configuration with new ${kind}`, {
      variant: "error",
      autoHideDuration: 5000,
    });
    console.error(err);
    onError && onError(err);
  }
}

// ----------------------------------------------------------------------

interface deleteComponentOptions {
  // Required to delete the component from the configuration.
  configuration: MinimumRequiredConfig;

  // The component to modify.
  component: BPResourceConfiguration;

  // The kind of the component.
  kind: Kind;

  // Called on successful delete.
  onSuccess: (result: { deleted: BPResourceConfiguration }) => void;

  // Called on failed delete.
  onError?: (e: any) => void;

  // Optional snackbar to display messages.
  enqueueSnackbar?: EnqueueSnackbar;
}

/**
 * Deletes a component from the configuration and removes all routes targetting it. If the
 * resource is in the library, this will only delete the reference from this configuration
 * and the library item will remain there.
 */
export async function deleteComponent({
  kind,
  component,
  configuration,
  onSuccess,
  onError,
  enqueueSnackbar,
}: deleteComponentOptions) {
  try {
    const updatedConfig = new BPConfiguration(configuration);
    updatedConfig.removeComponent(component, componentTypeOfKind(kind));

    const update = await updatedConfig.apply();
    if (update.status === UpdateStatus.INVALID) {
      console.error(update);
      throw new Error(`failed to save ${kind} on configuration`);
    }

    enqueueSnackbar?.(`Successfully deleted ${kind}!`, {
      variant: "success",
      autoHideDuration: 3000,
    });
    onSuccess({ deleted: component });
  } catch (err) {
    enqueueSnackbar?.(`Failed to delete ${kind}.`, {
      variant: "error",
      autoHideDuration: 5000,
    });
    console.error(err);
    onError && onError(err);
  }
}

// ----------------------------------------------------------------------

interface togglePausedOptions {
  // Required to pause inline components in the configuration.
  configuration?: MinimumRequiredConfig;

  // The component to modify.
  component: BPResourceConfiguration;

  // The library resource
  resource?: ParameterizedResource;

  // The kind of the component.
  kind: Kind;

  // Called on successful toggle.
  onSuccess: (result: { updated: BPResourceConfiguration }) => void;

  // Called on failed toggle.
  onError?: (e: any) => void;

  // Optional snackbar to display messages.
  enqueueSnackbar?: EnqueueSnackbar;
}

export async function togglePaused({
  configuration,
  component,
  resource,
  kind,
  onSuccess,
  onError,
  enqueueSnackbar,
}: togglePausedOptions) {
  let action: "pause" | "resume";
  let componentText: string;

  if (component.isInline()) {
    action = !component.disabled ? "pause" : "resume";
    componentText = `${kind}`;
  } else {
    action = !resource?.spec.disabled ? "pause" : "resume";
    componentText = `${kind} ${resource?.metadata.name}`;
  }

  await updateComponent({
    configuration,
    component,
    resource,
    kind,
    onSuccess,
    onError,
    enqueueSnackbar,
    updateResource: (resource) => resource.toggleDisabled(),
    updateComponent: (component) => {
      component.disabled = !component.disabled;
    },
    successMessage: `${componentText} ${action}d! 🎉`,
    errorMessage: `Failed to ${action} ${componentText}.`,
  });
}

// ----------------------------------------------------------------------

interface UpdateComponentOptions {
  configuration?: MinimumRequiredConfig;
  component: BPResourceConfiguration;
  resource?: ParameterizedResource;
  kind: Kind;
  onSuccess: (result: { updated: BPResourceConfiguration }) => void;
  onError?: (e: any) => void;
  enqueueSnackbar?: EnqueueSnackbar;
  updateResource: (resource: BPParameterizedResource) => void;
  updateComponent: (component: BPResourceConfiguration) => void;
  successMessage: string;
  errorMessage: string;
}

async function updateComponent({
  configuration,
  component,
  resource,
  kind,
  onSuccess,
  onError,
  enqueueSnackbar,
  updateResource,
  updateComponent,
  successMessage,
  errorMessage,
}: UpdateComponentOptions) {
  var componentText = `${kind}`;

  try {
    const newComponent = component.clone();
    updateComponent(newComponent);

    if (component.isInline()) {
      // update the configuration containing the inline component
      if (configuration == null) {
        throw new Error("Inline component requires Configuration");
      }

      const updatedConfig = new BPConfiguration(configuration);
      updatedConfig.updateComponent(newComponent, componentTypeOfKind(kind));

      const update = await updatedConfig.apply();

      if (update.status === UpdateStatus.INVALID) {
        throw new Error(`failed to save ${componentText} on configuration`);
      }
    } else {
      // update the library resource
      if (resource == null) {
        throw new Error("Library component requires a library resource");
      }

      componentText = `${kind} ${resource.metadata.name}`;

      const newResource = new BPParameterizedResource(resource);
      updateResource(newResource);

      const update = await newResource.apply();

      if (update.status === UpdateStatus.INVALID) {
        throw new Error(`failed to save ${componentText}`);
      }

      // update the component to point to the new version
      newComponent.name = nameAndVersion(
        update.resource?.metadata?.name,
        update.resource?.metadata?.version,
      );
    }

    enqueueSnackbar?.(successMessage, {
      variant: "success",
      autoHideDuration: 3000,
    });
    onSuccess({ updated: newComponent });
  } catch (err) {
    enqueueSnackbar?.(errorMessage, {
      variant: "error",
      autoHideDuration: 5000,
    });
    console.error(err);
    onError?.(err);
  }
}
