import { memo } from "react";
import { BPResourceConfiguration } from "../../../utils/classes";
import { CardMeasurementContent } from "../../CardMeasurementContent/CardMeasurementContent";
import { ResourceDestinationCard } from "../../Cards/ResourceDestinationCard";
import { useV1PipelineGraph } from "../PipelineGraphContext";
import { isNodeDisabled } from "./nodeUtils";

function DestinationNode(params: {
  data: {
    pipelineType: string;
    id: string;
    label: string;
    attributes: Record<string, any>;
    metric: string;
    connectedNodesAndEdges: string[];
  };
}) {
  const {
    configuration,
    refetchConfiguration,
    hoveredSet,
    setHoveredSet,
    selectedTelemetryType,
  } = useV1PipelineGraph();

  const { id, attributes, metric } = params.data;
  const { title, subtitle, icon, paused } = attributes;

  const destinationIndex =
    typeof attributes["destinationIndex"] === "number"
      ? attributes["destinationIndex"]
      : -1;

  // make sure the resource exists. There could be a timing issue where this node exists
  // but the corresponding resource has been deleted.
  const resource = configuration?.spec?.destinations?.[destinationIndex];
  if (resource == null) {
    return null;
  }

  const resourceConfig = new BPResourceConfiguration(resource);

  const isDisabled = isNodeDisabled(selectedTelemetryType, attributes);
  const isNotInHoverSet =
    hoveredSet.length > 0 &&
    !hoveredSet.find((elem) => elem === params.data.id);

  return (
    <div
      onMouseEnter={() => {
        setHoveredSet(params.data.connectedNodesAndEdges);
      }}
      onMouseLeave={() => setHoveredSet([])}
    >
      <ResourceDestinationCard
        key={id}
        title={title}
        subtitle={subtitle}
        icon={icon}
        paused={paused}
        resource={resourceConfig}
        disabled={isDisabled || isNotInHoverSet}
        refetchConfiguration={refetchConfiguration}
        onDeleteSuccess={() => setHoveredSet([])}
      />
      <CardMeasurementContent>{metric}</CardMeasurementContent>
    </div>
  );
}

export default memo(DestinationNode);
