import { createContext, useContext, useState } from "react";
import {
  useOverviewMetricsV2Subscription,
  usePipelineGraphV2MetricsSubscription,
} from "../../graphql/generated";
import { useOverviewPage } from "../../pages/overview/OverviewPageContext";
import { Page } from "../../utils/graph/utils";
import { BPGraphMetrics } from "../ConfigurationFlowV2/metrics";
import { useBPGraph } from "./BPGraphProvider";
import { PipelineGraphMetricsData } from "./PipelineGraphMetricsData";
import { useV2PipelineGraph } from "./PipelineGraphV2Context";

interface GraphMetricsProviderProps {
  telemetryType: string;
  page: Page;
}

type GraphMetricsContextValue = BPGraphMetrics | null;
const graphMetricsContext = createContext<GraphMetricsContextValue>(null);

export const GraphMetricsProvider: React.FC<GraphMetricsProviderProps> = ({
  telemetryType,
  page,
  children,
}) => {
  const [configurationGraphMetrics, setConfigurationGraphMetrics] =
    useState<BPGraphMetrics | null>(null);
  const [overviewGraphMetrics, setOverviewGraphMetrics] =
    useState<BPGraphMetrics | null>(null);
  const { period, configuration } = useV2PipelineGraph();
  const { graph } = useBPGraph();
  usePipelineGraphV2MetricsSubscription({
    variables: {
      period,
      name: configuration?.metadata.name,
    },
    skip: !configuration,
    onData(options) {
      if (!options.data.data) return;
      const metricData = new PipelineGraphMetricsData(
        options.data.data.configurationV2Metrics.metrics,
      );

      setConfigurationGraphMetrics(
        new BPGraphMetrics(graph, metricData, telemetryType, period),
      );
    },
  });

  const {
    selectedPeriod: overviewPeriod,
    selectedConfigs: overviewConfigs,
    selectedDestinations: overviewDestinations,
  } = useOverviewPage();
  useOverviewMetricsV2Subscription({
    variables: {
      period: overviewPeriod,
      configIDs: overviewConfigs.map((id) => id.toString()),
      destinationIDs: overviewDestinations.map((id) => id.toString()),
    },
    skip: !graph,
    onData(options) {
      if (!options.data.data) return;
      const metricData = new PipelineGraphMetricsData(
        options.data.data.overviewMetricsV2.metrics,
      );

      setOverviewGraphMetrics(
        new BPGraphMetrics(graph, metricData, telemetryType, overviewPeriod),
      );
    },
  });

  let graphMetrics: BPGraphMetrics | null = null;
  switch (page) {
    case Page.Configuration:
      graphMetrics = configurationGraphMetrics;
      break;
    case Page.Overview:
      graphMetrics = overviewGraphMetrics;
      break;
  }

  return (
    <graphMetricsContext.Provider value={graphMetrics}>
      {children}
    </graphMetricsContext.Provider>
  );
};

export function useGraphMetrics() {
  return useContext(graphMetricsContext);
}
