import { NodeProps } from "reactflow";
import { findResource } from "../../../utils/classes/configuration";
import { ProcessorCardV2 } from "../../Cards/ProcessorCardV2";
import { useProcessorsDialog } from "../../ResourceDialog/ProcessorDialogContext";
import { useBPGraph } from "../BPGraphProvider";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { useRouting } from "../RoutingContext/RoutingContext";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName, V2NodeData } from "../types";

export const ProcessorListNode: React.FC<NodeProps<V2NodeData>> = ({
  data,
  type,
  id,
  xPos,
  yPos,
}) => {
  const { attributes } = data;
  const { configuration } = useV2PipelineGraph();
  const { editProcessors } = useProcessorsDialog();
  const { onMouseEnterNode, onMouseExitNode } = useBPGraph();
  const { canConnectToCurrentRouting: canConnect } = useRouting();

  if (!configuration) {
    return null;
  }

  const resourceConfig = findResource(
    configuration,
    attributes[AttributeName.ComponentPath],
  );

  return (
    <RoutingNodeWrapper
      componentType={"processors"}
      componentPath={attributes[AttributeName.ComponentPath]}
      nodeType={type}
      nodeID={id}
      xPos={xPos}
      yPos={yPos}
    >
      <div
        onMouseEnter={() => onMouseEnterNode(id)}
        onMouseLeave={onMouseExitNode}
      >
        <ProcessorCardV2
          nodeId={id}
          onClick={
            canConnect(attributes[AttributeName.ComponentPath])
              ? () => {}
              : () => editProcessors(attributes[AttributeName.ComponentPath])
          }
          processorCount={resourceConfig?.processors?.length ?? 0}
        />
      </div>
    </RoutingNodeWrapper>
  );
};
