import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Handle, Position } from "reactflow";
import colors from "../../styles/colors";
import { classes } from "../../utils/styles";
import { NoMaxWidthTooltip } from "../Custom/NoMaxWidthTooltip";
import { BookmarkIcon, PauseIcon } from "../Icons";
import styles from "./cards.module.scss";

interface ResourceCardProps {
  title: string;
  showBookmarkIcon?: boolean;
  subtitle?: string;
  icon?: string | null;
  altIcon?: React.ReactNode;
  paused?: boolean;
  disabled?: boolean;
  onClick: () => void;
  dataTestID?: string;
  scale?: string;
  insideLabel?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({
  title,
  showBookmarkIcon,
  subtitle,
  icon,
  altIcon,
  paused,
  disabled,
  onClick,
  dataTestID,
  scale,
  insideLabel,
  onMouseEnter,
  onMouseLeave,
}) => {
  const renderIcon = () => {
    if (icon) {
      return (
        <Box
          className={styles.icon}
          style={{ backgroundImage: `url(${icon})` }}
        />
      );
    }
    return altIcon;
  };

  scale = scale ?? "100%";

  return (
    <Box
      sx={{
        scale: scale,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Card
        data-testid="resource-card-test"
        className={classes([styles["resource-card"]])}
        style={{ background: paused ? colors.lightGray : "" }}
        onClick={onClick}
      >
        <CardActionArea
          className={classes([
            styles.action,
            disabled ? styles.disabled : undefined,
            paused ? styles.paused : undefined,
          ])}
        >
          <CardContent data-testid={dataTestID}>
            <Handle type="source" position={Position.Right} />
            <Handle type="target" position={Position.Left} />
            <Stack
              spacing={0}
              width="100%"
              height="100%"
              justifyContent={"center"}
              alignItems={"center"}
            >
              {renderIcon()}
              {insideLabel && <Typography>{insideLabel}</Typography>}
            </Stack>
            {paused && (
              <PauseIcon
                data-testid={`${dataTestID}-pause-icon`}
                className={styles["pause-icon"]}
              />
            )}
            {showBookmarkIcon && (
              <BookmarkIcon
                fill={colors.pixelPointBlue}
                stroke={colors.pixelPointBlue}
                className={styles["bookmark-icon"]}
                data-testid={`${dataTestID}-bookmark-icon`}
                width={"18px"}
              />
            )}
          </CardContent>
        </CardActionArea>
      </Card>

      <Stack alignItems="center" justifyContent={"center"} direction={"row"}>
        <NoMaxWidthTooltip title={title}>
          <Typography
            paddingTop={1}
            className={classes([
              styles.title,
              disabled ? styles.disabled : undefined,
            ])}
          >
            {title}
          </Typography>
        </NoMaxWidthTooltip>
      </Stack>
      <Stack alignItems="center" justifyContent={"center"} direction={"row"}>
        {subtitle && (
          <NoMaxWidthTooltip title={subtitle}>
            <Typography
              className={classes([
                styles.description,
                disabled ? styles.disabled : undefined,
              ])}
            >
              {subtitle}
            </Typography>
          </NoMaxWidthTooltip>
        )}
      </Stack>
    </Box>
  );
};
