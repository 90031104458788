import { ParameterizedResource } from "../../graphql/generated";
import { BPBaseResource } from "./base-resource";
import { BPResourceConfiguration } from "./resource-configuration";

export class BPParameterizedResource
  extends BPBaseResource
  implements ParameterizedResource
{
  __typename?: "ParameterizedResource" | undefined;

  constructor(r: ParameterizedResource) {
    super();
    this.kind = r.kind;
    this.metadata = r.metadata;
    this.spec = r.spec;
  }

  asResourceConfig(): BPResourceConfiguration {
    return new BPResourceConfiguration({
      name: this.metadata.name,
      id: this.metadata.id,
      disabled: this.spec.disabled,
      parameters: this.spec.parameters,
      processors: this.spec.processors,
    });
  }

  /**
   * routeIDsFromParameters determines the route IDs based on the
   * parameters for the parameterized resource.  Currently it
   * only supports the routing connector.
   */
  routeIDsFromParameters(): string[] {
    if (this.kind !== "Connector") return [];
    const routingParameter = this.spec.parameters?.find(
      (p) => p.name === "routes",
    );
    if (routingParameter == null) return [];
    return (routingParameter.value as { id: string }[]).map((r) => r.id);
  }
}

export function wrapResource(
  r: ParameterizedResource,
): BPParameterizedResource {
  return new BPParameterizedResource(r);
}

export function asParameterizedResource(
  r: Omit<ParameterizedResource, "__typename">,
): ParameterizedResource {
  return { ...r, __typename: "ParameterizedResource" };
}
