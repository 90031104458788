import {
  Alert,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { isFunction } from "lodash";
import { memo } from "react";
import { AdditionalInfo } from "../../graphql/generated";
import colors from "../../styles/colors";
import { BookmarkIcon, HelpCircleIcon, XIcon } from "../Icons";
import { SafeLink } from "../SafeLink";
import { getStabilityChip } from "../StabilityChip";
import styles from "./dialog-components.module.scss";

interface Props {
  description?: string;
  title?: string;
  additionalInfo?: AdditionalInfo | null;
  stability?: string;
  resourceDocLink?: string;
  onClose: () => void;
  showLibraryBookmark?: boolean;
  resourceInLibrary?: boolean;
  setAddToLibraryDialogOpen?: (b: boolean) => void;
  setUnlinkFromLibraryDialogOpen?: (b: boolean) => void;
  readOnly?: boolean;
}

const TitleSectionComponent: React.FC<Props> = ({
  description,
  title,
  additionalInfo,
  stability,
  resourceDocLink,
  onClose,
  showLibraryBookmark,
  resourceInLibrary,
  setAddToLibraryDialogOpen,
  setUnlinkFromLibraryDialogOpen,
  readOnly,
}) => {
  function handleBookmarkClick() {
    if (resourceInLibrary) {
      isFunction(setUnlinkFromLibraryDialogOpen)
        ? setUnlinkFromLibraryDialogOpen(true)
        : console.error(
            "No function provided for setUnlinkFromLibraryDialogOpen",
          );
    } else {
      isFunction(setAddToLibraryDialogOpen)
        ? setAddToLibraryDialogOpen(true)
        : console.error("No function provided for setAddToLibraryDialogOpen");
    }
  }

  let tooltipMessage: string;
  if (readOnly) {
    if (resourceInLibrary) {
      tooltipMessage = "Resource is in Library";
    } else {
      tooltipMessage = "Resource is not in Library";
    }
  } else {
    if (resourceInLibrary) {
      tooltipMessage = "Unlink resource from Library";
    } else {
      tooltipMessage = "Add resource to Library";
    }
  }

  return (
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack maxWidth={"80%"}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Tooltip title={title}>
              <Typography
                variant={"h5"}
                fontSize={28}
                fontWeight={600}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
              >
                {title}
              </Typography>
            </Tooltip>
            {resourceDocLink && (
              <IconButton
                LinkComponent={SafeLink}
                href={resourceDocLink}
                target="_blank"
                rel="noreferrer"
                color="primary"
              >
                <HelpCircleIcon />
              </IconButton>
            )}
            {getStabilityChip(stability)}
          </Stack>
          <Typography fontSize={18}>{description}</Typography>
        </Stack>

        <Stack direction={"row"} spacing={3}>
          {showLibraryBookmark && (
            <Tooltip title={tooltipMessage}>
              {/* span around button allows Tooltip to show when button is disabled, otherwise there are console errors */}
              <span>
                <IconButton
                  color={resourceInLibrary ? "primary" : undefined}
                  onClick={handleBookmarkClick}
                  disabled={readOnly}
                  data-testid="source-configuration-bookmark-icon"
                >
                  {resourceInLibrary && readOnly ? (
                    <BookmarkIcon
                      fill={colors.pixelPointBlue}
                      stroke={colors.pixelPointBlue}
                    />
                  ) : (
                    <BookmarkIcon
                      fill={resourceInLibrary ? colors.pixelPointBlue : "none"}
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )}

          <IconButton
            className={styles.close}
            onClick={onClose}
            data-testid="close-button"
          >
            <XIcon strokeWidth={"3"} width={"28"} />
          </IconButton>
        </Stack>
      </Stack>
      {additionalInfo && (
        <Alert
          severity="info"
          className={styles["info"]}
          data-testid="info-alert"
        >
          <Typography>
            {additionalInfo.message}
            {additionalInfo.documentation?.map((d) => (
              <SafeLink
                href={d.url}
                rel="noreferrer"
                target="_blank"
                key={d.url}
              >
                <br></br>
                {d.text}
              </SafeLink>
            ))}
          </Typography>
        </Alert>
      )}
    </DialogTitle>
  );
};

export const TitleSection = memo(TitleSectionComponent);
