import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import { memo, useState } from "react";
import { DateTimeInput } from ".";
import { ParameterDefinition } from "../../../graphql/generated";
import { PlusCircleIcon, TrashIcon } from "../../Icons";
import { useValidationContext } from "../ValidationContext";
import { ParamInputProps } from "./ParameterInput";

export type SearchesValue = Searches[];

export interface Searches {
  query: string;
  earliestTime: string;
  latestTime: string;
  eventBatchSize: number;
}

const EMPTY_ITEM: Searches = {
  query: "",
  earliestTime: "",
  latestTime: "",
  eventBatchSize: 100,
};

const EMPTY_VALUE: SearchesValue = [EMPTY_ITEM];

export const SplunkSearchesInput: React.FC<ParamInputProps<SearchesValue>> = ({
  definition,
  value: paramValue,
  readOnly,
  onValueChange,
}) => {
  const initValue =
    paramValue != null && paramValue.length > 0 ? paramValue : EMPTY_VALUE;
  const [controlValue, setControlValue] = useState<SearchesValue>(initValue);
  const { errors } = useValidationContext();

  function handleValueChange(newValue: SearchesValue) {
    if (isEqual(newValue, EMPTY_VALUE)) {
      onValueChange && onValueChange([]);
    } else {
      onValueChange && onValueChange(newValue);
    }
  }
  function handleAddField() {
    const newValue = [...controlValue, EMPTY_ITEM];
    setControlValue(newValue);
    handleValueChange(newValue);
  }

  function handleRemoveField(index: number) {
    const newValue = controlValue.filter((_, i) => i !== index);
    if (newValue.length === 0) {
      newValue.push(EMPTY_ITEM);
    }
    setControlValue(newValue);
    handleValueChange(newValue);
  }

  function handleItemChange(index: number, item: Searches) {
    const newValue = controlValue.map((v, i) => (i === index ? item : v));
    setControlValue(newValue);
    handleValueChange(newValue);
  }

  return (
    <>
      <Typography>{definition.label}</Typography>
      <Typography fontSize="0.75rem" width="42rem">
        {definition.description}
      </Typography>
      {errors[definition.name] && (
        <FormHelperText error>
          {errors[definition.name] as string}
        </FormHelperText>
      )}
      <Stack direction={"row"}>
        <Stack direction={"column"} flex={1}>
          <Box marginTop="8px">
            {controlValue.map((item, index) => (
              <Stack key={`searches-item-container-${index}`}>
                <Box marginTop="16px" />
                <SearchItem
                  definition={definition}
                  item={item}
                  index={index}
                  onRemoveField={handleRemoveField}
                  onItemChange={handleItemChange}
                  handleAddField={handleAddField}
                  controlValue={controlValue}
                  errored={errors[definition.name] != null}
                  readOnly={readOnly || false}
                />
              </Stack>
            ))}
          </Box>
          {!readOnly && (
            <Box marginLeft={1} marginTop={1}>
              <Button startIcon={<PlusCircleIcon />} onClick={handleAddField}>
                New Row
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};

type SearchItemProps = {
  definition: ParameterDefinition;
  item: Searches;
  index: number;
  onRemoveField: (index: number) => void;
  onItemChange(index: number, item: Searches): void;
  handleAddField: () => void;
  controlValue: SearchesValue;
  errored: boolean;
  readOnly: boolean;
};

const SearchItem: React.FC<SearchItemProps> = memo(
  ({
    definition,
    item,
    index,
    onRemoveField,
    onItemChange,
    handleAddField,
    controlValue,
    errored,
    readOnly,
  }) => {
    return (
      <Stack direction="row" spacing={1}>
        <Stack direction="column" spacing={2} flexGrow={1}>
          <Stack direction="row" spacing={2} flexGrow={1}>
            <Box flexGrow={1} minWidth="200px">
              <FormControl fullWidth disabled={readOnly}>
                <InputLabel id={`search-query-label-${index}`}>
                  Query
                </InputLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Query"
                  value={item.query}
                  error={
                    errored &&
                    (item.query === "" ||
                      !item.query.startsWith("search") ||
                      item.query.includes("|") ||
                      item.query.includes("earliest=") ||
                      item.query.includes("latest=") ||
                      item.query.includes("endtime=") ||
                      item.query.includes("starttime=") ||
                      item.query.includes("timeformat="))
                  }
                  onChange={(e) =>
                    onItemChange(index, {
                      ...item,
                      query: e.target.value,
                    })
                  }
                  fullWidth
                  required
                  disabled={readOnly}
                />
              </FormControl>
            </Box>
            <Box minWidth={"100px"}>
              <FormControl fullWidth disabled={readOnly}>
                <InputLabel id={`search-event-batch-size-label-${index}`}>
                  Event Batch Size
                </InputLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Event Batch Size"
                  value={item.eventBatchSize}
                  error={errored && item.eventBatchSize <= 0}
                  slotProps={{
                    htmlInput: {
                      inputMode: "numeric",
                    },
                    formHelperText: {
                      sx: { marginLeft: "-2px" },
                    },
                  }}
                  onChange={(e) =>
                    onItemChange(index, {
                      ...item,
                      eventBatchSize: Number(e.target.value),
                    })
                  }
                  fullWidth
                  disabled={readOnly}
                />
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} flexGrow={1}>
            <Box flexGrow={1} minWidth={"200px"}>
              <FormControl fullWidth disabled={readOnly}>
                <DateTimeInput
                  definition={{
                    ...definition,
                    label: "Earliest Time",
                    description: "",
                    required: true,
                  }}
                  value={item.earliestTime}
                  onValueChange={(e) => {
                    onItemChange(index, {
                      ...item,
                      earliestTime: e,
                    });
                  }}
                  readOnly={readOnly}
                />
              </FormControl>
            </Box>
            <Box flexGrow={1} minWidth={"200px"}>
              <FormControl fullWidth disabled={readOnly}>
                <DateTimeInput
                  definition={{
                    ...definition,
                    label: "Latest Time",
                    description: "",
                    required: true,
                  }}
                  value={item.latestTime}
                  onValueChange={(e) =>
                    onItemChange(index, {
                      ...item,
                      latestTime: e,
                    })
                  }
                  readOnly={readOnly}
                />
              </FormControl>
            </Box>
          </Stack>
          <br />
        </Stack>
        <Stack width="28px" justifyContent="center">
          {!readOnly && (
            <IconButton
              size="small"
              onClick={() => onRemoveField(index)}
              sx={{
                alignItems: "center",
                width: "28px",
                height: "28px",
              }}
            >
              <TrashIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  },
);
