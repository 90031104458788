import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Kind, useGetResourceTypeQuery } from "../../graphql/generated";
import { Stability } from "../../types/resources";
import { metadataSatisfiesSubstring } from "../../utils/metadata-satisfies-substring";
import { ActionsSection } from "../DialogComponents";
import { ProcessorIcon } from "../Icons";
import { ReusableProcessors } from "../ProcessorsDialog/withCommonProcessorDialog";
import { DialogResource } from "../ResourceDialog";
import { ResourceTypeButton } from "../ResourceTypeButton";
import { ResourceTypeButtonContainer } from "../ResourceTypeButton";
import { ResourceType } from "./ResourceConfigurationEditor";
import { ViewHeading } from "./ViewHeading";
import styles from "./select-view.module.scss";

interface ChooseViewProps {
  reusableResources: ReusableProcessors; // or extensions
  handleSaveExisting: (r: DialogResource) => void;
  selected: ResourceType;
  resourceKind: Kind.Processor | Kind.Extension;
  onBack: () => void;
  onCreate: () => void;
}

export const ChooseView: React.FC<ChooseViewProps> = ({
  reusableResources,
  selected,
  resourceKind,
  handleSaveExisting,
  onBack,
  onCreate,
}) => {
  const [search, setSearch] = useState("");
  const [icon, setIcon] = useState<string>();

  let heading = "";
  if (selected.metadata.name === "processor_bundle") {
    heading = "Add Processor Bundle";
  } else {
    heading =
      selected?.metadata?.displayName && resourceKind
        ? `Add ${resourceKind}: ${selected.metadata.displayName}`
        : selected?.metadata?.name;
  }

  useGetResourceTypeQuery({
    variables: {
      kind:
        resourceKind === Kind.Processor
          ? Kind.ProcessorType
          : Kind.ExtensionType,
      name: selected.metadata.name,
    },
    onCompleted(data) {
      setIcon(data.resourceType?.metadata?.icon!);
    },
    onError: (err) => {
      console.error(err);
    },
  });
  const stackRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (stackRef.current) {
      stackRef.current.focus();
    }
  }, []);

  return (
    <Stack
      spacing={2}
      height={"100%"}
      ref={stackRef}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          event.stopPropagation();
          onBack();
        }
      }}
    >
      <ViewHeading
        heading={heading}
        subHeading={selected?.metadata?.description || ``}
        stability={selected?.metadata?.stability ?? Stability.UNKNOWN}
        resourceDocLink={selected?.metadata?.resourceDocLink || ``}
      />

      <Stack spacing={1} alignItems={"center"} overflow={"auto"} flexGrow={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={onCreate}
          data-testid="add-resource-page-create-new-instead-of-reuse-button"
        >
          Create New
        </Button>
        <Typography>or</Typography>
        <Typography>Reuse existing {resourceKind}</Typography>
        <ResourceTypeButtonContainer
          onSearchChange={(v: string) => setSearch(v)}
          placeholder={
            resourceKind === Kind.Processor
              ? "Search for a processor..."
              : resourceKind === Kind.Extension
                ? "Search for an extension..."
                : ""
          }
        >
          {reusableResources
            .filter((r) => {
              return metadataSatisfiesSubstring(r, search);
            })
            .map((resource) => {
              return (
                <ResourceTypeButton
                  key={resource?.metadata?.name}
                  displayName={resource?.metadata?.name}
                  iconOverrideSVG={
                    resourceKind === Kind.Processor ? (
                      <ProcessorIcon className={styles.overrideIcon} />
                    ) : undefined
                  }
                  icon={icon}
                  onSelect={() => handleSaveExisting(resource)}
                  telemetryTypes={selected?.spec?.telemetryTypes}
                />
              );
            })}
        </ResourceTypeButtonContainer>
      </Stack>
      <ActionsSection>
        <Button variant="outlined" color="secondary" onClick={() => onBack()}>
          Back
        </Button>
      </ActionsSection>
    </Stack>
  );
};
