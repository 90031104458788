import { ApolloError, gql } from "@apollo/client";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactFlow, { useReactFlow, useStore, Node, Edge } from "reactflow";
import { BPGraph } from "../../components/ConfigurationFlowV2/graph";
import { layoutV2OverviewGraph } from "../../components/ConfigurationFlowV2/layout";
import { DEFAULT_TELEMETRY_TYPE } from "../../components/MeasurementControlBar/MeasurementControlBar";
import { firstActiveTelemetry } from "../../components/PipelineGraph/Nodes/nodeUtils";
import { BPGraphProvider } from "../../components/PipelineGraphV2/BPGraphProvider";
import { GraphMetricsProvider } from "../../components/PipelineGraphV2/GraphMetricsProvider";
import {
  PipelineType,
  Role,
  useGetOverviewPageQuery,
} from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import { Page } from "../../utils/graph/utils";
import { hasPermission } from "../../utils/has-permission";
import OverviewEdgeV2 from "./OverviewEdgeV2";
import { useOverviewPage } from "./OverviewPageContext";
import {
  OverviewDestinationNode,
  ConfigurationNode,
  GatewayNode,
} from "./nodes";

gql`
  query getOverviewPage($configIDs: [ID!], $destinationIDs: [ID!]) {
    overviewPage(configIDs: $configIDs, destinationIDs: $destinationIDs) {
      graph {
        attributes
        sources {
          id
          label
          type
          attributes
        }

        intermediates {
          id
          label
          type
          attributes
        }

        targets {
          id
          label
          type
          attributes
        }

        edges {
          id
          source
          sourceHandle
          target
          attributes
        }
      }
    }
  }
`;

const nodeTypes = {
  destinationNode: OverviewDestinationNode,
  configurationNode: ConfigurationNode,
  gatewayNode: GatewayNode,
};

const edgeTypes = {
  configurationEdge: OverviewEdgeV2,
};

export const OverviewGraph: React.FC = () => {
  const [nodes, setNodes] = useState<Node[]>();
  const [edges, setEdges] = useState<Edge[]>();
  const [hasPipeline, setHasPipeline] = useState<boolean>(true);
  const {
    selectedTelemetry,
    setSelectedTelemetry,
    selectedPeriod,
    selectedConfigs,
    selectedDestinations,
    setGraph,
    graph,
  } = useOverviewPage();
  const { enqueueSnackbar } = useSnackbar();
  const reactFlowInstance = useReactFlow();
  const navigate = useNavigate();

  // map the selectedDestinations to an array of strings
  const destinationIDs = selectedDestinations?.map((id) => id.toString());

  // map the selectedConfigs to an array of strings
  const configIDs = selectedConfigs?.map((id) => id.toString());

  function onError(error: ApolloError) {
    console.error(error.message);
    enqueueSnackbar("Oops! Something went wrong.", {
      variant: "error",
      key: error.message,
    });
  }

  const { loading } = useGetOverviewPageQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      configIDs: configIDs,
      destinationIDs: destinationIDs,
    },
    async onCompleted(data) {
      const bpGraph = new BPGraph(data.overviewPage.graph);
      setGraph(bpGraph);
      let { nodes: gotNodes, edges: gotEdges } = await layoutV2OverviewGraph(
        bpGraph,
        (selectedTelemetry as PipelineType) ||
          (DEFAULT_TELEMETRY_TYPE as PipelineType),
      );
      setNodes(gotNodes);
      setEdges(gotEdges);

      const determineHasPipeline =
        data.overviewPage.graph.sources.length > 0 &&
        data.overviewPage.graph.targets.length > 0;

      setHasPipeline(determineHasPipeline);

      if (
        selectedTelemetry == null &&
        data.overviewPage.graph.attributes != null
      ) {
        const activeTelemetry = firstActiveTelemetry(
          data.overviewPage.graph.attributes,
        );
        if (activeTelemetry) {
          setSelectedTelemetry(activeTelemetry);
        }
      }
    },
    onError,
  });

  useEffect(() => {
    const getLayoutGraph = async () => {
      if (graph != null) {
        let { nodes: gotNodes, edges: gotEdges } = await layoutV2OverviewGraph(
          graph,
          (selectedTelemetry as PipelineType) ||
            (DEFAULT_TELEMETRY_TYPE as PipelineType),
        );
        setNodes(gotNodes);
        setEdges(gotEdges);
      }
    };
    getLayoutGraph();
  }, [graph, selectedPeriod, selectedTelemetry, setGraph]);

  const reactFlowWidth = useStore((state: { width: any }) => state.width);
  const reactFlowHeight = useStore((state: { height: any }) => state.height);
  const reactFlowNodeCount = useStore(
    (state: { nodeInternals: any }) =>
      Array.from(state.nodeInternals.values()).length || 0,
  );
  useEffect(() => {
    reactFlowInstance.fitView();
  }, [reactFlowWidth, reactFlowHeight, reactFlowNodeCount, reactFlowInstance]);

  if (loading || nodes == null) {
    return <LoadingIndicator />;
  }

  function onNodesChange() {
    reactFlowInstance.fitView();
  }

  return hasPipeline ? (
    <div style={{ height: "100%", width: "100%" }}>
      <BPGraphProvider
        graph={graph}
        pipelineType={selectedTelemetry as PipelineType}
      >
        <GraphMetricsProvider
          page={Page.Overview}
          telemetryType={selectedTelemetry}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodesConnectable={false}
            nodesDraggable={false}
            proOptions={{ account: "paid-pro", hideAttribution: true }}
            fitView={true}
            deleteKeyCode={null}
            panOnDrag={false}
            zoomOnScroll={false}
            zoomOnDoubleClick={false}
            zoomOnPinch={false}
            onWheel={(event) => {
              window.scrollBy(event.deltaX, event.deltaY);
            }}
            onNodesChange={onNodesChange}
          >
            {/* Render nodes above edges */}
            <style>
              {`
                .react-flow__edges {
                  z-index: 0;
                }
                .react-flow__nodes {
                  z-index: 1;
                }
              `}
            </style>
          </ReactFlow>
        </GraphMetricsProvider>
      </BPGraphProvider>
    </div>
  ) : (
    <NoDeployedConfigurationsMessage navigate={navigate} />
  );
};

const NoDeployedConfigurationsMessage: React.FC<{
  navigate: (to: string) => void;
}> = ({ navigate }) => {
  const role = useRole();

  return (
    <Stack
      width="100%"
      height="calc(100vh - 200px)"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      padding={4}
    >
      <Typography variant="h4" textAlign={"center"}>
        You haven&apos;t deployed any configurations.
      </Typography>
      <Typography textAlign={"center"}>
        Once you&apos;ve created a configuration and rolled it out to an agent,
        you&apos;ll see your data topology here.
      </Typography>
      <Button
        disabled={role === Role.Viewer}
        variant="contained"
        onClick={
          !hasPermission(Role.User, role)
            ? undefined
            : () => navigate("/configurations/new")
        }
      >
        Create Configuration Now
      </Button>
    </Stack>
  );
};

const LoadingIndicator: React.FC = () => {
  return (
    <Stack
      width="100%"
      height="calc(100vh - 200px)"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Stack>
  );
};
