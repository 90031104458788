import { Fade, IconButton } from "@mui/material";
import { memo, useEffect, useMemo, useRef } from "react";
import { EdgeLabelRenderer, EdgeProps, getBezierPath } from "reactflow";
import { hasPermission, useRole } from "../../../contexts/RBAC";
import { EdgeMetricV2, Role } from "../../../graphql/generated";
import colors from "../../../styles/colors";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { classes } from "../../../utils/styles";
import {
  edgeAnimationDuration,
  edgeStrokeOffsetSpan,
  skipFrames,
} from "../../GraphComponents/CustomEdge";
import { PencilIcon } from "../../Icons";
import { useProcessorsDialog } from "../../ResourceDialog/ProcessorDialogContext";
import { useBPGraph } from "../BPGraphProvider";
import { useGraphMetrics } from "../GraphMetricsProvider";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { useRouting } from "../RoutingContext/RoutingContext";
import { AttributeName, V2EdgeData } from "../types";
import styles from "./configuration-edge-v2.module.scss";

const ConfigurationEdgeV2: React.FC<EdgeProps<V2EdgeData>> = ({
  id,
  data,
  source,
  sourceX,
  sourceY,
  sourcePosition,
  target,
  targetX,
  targetY,
  targetPosition,

  ...rest
}) => {
  if (!data) throw new Error("missing data for edge");
  const { attributes } = data;
  const { readOnlyGraph } = useV2PipelineGraph();
  const { hoveredSet, hoveredEdge, onMouseEnterEdge, onMouseExitEdge } =
    useBPGraph();
  const { editProcessorsOpen } = useProcessorsDialog();
  const hiddenRef = useRef(false);
  const pathRef = useRef<SVGPathElement>(null);
  const active = hasPipelineTypeFlag(
    attributes[AttributeName.PipelineType],
    attributes[AttributeName.ActiveTypeFlags],
  );
  const graphMetrics = useGraphMetrics();
  const role = useRole();

  const dimmed = hoveredSet.length > 0 && !hoveredSet.includes(id);
  const metricID = data?.attributes.metricID ?? "";

  // Keep track of the hidden ref
  useEffect(() => {
    hiddenRef.current = editProcessorsOpen;
  }, [editProcessorsOpen]);

  // Animation effect
  useEffect(() => {
    let frameCount = 0; // Counter for frame skipping

    // Fun fact:
    //    function animate(time: number) {
    // seems to cause a 10-20% CPU usage increase compared to:
    const animate = (time: number) => {
      frameCount++;

      if (frameCount > skipFrames) {
        if (!pathRef.current) return;

        const currentTime = time % edgeAnimationDuration;
        const progress = currentTime / edgeAnimationDuration;
        const currentOffset = progress * edgeStrokeOffsetSpan;
        pathRef.current.style.strokeDashoffset = `${currentOffset}`;

        frameCount = 0; // Reset the frame counter
      }

      if (active && !hiddenRef.current) {
        requestAnimationFrame(animate);
      }
    };

    if (active && !hiddenRef.current) {
      requestAnimationFrame(animate);
    }
  }, [active, editProcessorsOpen]);

  const [path, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const pathClasses = useMemo(() => {
    return makePathClass({
      active,
      dimmed,
      metric: graphMetrics?.data().metric(metricID, attributes.pipelineType),
      maxValue: graphMetrics?.data().maxValue(attributes.pipelineType) ?? 0,
      hovered: hoveredEdge === id,
    });
  }, [active, dimmed, graphMetrics, metricID, attributes, hoveredEdge, id]);

  const showEdgeOptions =
    canInsertComponent(id) && !readOnlyGraph && hasPermission(Role.User, role);

  return (
    <>
      {showEdgeOptions && (
        <EdgeMenuButton
          isHovered={hoveredEdge === id}
          source={source}
          target={target}
          {...{ labelX, labelY }}
          onMouseEnter={() => onMouseEnterEdge(id)}
          onMouseLeave={onMouseExitEdge}
          routeId={attributes[AttributeName.RouteID]}
        />
      )}

      {/** An invisible line to increase the hover distance */}
      <path
        style={{ opacity: 0, strokeWidth: 25 }}
        d={path}
        onMouseEnter={() => {
          onMouseEnterEdge(id);
        }}
        onMouseLeave={onMouseExitEdge}
      />

      {/** The actual Edge */}
      <path
        onMouseEnter={() => {
          onMouseEnterEdge(id);
        }}
        onMouseLeave={onMouseExitEdge}
        ref={pathRef}
        id={id}
        d={path}
        className={pathClasses}
      />
    </>
  );
};

type makePathClassArg = {
  active: boolean;
  dimmed: boolean;
  metric?: EdgeMetricV2;
  maxValue: number;
  hovered: boolean;
};

function makePathClass({
  active,
  dimmed,
  metric,
  maxValue,
  hovered,
}: makePathClassArg) {
  const classNames = [styles.noFill];

  active
    ? classNames.push(styles.activeRoute)
    : classNames.push(styles.inactiveRoute);

  active && classNames.push(getWeightedClassName(maxValue, metric));
  dimmed && classNames.push(styles.dimmed);
  hovered && classNames.push(styles.hovered);
  return classes(classNames);
}

export const getWeightedClassName = (
  maxValue: number,
  metric?: EdgeMetricV2,
) => {
  if (metric == null) {
    return styles.inactive;
  }

  return getWidthClass(metric.value, maxValue);
};

function getWidthClass(rawValue: number, maxValue: number) {
  const ratio = rawValue / maxValue;
  if (ratio >= 1) {
    return styles.w5;
  }
  const scaled = Math.floor(ratio * 5 + 1);
  const widthStyle = `w${scaled}`;
  return styles[widthStyle];
}

interface EdgeMenuButtonProps {
  source: string;
  target: string;
  isHovered: boolean;
  labelX: number;
  labelY: number;
  routeId?: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const EdgeMenuButton: React.FC<EdgeMenuButtonProps> = ({
  source,
  target,
  labelX,
  labelY,
  isHovered,
  routeId,
  onMouseEnter,
  onMouseLeave,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const { onEdgeButtonClick } = useRouting();

  return (
    <EdgeLabelRenderer>
      <div
        className={"nopan"}
        style={{
          transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          pointerEvents: isHovered ? "all" : "none",
          position: "absolute",
          backgroundColor: colors.white,
          borderRadius: "50%",
          zIndex: 1,
          opacity: isHovered ? 1 : 0,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div ref={anchorRef} />
        <Fade in={isHovered}>
          <IconButton
            color="primary"
            onClick={() => {
              anchorRef.current &&
                onEdgeButtonClick(anchorRef.current, source, target, routeId);
            }}
          >
            <PencilIcon width="20px" />
          </IconButton>
        </Fade>
      </div>
    </EdgeLabelRenderer>
  );
};

function canInsertComponent(edgeId: string): boolean {
  const [sourceId, targetId] = edgeId.split("|");
  if (!sourceId || !targetId) {
    return false;
  }

  // Do not allow insertion between a source and its processor
  if (sourceId.startsWith("source/") && targetId.startsWith("source")) {
    return false;
  }
  // DO not allow insertion between a destination and its processor
  if (edgeId.startsWith("destination/") && edgeId.startsWith("destination/")) {
    return false;
  }
  return true;
}

export default memo(ConfigurationEdgeV2);
