import { gql, useApolloClient } from "@apollo/client";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { withEENavBar } from "../../../components/EENavBar";
import { withRBAC } from "../../../contexts/RBAC";
import { withRequireLogin } from "../../../contexts/RequireLogin";
import {
  GetAccountPageInfoDocument,
  GetAccountPageInfoQuery,
  useGetAccountPageInfoQuery,
  useUsersSubscription,
} from "../../../graphql/generated";
import { APIKeyTab } from "./APIKeyTab";
import { AccountTab } from "./AccountTab";
import { UsersTab } from "./UsersTab";
import styles from "./account-page.module.scss";

gql`
  query getAccountPageInfo {
    user {
      metadata {
        id
        name
        version
        displayName
      }
      spec {
        activeAccount
        email
        requestedMFA
      }
      role
    }

    users {
      metadata {
        id
        name
        version
        displayName
      }
      spec {
        activeAccount
        email
        requestedMFA
      }
      role
    }

    accounts {
      metadata {
        id
        name
        version
        displayName
      }
    }
  }

  mutation removeUser($input: RemoveUserInput!) {
    removeUser(input: $input) {
      metadata {
        name
        id
        version
        displayName
      }
      spec {
        activeAccount
        email
      }
      role
    }
  }

  subscription Users {
    users {
      metadata {
        name
        id
        version
        displayName
      }
      spec {
        email
        activeAccount
      }
      role
    }
  }
`;

export const AccountPageComponent: React.FC = () => {
  const [tab, setTab] = useState<"account" | "users" | "api-key">("account");

  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const { data, error, refetch } = useGetAccountPageInfoQuery({
    fetchPolicy: "network-only",
  });

  useUsersSubscription({
    onData: ({ data }) => {
      client.cache.updateQuery<GetAccountPageInfoQuery>(
        {
          query: GetAccountPageInfoDocument,
        },
        (prev) => {
          if (!prev) return prev;
          if (data.data == null) return prev;
          return {
            ...prev,
            users: data.data?.users,
          };
        },
      );
    },
  });

  const currentAccount = useMemo(() => {
    if (data == null) {
      return undefined;
    }

    return data.accounts.find(
      (act) => act.metadata.id === data.user?.spec.activeAccount,
    );
  }, [data]);

  useEffect(() => {
    if (error != null) {
      console.error("Failed to retrieve project data", {
        error,
      });
      enqueueSnackbar("Failed to retrieve project data.", {
        variant: "error",
        key: "project-error",
      });
      return;
    }
  }, [enqueueSnackbar, error]);

  if (currentAccount == null) {
    return null;
  }

  return (
    <div className={styles.container}>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          classes={{ root: styles.tabs }}
        >
          <Tab value={"account"} label="Project" />
          <Tab value={"users"} label="Users" />
          <Tab value={"api-key"} label="API Key" />
        </Tabs>

        <TabPanel value={"account"}>
          <AccountTab account={currentAccount} refetch={refetch} />
        </TabPanel>

        <TabPanel value={"users"}>
          <UsersTab data={data} currentAccount={currentAccount} />
        </TabPanel>

        <TabPanel value={"api-key"}>
          <APIKeyTab />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export const AccountPage = withRequireLogin(
  withRBAC(withEENavBar(AccountPageComponent)),
);
