import yaml from "js-yaml";
import { isEmpty } from "lodash";
import {
  ParameterDefinition,
  ResourceConfiguration,
} from "../../graphql/generated";
import { validateNameField } from "../../utils/forms/validate-name-field";
import {
  Condition,
  ConditionInputValue,
} from "./ParameterInput/ConditionInput";
import { ExtractMetricsValue } from "./ParameterInput/ExtractMetricsInput";
import { FieldsValue } from "./ParameterInput/FieldsInput";
import { GoogleSecOpsStandardizationValue } from "./ParameterInput/GoogleSecOpsStandardizationInput";
import type { RecombineConditionsValue } from "./ParameterInput/RecombineConditionsInput";
import { RolloutOptionsStage } from "./ParameterInput/RolloutStagesInput";
import { getRouteFieldName, RoutesValue } from "./ParameterInput/RoutesInput";
import { SearchesValue } from "./ParameterInput/SplunkSearchInput";

const REQUIRED_ERROR_MSG = "Required.";

export function validateStringField(
  value: string | null,
  required?: boolean,
): string | null {
  if (required && isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }
  return null;
}

export function validateStringsField(
  value: string[],
  required?: boolean,
): string | null {
  if (required && isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }

  return null;
}

export function validateYamlField(
  value: string | null,
  required?: boolean,
  variant?: string | null,
): string | null {
  if (required && isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }
  if (variant === "text") {
    return null;
  }
  try {
    const doc = yaml.load(value!);
    if (typeof doc !== "object") {
      return "Invalid YAML";
    }
  } catch (e: any) {
    const message = e.toString().replace("YAMLException:", "");
    return "Invalid YAML: " + message;
  }
  return null;
}

export function validateMapField(
  value: Record<string, string> | null,
  required?: boolean,
): string | null {
  if (required) {
    if (value == null) {
      return REQUIRED_ERROR_MSG;
    }

    const entries = Object.entries(value);
    if (isEmpty(entries)) {
      return REQUIRED_ERROR_MSG;
    }

    let nonEmptyKeyFound = false;
    for (const entry of entries) {
      if (!isEmpty(entry[0])) {
        nonEmptyKeyFound = true;
        break;
      }
    }

    if (!nonEmptyKeyFound) {
      return REQUIRED_ERROR_MSG;
    }
  }

  return null;
}

export function validateAWSNamedField(value: any): string | null {
  if (value?.length < 1) {
    return "At least one log group must be specified.";
  }

  for (const subField of value) {
    if (isEmpty(subField.id)) {
      return "All log group IDs must be set.";
    }
  }

  return null;
}

export function validateFileLogSortField(value: any): string | null {
  if (value?.length < 1) {
    return "At least one sort rule must be specified.";
  }

  for (const subField of value) {
    if (isEmpty(subField.regexKey)) {
      return "All regex keys must be set.";
    }
    // Check if sortType is set to "timestamp" and if so, check that layout is set
    if (subField.sortType === "timestamp" && isEmpty(subField.layout)) {
      return "Layout must be set for timestamp sort.";
    }

    if (subField.sortType !== "timestamp" && !isEmpty(subField.layout)) {
      return "Layout should only be set for timestamp sort.";
    }
  }

  return null;
}

export function validateStrIntField(
  definition: ParameterDefinition,
  value: string | number | null,
): string | null {
  if (definition.required && isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }

  if (typeof value === "string" && isNaN(Number(value))) {
    return "Must be a number.";
  }

  return null;
}

export function validateIntField(
  definition: ParameterDefinition,
  value?: number,
): string | null {
  if (definition.required && value == null) {
    return REQUIRED_ERROR_MSG;
  }

  return null;
}

export function validateFractionField(
  definition: ParameterDefinition,
  value?: number,
): string | null {
  if (definition.required && value == null) {
    return REQUIRED_ERROR_MSG;
  }

  if (value == null) {
    return null;
  }

  if (isNaN(value)) {
    return "Must be a number.";
  }

  if (value < 0 || 1 < value) {
    return "Must be between 0 and 1.";
  }

  return null;
}

export function validateConditionField(
  definition: ParameterDefinition,
  value?: string | ConditionInputValue,
): string | null {
  if (definition.required && new Condition(value).isEmpty()) {
    return REQUIRED_ERROR_MSG;
  }

  return null;
}

export function validateFieldsField(
  definition: ParameterDefinition,
  value: FieldsValue,
): string | null {
  for (const fields of value) {
    if (isEmpty(fields.key) || isEmpty(fields.value)) {
      return "Field and value are required.";
    }
  }
  return null;
}
export function validateGoogleSecOpsStandardizationField(
  definition: ParameterDefinition,
  value: GoogleSecOpsStandardizationValue,
): string | null {
  for (const secOpsFields of value) {
    if (isEmpty(secOpsFields.logType)) {
      return "Log type is required.";
    }
  }
  return null;
}

export function validateProcessorBundleField(
  definition: ParameterDefinition,
  value: ResourceConfiguration[],
): string | null {
  if (value.length === 0) {
    return "At least one processor is required.";
  }
  return null;
}

export function validateDateTimeField(
  definition: ParameterDefinition,
  value: string,
): string | null {
  if (definition.required && isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }
  return null;
}

export function validateRolloutStages(
  definition: ParameterDefinition,
  value: RolloutOptionsStage[],
): string | null {
  if (value == null) {
    return REQUIRED_ERROR_MSG;
  }

  const missingName = value.some((stage: RolloutOptionsStage) => {
    return stage.name === "";
  });

  const missingLabel = value.some((stage: RolloutOptionsStage) => {
    return isEmpty(stage.labels);
  });

  if (missingName || missingLabel) {
    return "Every stage must have a name and at least one label";
  } else {
    return null;
  }
}

export function validateRecombineConditions(
  definition: ParameterDefinition,
  value: RecombineConditionsValue,
): string | null {
  if (definition.required && isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }

  for (const condition of value) {
    if (isEmpty(condition.selector)) {
      return "Selector is required.";
    }
    if (isEmpty(condition.selectorMatchExpression)) {
      return "Selector match expression is required.";
    }

    if (isEmpty(condition.recombineMatchExpression)) {
      return "Recombine match expression is required.";
    }
  }
  return null;
}

export function validateTelemetrySelector(
  definition: ParameterDefinition,
  value: string,
): string | null {
  if (isEmpty(value)) {
    return "At least one telemetry type must be enabled";
  }

  return null;
}

export function validateOTTLField(
  value: string | null,
  required?: boolean,
): string | null {
  if (required && isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }
  return null;
}

export function validateOTTLFields(
  value: string[] | null,
  required?: boolean,
): string | null {
  if (required && isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }
  return null;
}

export function validateExtractMetricsField(
  value: ExtractMetricsValue | null,
): Record<string, null | string> | string {
  if (isEmpty(value)) {
    return REQUIRED_ERROR_MSG;
  }
  if (!value) {
    return REQUIRED_ERROR_MSG;
  }

  const errors: Record<string, null | string> = {};
  for (let i = 0; i < value.length; i++) {
    if (isEmpty(value[i].metricName)) {
      errors["extract-metric-" + i + "-metric-name"] = REQUIRED_ERROR_MSG;
    }
    if (isEmpty(value[i].metricField)) {
      errors["extract-metric-" + i + "-metric-field"] = REQUIRED_ERROR_MSG;
    }
  }
  return errors;
}

export function validateRoutesField(
  definition: ParameterDefinition,
  value: RoutesValue | null,
  setError: (name: string, error: string | null) => void,
) {
  // top-level validation
  if (definition.required && (isEmpty(value) || value == null)) {
    setError(definition.name, REQUIRED_ERROR_MSG);
  }
  if (value == null) {
    return null;
  }

  // individual route validation
  const errors: Record<string, null | string> = {};

  for (let i = 0; i < value.length; i++) {
    const idName = getRouteFieldName(definition, i, "id");
    const conditionName = getRouteFieldName(definition, i, "condition");

    validateRoutesId(value, idName, value[i].id, setError);
    validateRoutesCondition(value, conditionName, value[i].condition, setError);
  }

  return errors;
}

function validateRoutesId(
  routes: RoutesValue,
  name: string,
  id: string,
  setError: (name: string, error: string | null) => void,
) {
  if (isEmpty(id)) {
    setError(name, REQUIRED_ERROR_MSG);
    return;
  }

  // make sure it's not a duplicate
  const duplicate = routes.filter((r) => r.id === id).length > 1;
  if (duplicate) {
    setError(name, "Route IDs must be unique within this routing connector.");
    return;
  }

  // make sure it's a valid name
  const error = validateNameField(id);
  if (error) {
    setError(name, error);
    return;
  }

  setError(name, null);
}

function validateRoutesCondition(
  routes: RoutesValue,

  name: string,
  condition: string | ConditionInputValue,
  setError: (name: string, error: string | null) => void,
) {
  if (new Condition(condition).isEmpty()) {
    setError(name, REQUIRED_ERROR_MSG);
  }
  setError(name, null);
}

export function validateSplunkSearchesField(
  definition: ParameterDefinition,
  value: SearchesValue,
): string | null {
  for (const search of value) {
    if (isEmpty(search.query)) {
      return "Query is required.";
    }
    if (!search.query.startsWith("search") || search.query.includes("|")) {
      return "Query must start with an explicit 'search' command and contain no other chained commands";
    }
    if (
      search.query.includes("earliest=") ||
      search.query.includes("latest=") ||
      search.query.includes("endtime=") ||
      search.query.includes("starttime=") ||
      search.query.includes("timeformat=")
    ) {
      return "Query must not contain any time fields";
    }
    if (isEmpty(search.earliestTime)) {
      return "Earliest Time is required.";
    }
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(search.earliestTime) === false) {
      return "Earliest Time must be a valid UTC date time string.";
    }
    if (isEmpty(search.latestTime)) {
      return "Latest Time is required.";
    }
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(search.latestTime) === false) {
      return "Latest Time must be a valid UTC date time string.";
    }
    if (isNaN(search.eventBatchSize)) {
      return "EventBatchSize must be a number.";
    }
    if (search.eventBatchSize <= 0) {
      return "EventBatchSize must be greater than 0.";
    }
  }
  return null;
}
