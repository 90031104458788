import { Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, useState } from "react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetSourceTypeDisplayInfoQuery } from "../../graphql/generated";
import { formatAgentCount } from "./ConfigurationCard";
import { ResourceCard } from "./ResourceCard";
import styles from "./cards.module.scss";

interface OverviewGatewayCardProps {
  id: string;
  label: string;
  // disabled indicates that the card is not active and should be greyed out
  disabled?: boolean;
  configuration: string;
  agentCount?: number;
}

const OverviewGatewayCardComponent: React.FC<OverviewGatewayCardProps> = ({
  disabled,
  configuration,
  agentCount,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [configurationName, setConfigurationName] = useState<string>("");

  const { data } = useGetSourceTypeDisplayInfoQuery({
    variables: { name: "bindplane_gateway" },
    fetchPolicy: "cache-and-network",
  });

  // Loading
  if (data === undefined) {
    return null;
  }
  if (data.sourceType == null) {
    enqueueSnackbar(`Could not retrieve gateway sourceType.`, {
      variant: "error",
    });
    return null;
  }

  const configurationURL = `/configurations/${configuration}`;
  agentCount = agentCount ?? 0;

  return (
    <div>
      <ResourceCard
        onMouseEnter={() => setConfigurationName(configuration)}
        onMouseLeave={() => setConfigurationName("")}
        title={configurationName}
        icon={data?.sourceType?.metadata.icon}
        disabled={disabled}
        onClick={() =>
          navigate({ pathname: configurationURL, search: location.search })
        }
        insideLabel="Gateway"
      />
      <Chip
        classes={{
          root: styles["overview-count-chip"],
          label: styles["overview-count-chip-label"],
        }}
        size="small"
        label={formatAgentCount(agentCount)}
      />
    </div>
  );
};

export const OverviewGatewayCard = memo(OverviewGatewayCardComponent);
