import { LicenseInfo } from "@mui/x-license-pro";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { App, getAuth0Domain } from "./app";
import { version } from "./components/BindplaneVersion/utils";
import { getSaasEnabled } from "./utils/get-saas-enabled";
import "./styles/globals.scss";

/*
Here we are setting the MUI Premium license key.  We expect it
to be bundled with the served JS, but thats alright.  From MUI:

   The license key is checked without making any network
   requests—it's designed to be public. It's expected that
   the license key will be exposed in a JavaScript bundle;
   we simply ask licensed users not to actively publicize
   their license key.

This works around having to set the license key at build time
and for development.
*/
LicenseInfo.setLicenseKey(
  "9e2e66d2d6e08fd9b74a404114a22a7dTz0xMDA4MTEsRT0xNzYxNzQ3OTU1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
);

// Only initialize Sentry if running in SaaS, and therefore production
if (getSaasEnabled() && getAuth0Domain() === "auth0.saas.dev.bindplane.com") {
  Sentry.init({
    // TODO: replace with prod dsn before enabling in SaaS
    dsn: "https://b5f272217a1ffb1b866200e40535ca25@o4506356416708608.ingest.us.sentry.io/4506356427980800",
    // TODO: hardcode to production before enabling in SaaS
    environment: "stage",
    release: version(),
    integrations: [
      Sentry.captureConsoleIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
