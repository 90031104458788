import { gql } from "@apollo/client";
import {
  Box,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Grid2 as Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  useDestinationsQuery,
  useGetSummaryGraphQuery,
} from "../../graphql/generated";
import {
  ALL_DESTINATIONS_VALUE,
  SummaryInterval,
  SummaryPeriod,
  useSummaryPageContext,
} from "../../pages/overview/SummaryPage/SummaryPageContext";
import colors from "../../styles/colors";
import { formatBytes } from "../../utils/graph/utils";
import { DataSummaryGraph } from "../DataSummaryGraph";
import { DestinationsIcon } from "../Icons";
import { DestinationTypeCell } from "../Tables/DestinationsTable/cells";
import dividerStyle from "../SummaryPageCard/summary-page-card.module.scss";
import styles from "./data-summary-section.module.scss";

gql`
  query GetSummaryGraph(
    $period: String!
    $interval: String!
    $telemetryType: String!
    $destinationIDs: [ID!]
  ) {
    summaryGraphData(
      period: $period
      interval: $interval
      telemetryType: $telemetryType
      destinationIDs: $destinationIDs
    ) {
      totalIngest
      totalEgress
      dataset {
        timestamp
        s0Value
        d1Value
      }
    }
  }
  subscription SummaryGraphData(
    $period: String!
    $interval: String!
    $telemetryType: String!
    $destinationIDs: [ID!]
  ) {
    summaryGraphData(
      period: $period
      interval: $interval
      telemetryType: $telemetryType
      destinationIDs: $destinationIDs
    ) {
      totalIngest
      totalEgress
      dataset {
        timestamp
        s0Value
        d1Value
      }
    }
  }
`;

const intervalsToPeriods: Record<SummaryInterval, SummaryPeriod> = {
  [SummaryInterval.OneDay]: SummaryPeriod.OneHour,
  [SummaryInterval.ThirtyDays]: SummaryPeriod.OneDay,
  [SummaryInterval.NinetyDays]: SummaryPeriod.OneDay,
};

export const DataSummarySection: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    measurementInterval,
    onMeasurementIntervalChange,
    telemetryType,
    onTelemetryTypeChange,
    period,
    onPeriodChange,
    destination,
    onDestinationChange,
  } = useSummaryPageContext();

  const [reduction, setReduction] = useState(0);
  const { data: destinationsData } = useDestinationsQuery({
    variables: {
      filterUnused: true,
      filterGateways: true,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error("Error fetching destinations", {
        error,
        destination,
      });
      enqueueSnackbar("Destinations unavailable.", { variant: "error" });
    },
  });

  const { data, loading } = useGetSummaryGraphQuery({
    variables: {
      period: period,
      interval: measurementInterval,
      telemetryType: telemetryType!,
      destinationIDs:
        destination === ALL_DESTINATIONS_VALUE ? [] : [destination!],
    },
    // Wait for destination to be set by the context before fetching.
    skip: destination == null || telemetryType == null,
    fetchPolicy: "network-only",
    onError(err) {
      enqueueSnackbar("Summary Graph unavailable.", { variant: "error" });
      console.error("Error fetching summary graph", {
        error: err,
        destination,
        period,
        measurementInterval,
        telemetryType,
      });
    },
    onCompleted(data) {
      const { totalIngest, totalEgress } = data.summaryGraphData;

      if (totalIngest === 0) {
        setReduction(0);
        return;
      }

      const rounded = Math.round((totalEgress / totalIngest) * 10000) / 100;
      setReduction(100 - rounded);
    },
  });

  return (
    <Stack>
      <Stack className={styles["header"]}>
        <CardHeader
          title="Data Summary"
          titleTypographyProps={{
            fontSize: "1rem",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        />
        <Stack className={styles["header-right"]}>
          <TextField
            value={measurementInterval}
            variant="outlined"
            onChange={(e) => {
              onMeasurementIntervalChange(e.target.value as SummaryInterval);
              onPeriodChange(
                intervalsToPeriods[e.target.value as SummaryInterval],
              );
            }}
            className={styles["upper-form-control"]}
            size="small"
            name="interval"
            select
          >
            {Object.entries({
              "24 hours": SummaryInterval.OneDay,
              "30 days": SummaryInterval.ThirtyDays,
              "90 days": SummaryInterval.NinetyDays,
            }).map(([label, value]) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <Typography color={colors.darkGray}>Interval</Typography>

          <TextField
            value={telemetryType ?? "logs"}
            onChange={(e) => onTelemetryTypeChange(e.target.value)}
            className={styles["upper-form-control"]}
            size="small"
            name="telemetry"
            select
          >
            {Object.entries({
              Logs: "logs",
              Metrics: "metrics",
              Traces: "traces",
            }).map(([label, value]) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <Typography color={colors.darkGray}>Telemetry Type</Typography>
        </Stack>
      </Stack>
      <Divider className={styles["divider"]} />
      <Stack className={styles["content"]}>
        {loading ? (
          <Stack height={400} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress size={100} />
          </Stack>
        ) : (
          <DataSummaryGraph
            period={period}
            interval={measurementInterval}
            summaryGraphData={
              data?.summaryGraphData ?? {
                dataset: [],
                totalIngest: 0,
                totalEgress: 0,
              }
            }
          />
        )}
      </Stack>
      <Divider className={dividerStyle["divider"]} />
      <Stack className={styles["content"]}>
        <Grid
          container
          spacing={2}
          style={{ width: "100%", marginTop: 10 }}
          alignItems={"center"}
        >
          <Box>
            <Box
              height={36}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography fontSize={24} whiteSpace={"nowrap"}>
                  {formatBytes(data?.summaryGraphData.totalIngest ?? 0)}
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography fontSize={14} color={colors.lightGreen}>
                •
              </Typography>
              <Typography align="right" fontSize={18} color={colors.darkGray}>
                Data in
              </Typography>
            </Box>
          </Box>

          <Divider
            orientation="vertical"
            className={styles["vertical-divider"]}
          />

          <Box>
            <Box
              height={36}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography fontSize={24} whiteSpace={"nowrap"}>
                  {formatBytes(data?.summaryGraphData.totalEgress ?? 0)}
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography
                fontSize={14}
                color={colors.lightBlue}
                paddingBottom="2px"
              >
                •
              </Typography>
              <Typography align="right" fontSize={18} color={colors.darkGray}>
                Data out
              </Typography>
            </Box>
          </Box>

          <Divider
            orientation="vertical"
            className={styles["vertical-divider"]}
          />

          <Box>
            <Box
              height={36}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography fontSize={24} align="center">
                {Math.abs(reduction).toFixed(2)}%
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography
                fontSize={14}
                color={colors.neonCarrot}
                paddingBottom="2px"
              >
                •
              </Typography>
              <Typography fontSize={18} align="center" color={colors.darkGray}>
                {reduction >= 0 ? "Reduction" : "Increase"}
              </Typography>
            </Box>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            alignItems={"center"}
            marginLeft={"auto"}
          >
            <Typography color={colors.darkGray}>Destination</Typography>
            <FormControl className={styles["form-control"]}>
              {destination != null && destinationsData !== undefined && (
                <Select
                  value={destination ?? ALL_DESTINATIONS_VALUE}
                  onChange={(e) => onDestinationChange(e.target.value)}
                  size="small"
                  name="destination"
                  className={styles["select-component"]}
                >
                  <MenuItem
                    key={ALL_DESTINATIONS_VALUE}
                    value={ALL_DESTINATIONS_VALUE}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <DestinationsIcon
                        className={styles["destination-icon"]}
                      />
                      <ListItemText primary="All destinations" />
                    </Stack>
                  </MenuItem>
                  {destinationsData.destinations.map((d) => (
                    <MenuItem key={d.metadata.name} value={d.metadata.name}>
                      <Stack direction="row" alignItems="center">
                        <ListItemIcon>
                          <DestinationTypeCell type={d.spec.type} icon />
                        </ListItemIcon>
                        <ListItemText primary={d.metadata.name} />
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
};
