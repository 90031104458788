import { EdgeMetricV2 } from "../../graphql/generated";
import { MaxValueMap } from "../GraphComponents";
import { TELEMETRY_SIZE_METRICS } from "../MeasurementControlBar/MeasurementControlBar";

interface PipelineGraphMetricsType {
  metrics: EdgeMetricV2[];
}

type metricID = string;

export class PipelineGraphMetricsData implements PipelineGraphMetricsType {
  metrics: EdgeMetricV2[];
  private maxValues: MaxValueMap;

  constructor(data: EdgeMetricV2[]) {
    this.metrics = data;
    this.maxValues = {
      maxLogValue: this.findMaxValue("logs"),
      maxMetricValue: this.findMaxValue("metrics"),
      maxTraceValue: this.findMaxValue("traces"),
    };
  }

  metric(id: metricID, pipelineType: string): EdgeMetricV2 | undefined {
    return this.metrics.find(
      (metric) =>
        metric.metricID === id && metric.name === metricName(pipelineType),
    );
  }

  maxValue(pipelineType: string) {
    switch (pipelineType) {
      case "logs":
        return this.maxValues.maxLogValue;
      case "metrics":
        return this.maxValues.maxMetricValue;
      case "traces":
        return this.maxValues.maxTraceValue;
    }
    throw new Error(`Unknown pipeline type: ${pipelineType}`);
  }

  overviewSourceNodeMetric(
    sourceId: string,
    pipelineType: string,
  ): EdgeMetricV2 | undefined {
    if (sourceId === "") {
      return undefined;
    }
    return this.metrics.find((metric) => {
      const metricIDParts = metric.metricID.split("|");
      if (metricIDParts.length !== 3) {
        return false;
      }

      return (
        metricIDParts[0] === sourceId &&
        metric.name === metricName(pipelineType)
      );
    });
  }

  overviewTargetNodeMetric(
    targetId: string,
    pipelineType: string,
  ): EdgeMetricV2 | undefined {
    if (targetId === "") {
      return undefined;
    }
    return this.metrics.find((metric) => {
      const metricIDParts = metric.metricID.split("|");
      if (metricIDParts.length !== 3) {
        return false;
      }

      return (
        metricIDParts[1] === targetId &&
        metric.name === metricName(pipelineType)
      );
    });
  }

  private findMaxValue(pipelineType: string) {
    return this.metrics
      .filter((metric) => metric.name === metricName(pipelineType))
      .reduce((acc, metric) => {
        return Math.max(acc, metric.value);
      }, 0);
  }
}

function metricName(pipelineType: string) {
  return TELEMETRY_SIZE_METRICS[pipelineType];
}
