import { memo } from "react";
import { NodeProps } from "reactflow";
import { OverviewGatewayCard } from "../../../components/Cards/OverviewGatewayCard";
import { DEFAULT_TELEMETRY_TYPE } from "../../../components/MeasurementControlBar/MeasurementControlBar";
import { isNodeDisabled } from "../../../components/PipelineGraph/Nodes/nodeUtils";
import { useBPGraph } from "../../../components/PipelineGraphV2/BPGraphProvider";
import { V2NodeData } from "../../../components/PipelineGraphV2/types";
import { useOverviewPage } from "../OverviewPageContext";

export function GatewayNode(params: NodeProps<V2NodeData>): JSX.Element {
  const { label, attributes } = params.data;
  const { selectedTelemetry } = useOverviewPage();

  // hoveredSet logic for the overview page is not currently working but will be revisited in the future
  const { hoveredSet } = useBPGraph();
  const isDisabled = isNodeDisabled(
    selectedTelemetry || DEFAULT_TELEMETRY_TYPE,
    params.data.attributes,
  );
  const isNotInHoverSet =
    hoveredSet.length > 0 && !hoveredSet.find((elem) => elem === params.id);
  return (
    <div
      style={{
        scale: "60%",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
        borderRadius: "8px",
      }}
    >
      <OverviewGatewayCard
        id={attributes.resourceId}
        agentCount={attributes.agentCount}
        label={label ?? ""}
        configuration={attributes.configuration ?? ""}
        disabled={isDisabled || isNotInHoverSet}
        key={params.id}
      />
    </div>
  );
}

export default memo(GatewayNode);
