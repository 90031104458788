import { gql } from "@apollo/client";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { createContext, useContext, useState } from "react";
import { StringParam, withDefault, useQueryParam } from "use-query-params";
import { BPGraph } from "../../components/ConfigurationFlowV2/graph";
import { MaxValueMap } from "../../components/GraphComponents";
import { DEFAULT_TELEMETRY_TYPE } from "../../components/MeasurementControlBar";
import {
  DEFAULT_OVERVIEW_GRAPH_QUERY_PERIOD,
  TELEMETRY_SIZE_METRICS,
} from "../../components/MeasurementControlBar/MeasurementControlBar";
import {
  OverviewPageMetricsSubscription,
  useOverviewPageMetricsSubscription,
} from "../../graphql/generated";
import {
  CONFIGS_PARAM_NAME,
  DESTINATIONS_PARAM_NAME,
  PERIOD_PARAM_NAME,
  TELEMETRY_TYPE_PARAM_NAME,
  useQueryParamWrapper,
} from "../../utils/state";

gql`
  subscription OverviewPageMetrics(
    $period: String!
    $configIDs: [ID!]
    $destinationIDs: [ID!]
  ) {
    overviewMetrics(
      period: $period
      configIDs: $configIDs
      destinationIDs: $destinationIDs
    ) {
      metrics {
        name
        nodeID
        value
        unit
        isActiveGateway
      }
      edgeMetrics {
        name
        edgeID
        unit
        value
      }
      maxMetricValue
      maxLogValue
      maxTraceValue
    }
  }
`;

export interface OverviewPageContextValue {
  hoveredSet: string[];
  setHoveredNodeAndEdgeSet: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTelemetry: string;
  setSelectedTelemetry: (t: string) => void;
  selectedConfigs: GridRowSelectionModel;
  setSelectedConfigs: (d: GridRowSelectionModel) => void;
  selectedDestinations: GridRowSelectionModel;
  setSelectedDestinations: (d: GridRowSelectionModel) => void;
  selectedPeriod: string;
  setPeriod: (p: string) => void;
  editingDestination: string | null;
  setEditingDestination: (dest: string | null) => void;

  maxValues: MaxValueMap;
  setMaxValues: (maxValues: MaxValueMap) => void;
  overviewMetrics?: OverviewPageMetricsSubscription["overviewMetrics"];
  onSelectTop3Configs: () => void;
  onSelectTop3Destinations: () => void;
  /**
   * Whether we're waiting for the metrics subscription
   */
  loadingMetrics: boolean;
  graph: BPGraph;
  setGraph: (g: BPGraph) => void;
}

const defaultContext: OverviewPageContextValue = {
  hoveredSet: [],
  setHoveredNodeAndEdgeSet: () => {},
  selectedTelemetry: "logs",
  setSelectedTelemetry: () => {},
  selectedConfigs: [],
  setSelectedConfigs: () => {},
  selectedDestinations: [],
  setSelectedDestinations: () => {},
  selectedPeriod: DEFAULT_OVERVIEW_GRAPH_QUERY_PERIOD,
  setPeriod: () => {},
  editingDestination: null,
  setEditingDestination: () => {},
  maxValues: {
    maxMetricValue: 0,
    maxLogValue: 0,
    maxTraceValue: 0,
  },
  setMaxValues: () => {},
  onSelectTop3Configs: () => {},
  onSelectTop3Destinations: () => {},
  loadingMetrics: false,
  graph: new BPGraph(),
  setGraph: () => {},
};

const OverviewPageContext = createContext(defaultContext);

export const OverviewPageProvider: React.FC = ({ children }) => {
  const [selectedTelemetry, setSelectedTelemetry] = useQueryParam(
    TELEMETRY_TYPE_PARAM_NAME,
    withDefault(StringParam, DEFAULT_TELEMETRY_TYPE),
  );

  const [selectedPeriod, setPeriod] = useQueryParam(
    PERIOD_PARAM_NAME,
    withDefault(StringParam, DEFAULT_OVERVIEW_GRAPH_QUERY_PERIOD),
  );

  const [graph, setGraph] = useState<BPGraph>(new BPGraph());

  const [selectedDestinations, setSelectedDestinations] = useQueryParamWrapper<
    GridRowSelectionModel | undefined
  >(DESTINATIONS_PARAM_NAME, undefined);
  const [selectedConfigs, setSelectedConfigs] = useQueryParamWrapper<
    GridRowSelectionModel | undefined
  >(CONFIGS_PARAM_NAME, undefined);

  const [maxValues, setMaxValues] = useState<MaxValueMap>({
    maxMetricValue: 0,
    maxLogValue: 0,
    maxTraceValue: 0,
  });

  const [hoveredSet, setHoveredNodeAndEdgeSet] = useState<string[]>([]);
  const [editingDestination, setEditingDestination] = useState<string | null>(
    null,
  );

  const [overviewMetrics, setOverviewMetrics] =
    useState<OverviewPageMetricsSubscription["overviewMetrics"]>();

  const { loading: loadingMetrics } = useOverviewPageMetricsSubscription({
    variables: {
      period: selectedPeriod,
    },
    onData({ data }) {
      const gotOverviewMetrics = data.data?.overviewMetrics;
      if (gotOverviewMetrics) {
        setOverviewMetrics(gotOverviewMetrics);

        // Select Top 3 if configurations or destinations are undefined
        if (selectedConfigs == null) {
          const top = selectTop(
            "configuration",
            selectedTelemetry,
            3,
            gotOverviewMetrics.metrics,
          );
          setSelectedConfigs(top);
        }

        if (selectedDestinations == null) {
          const top = selectTop(
            "destination",
            selectedTelemetry,
            3,
            gotOverviewMetrics.metrics,
          );
          setSelectedDestinations(top);
        }
      }
    },
  });

  function handleSelectTop3Configs() {
    const metrics = overviewMetrics?.metrics;
    if (metrics == null) {
      return;
    }
    const top = selectTop("configuration", selectedTelemetry, 3, metrics);
    setSelectedConfigs(top);
  }

  function handleSelectTop3Destinations() {
    const metrics = overviewMetrics?.metrics;
    if (metrics == null) {
      return;
    }
    const top = selectTop("destination", selectedTelemetry, 3, metrics);
    setSelectedDestinations(top);
  }

  return (
    <OverviewPageContext.Provider
      value={{
        setHoveredNodeAndEdgeSet,
        hoveredSet,
        selectedTelemetry,
        setSelectedTelemetry,
        selectedConfigs: selectedConfigs || [],
        setSelectedConfigs,
        selectedDestinations: selectedDestinations || [],
        setSelectedDestinations,
        selectedPeriod,
        setPeriod,
        editingDestination,
        setEditingDestination,
        overviewMetrics,
        loadingMetrics,
        graph,
        setGraph,

        maxValues,
        setMaxValues,
        onSelectTop3Configs: handleSelectTop3Configs,
        onSelectTop3Destinations: handleSelectTop3Destinations,
      }}
    >
      {children}
    </OverviewPageContext.Provider>
  );
};

export function useOverviewPage(): OverviewPageContextValue {
  return useContext(OverviewPageContext);
}

export function selectTop(
  resourceType: "configuration" | "destination",
  selectedTelemetry: string,
  limit: number,
  metrics: OverviewPageMetricsSubscription["overviewMetrics"]["metrics"],
) {
  const filteredMetrics =
    metrics
      .filter((metric) => !metric.isActiveGateway)
      .filter((metric) => metric.nodeID.startsWith(resourceType))
      .filter(
        (metric) => metric.name === TELEMETRY_SIZE_METRICS[selectedTelemetry],
      )
      .sort((a, b) => {
        const valueDiff = b.value - a.value;
        if (valueDiff !== 0) {
          return valueDiff;
        }
        return a.nodeID.localeCompare(b.nodeID);
      }) || [];

  const topResources = filteredMetrics.slice(0, limit).map((metric) => {
    return metric.nodeID.split("/")[1];
  });
  return topResources;
}
