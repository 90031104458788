import { gql } from "@apollo/client";
import { CardHeader, Divider, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAgentMonitoringSummaryQuery } from "../../graphql/generated";
import { AGENTS_PAGE_QUERY_PARAM } from "../../pages/agents";
import colors from "../../styles/colors";
import { NumberChip } from "../NumberChip";
import styles from "./agent-monitoring.module.scss";

gql`
  query GetAgentMonitoringSummary {
    agentMonitoring {
      total
      disconnected
      errored
      outdated
      connected
    }
  }
`;

/**
 * AgentMonitoring shows the total, connected, disconnected, outdated, and errored
 * agent counts in a flex wrapped row.
 */
export const AgentMonitoring: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading } = useGetAgentMonitoringSummaryQuery({
    onError(err) {
      console.error("Error fetching agent monitoring summary", { error: err });
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  function onTotalClick() {
    navigate("/agents");
  }

  function onConnectedClick() {
    const params = new URLSearchParams(location.search);
    params.set(AGENTS_PAGE_QUERY_PARAM, "-status:Disconnected");
    navigate(`/agents?${params.toString()}`);
  }

  function onDisconnectedClick() {
    const params = new URLSearchParams(location.search);
    params.set(AGENTS_PAGE_QUERY_PARAM, "status:Disconnected");
    navigate(`/agents?${params.toString()}`);
  }

  function onOutdatedClick() {
    const params = new URLSearchParams(location.search);
    params.set(AGENTS_PAGE_QUERY_PARAM, "-version:latest");
    navigate(`/agents?${params.toString()}`);
  }

  function onErroredClick() {
    const params = new URLSearchParams(location.search);
    params.set(AGENTS_PAGE_QUERY_PARAM, "status:Error");
    navigate(`/agents?${params.toString()}`);
  }

  return (
    <Stack>
      <CardHeader
        title="Monitoring"
        titleTypographyProps={{
          fontSize: "1rem",
          fontWeight: "700",
          textTransform: "uppercase",
        }}
      />
      <Divider className={styles["divider"]} />

      <Stack className={styles["content"]}>
        <Typography variant="h6" className={styles["typography"]}>
          Agents
        </Typography>
        <Stack rowGap={1}>
          <Stack direction={"row"} flexWrap={"wrap"} spacing={1}>
            <NumberChip
              loading={loading}
              label="Total"
              value={data?.agentMonitoring?.total}
              color={colors.lightBlue}
              onClick={onTotalClick}
            />
            <NumberChip
              loading={loading}
              label="Connected"
              value={data?.agentMonitoring?.connected}
              color={colors.lightGreen}
              onClick={onConnectedClick}
            />
            <NumberChip
              loading={loading}
              label="Disconnected"
              value={data?.agentMonitoring?.disconnected}
              color="slategray"
              onClick={onDisconnectedClick}
            />
          </Stack>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent={"flex-start"}
            spacing={1}
          >
            <NumberChip
              loading={loading}
              label="Outdated"
              value={data?.agentMonitoring?.outdated}
              color={colors.neonCarrot}
              onClick={onOutdatedClick}
            />
            <NumberChip
              loading={loading}
              label="Errored"
              value={data?.agentMonitoring?.errored}
              color="error"
              onClick={onErroredClick}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
