import { Chip } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid";
import { ComponentProps } from "react";
import { AgentStatus } from "../../types/agents";
import { LabelsWithTooltip } from "../LabelsWithTooltip/LabelsWithTooltip";
import { SearchLink } from "../SearchLink";
import { TableAgent } from "./types";

export function renderConfigurationCell(
  cellParams: GridCellParams<any, string>,
) {
  const configName = cellParams.value;
  if (configName == null) {
    return <></>;
  }
  return (
    <SearchLink
      path={`/configurations/${configName}`}
      displayName={configName}
    />
  );
}

export function renderNameDataCell(
  cellParams: GridCellParams<{ name: string; id: string }, TableAgent>,
): JSX.Element {
  return (
    <SearchLink
      path={`/agents/${cellParams.row.id}`}
      displayName={cellParams.row.name}
    />
  );
}

export function renderLabelDataCell(
  cellParams: GridCellParams<any, Record<string, string>>,
): JSX.Element {
  return renderAgentLabels(cellParams.value);
}

export function renderStatusDataCell(
  cellParams: GridCellParams<any, AgentStatus>,
): JSX.Element {
  return renderAgentStatus(cellParams.value);
}

function renderAgentLabels(
  labels: Record<string, string> | undefined,
): JSX.Element {
  return <LabelsWithTooltip labels={labels} />;
}

function renderAgentStatus(status: AgentStatus | undefined): JSX.Element {
  let statusText: string;
  let color: ComponentProps<typeof Chip>["color"] = "default";

  switch (status) {
    case AgentStatus.DISCONNECTED:
      statusText = "Disconnected";
      break;
    case AgentStatus.CONNECTED:
      statusText = "Connected";
      color = "success";
      break;
    case AgentStatus.ERROR:
      statusText = "Errored";
      color = "error";
      break;
    // Component failed is deprecated.
    case AgentStatus.COMPONENT_FAILED:
      statusText = "Component Failed";
      break;
    case AgentStatus.DELETED:
      statusText = "Deleted";
      break;
    case AgentStatus.CONFIGURING:
      statusText = "Configuring";
      break;
    case AgentStatus.UPGRADING:
      statusText = "Upgrading";
      color = "warning";
      break;
    case AgentStatus.PENDING:
      statusText = "Pending";
      color = "warning";
      break;
    default:
      statusText = "";
      break;
  }

  return <Chip size="small" color={color} label={statusText} />;
}
