import { gql } from "@apollo/client";
import {
  Autocomplete,
  BaseSelectProps,
  MenuItem,
  StandardSelectProps,
  TextField,
} from "@mui/material";
import {
  GetAgentTypesQuery,
  useGetAgentTypesQuery,
} from "../../graphql/generated";
import styles from "./agent-type-select.module.scss";

gql`
  query GetAgentTypes {
    agentTypes {
      agentTypes {
        metadata {
          id
          displayName
          name
          version
        }
      }
    }
  }
`;

type AgentType = NonNullable<GetAgentTypesQuery["agentTypes"]["agentTypes"][0]>;

type SelectProps = Omit<
  StandardSelectProps & BaseSelectProps<string>,
  "variant"
>;

interface AgentTypeSelectProps extends SelectProps {
  onAgentTypeSelected: (value: string) => void;
}

export const AgentTypeSelect: React.FC<AgentTypeSelectProps> = ({
  onAgentTypeSelected,
  size,
}) => {
  const { data } = useGetAgentTypesQuery({
    fetchPolicy: "network-only",
  });
  const agentTypesData = data?.agentTypes.agentTypes ?? [];

  return (
    <Autocomplete
      id="agent-type-select"
      size={size}
      disableClearable
      onChange={(_, value) => onAgentTypeSelected(value.name)}
      options={agentTypesData.map((a: AgentType) => a.metadata)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.displayName ?? option.name}
      classes={{ root: styles.root }}
      renderInput={(params) => <TextField {...params} label={"Agent Type"} />}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <MenuItem
            {...optionProps}
            key={key}
            value={option.name}
            classes={{ root: styles.item }}
          >
            {option.displayName}
          </MenuItem>
        );
      }}
    />
  );
};
