import { Button, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { GetResourceTypeQuery, Kind } from "../../graphql/generated";
import { Stability } from "../../types/resources";
import { ActionsSection } from "../DialogComponents";
import {
  FormValues,
  initFormValues,
  isValid,
  useValidationContext,
  ValidationContextProvider,
} from "../ResourceConfigForm";
import {
  FormValueContextProvider,
  useResourceFormValues,
} from "../ResourceConfigForm/ResourceFormContext";
import { initFormErrors } from "../ResourceConfigForm/init-form-values";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";
import { ResourceForm } from "./ResourceForm";
import mixins from "../../styles/mixins.module.scss";

interface CreateConfigureViewProps {
  resourceKind: Kind.Processor | Kind.Extension;
  resourceType: NonNullable<GetResourceTypeQuery["resourceType"]>;
  onBack: () => void;
  onSave: (formValues: FormValues) => void;
  onClose: () => void;
  initValues?: FormValues;
  actionButtonText?: string;
}

const CreateConfigureViewComponent: React.FC<CreateConfigureViewProps> = ({
  resourceType,
  resourceKind,
  actionButtonText,
  onSave,
  onBack,
}) => {
  var { processorIndex = 0 } = useSnapshot();
  const { formValues } = useResourceFormValues();
  const { touchAll, setErrors } = useValidationContext();
  const stackRef = useRef<HTMLDivElement>(null);

  const [newContext, setNewContext] = useState<string | undefined>();

  useEffect(() => {
    if (resourceType?.metadata?.name === "processor_bundle") {
      setNewContext("bundle");
    } else {
      setNewContext("");
    }
  }, [resourceType, newContext, processorIndex]);

  useEffect(() => {
    if (stackRef.current) {
      stackRef.current.focus();
    }
  }, []);

  function handleSave() {
    const errors = initFormErrors(
      resourceType.spec.parameters,
      formValues,
      resourceKind,
    );

    if (!isValid(errors)) {
      setErrors(errors);
      touchAll();
      return;
    }

    onSave(formValues);
  }
  return (
    <Stack
      ref={stackRef}
      className={mixins["flex-grow"]}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          event.stopPropagation();
          onBack();
        }
      }}
    >
      <Stack className={mixins["flex-grow"]} overflow="auto">
        <ResourceForm
          title={resourceType.metadata.displayName ?? ""}
          description={resourceType.metadata.description ?? ""}
          additionalInfo={resourceType.metadata.additionalInfo}
          parameterDefinitions={resourceType.spec.parameters}
          stability={resourceType.metadata.stability ?? Stability.UNKNOWN}
          resourceDocLink={resourceType.metadata.resourceDocLink ?? ""}
          resourceType={resourceType}
        />
      </Stack>
      {(processorIndex <= 1 || newContext !== "bundle") && (
        <ActionsSection>
          <Button variant="outlined" color="secondary" onClick={onBack}>
            Cancel
          </Button>

          <Button variant="contained" color="primary" onClick={handleSave}>
            {actionButtonText ?? "Done"}
          </Button>
        </ActionsSection>
      )}
    </Stack>
  );
};

export const CreateConfigureView: React.FC<CreateConfigureViewProps> = ({
  initValues: initValuesProp,
  ...rest
}) => {
  const initValues = initFormValues(
    rest.resourceType.spec.parameters,
    null,
    null,
    false,
  );

  if (initValuesProp) {
    Object.assign(initValues, initValuesProp);
  }

  const initErrors = initFormErrors(
    rest.resourceType.spec.parameters,
    initValues,
    rest.resourceKind,
  );
  return (
    <FormValueContextProvider initValues={initValues}>
      <ValidationContextProvider
        initErrors={initErrors}
        definitions={rest.resourceType.spec.parameters}
      >
        <CreateConfigureViewComponent {...rest} />
      </ValidationContextProvider>
    </FormValueContextProvider>
  );
};
