import { ComponentProps } from "react";
import { useConfigurationEditData } from "../../hooks/useConfigurationEditData";
import { APIVersion } from "../../types/resources";
import { trimVersion } from "../../utils/version-helpers";
import { PipelineGraphV2 } from "../PipelineGraphV2";
import { PipelineGraph } from "./PipelineGraph";

type V2SwitchProps = ComponentProps<typeof PipelineGraph>;

export const V2Switch: React.FC<V2SwitchProps> = ({
  configurationName,
  tab,
  ...rest
}) => {
  const { live, draft } = useConfigurationEditData();
  const current = tab === "live" ? live : draft;
  if (current == null) {
    return null;
  }

  if (current?.apiVersion === APIVersion.V1) {
    return (
      <PipelineGraph
        configurationName={configurationName}
        tab={tab}
        {...rest}
      />
    );
  }

  return (
    <PipelineGraphV2
      configurationName={trimVersion(configurationName)}
      tab={tab}
      {...rest}
    />
  );
};
