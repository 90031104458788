import { gql } from "@apollo/client";
import { Kind } from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { useComponentDialog } from "../Dialogs/hooks/useComponentDialog";
import { ResourceCard } from "./ResourceCard";

gql`
  query getSourceWithType($name: String!) {
    sourceWithType(name: $name) {
      source {
        apiVersion
        kind
        metadata {
          name
          version
          id
          labels
          version
          displayName
        }
        spec {
          type
          parameters {
            name
            value
          }
          disabled
        }
      }
      sourceType {
        metadata {
          id
          name
          displayName
          version
          icon
          description
          stability
          additionalInfo {
            message
            documentation {
              text
              url
            }
          }
          resourceDocLink
        }
        spec {
          parameters {
            label
            name
            description
            required
            type
            default
            relevantIf {
              name
              operator
              value
            }
            documentation {
              text
              url
            }
            advancedConfig
            validValues
            options {
              multiline
              creatable
              trackUnchecked
              sectionHeader
              subHeader
              horizontalDivider
              gridColumns
              labels
              metricCategories {
                label
                column
                metrics {
                  name
                  description
                  kpi
                }
              }
              password
              processorContext
              sensitive
              variant
            }
          }
        }
      }
    }
  }
`;

interface ResourceSourceCardProps {
  title: string;
  subtitle: string;
  icon: string;
  paused: boolean;
  resource: BPResourceConfiguration;
  disabled?: boolean;
  refetchConfiguration: () => void;
  onDeleteSuccess?: () => void;
}

export const ResourceSourceCard: React.FC<ResourceSourceCardProps> = ({
  title,
  subtitle,
  icon,
  paused,
  resource,
  disabled,
  refetchConfiguration,
  onDeleteSuccess,
}) => {
  const { editComponent } = useComponentDialog();
  return (
    <ResourceCard
      dataTestID={`source-card-${resource.id}`}
      title={title}
      subtitle={subtitle}
      icon={icon}
      showBookmarkIcon
      disabled={disabled || paused}
      paused={paused}
      onClick={() => {
        editComponent({
          kind: Kind.Source,
          resource,
          onSuccess: ({ operation }) => {
            refetchConfiguration();
            if (operation === "delete") {
              onDeleteSuccess?.();
            }
          },
        });
      }}
    />
  );
};
