import { memo } from "react";
import { NodeProps } from "reactflow";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { findResource } from "../../../utils/classes/configuration";
import { InlineSourceCard } from "../../Cards/InlineSourceCard";
import { ResourceSourceCard } from "../../Cards/ResourceSourceCard";
import { useBPGraph } from "../BPGraphProvider";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { AttributeName, V2NodeData } from "../types";

const SourceNodeV2: React.FC<NodeProps<V2NodeData>> = ({ id, data }) => {
  const { configuration, refetchConfiguration } = useV2PipelineGraph();
  const { hoveredSet, onMouseEnterNode, onMouseExitNode } = useBPGraph();
  const { attributes } = data;
  const { title, subtitle, icon, paused } = attributes;

  const componentPath = attributes[AttributeName.ComponentPath];

  const isInactive = !hasPipelineTypeFlag(
    data.telemetryType,
    attributes[AttributeName.ActiveTypeFlags],
  );
  const notInHoveredSet = hoveredSet.length > 0 && !hoveredSet.includes(id);

  if (configuration == null) {
    return null;
  }

  const resourceConfig = findResource(configuration, componentPath);
  if (resourceConfig == null) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => onMouseEnterNode(id)}
      onMouseLeave={onMouseExitNode}
    >
      {resourceConfig.isInline() ? (
        <InlineSourceCard
          title={title}
          subtitle={subtitle}
          icon={icon}
          paused={paused}
          resource={resourceConfig}
          disabled={isInactive || notInHoveredSet}
          refetchConfiguration={refetchConfiguration}
        />
      ) : (
        <ResourceSourceCard
          title={title}
          subtitle={subtitle}
          icon={icon}
          paused={paused}
          resource={resourceConfig}
          disabled={isInactive || notInHoveredSet}
          refetchConfiguration={refetchConfiguration}
        />
      )}
    </div>
  );
};

export default memo(SourceNodeV2);
