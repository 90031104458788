import { gql } from "@apollo/client";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { withEENavBar } from "../../components/EENavBar";
import { ValidationContextProvider } from "../../components/ResourceConfigForm";
import { withRBAC } from "../../contexts/RBAC";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { Kind } from "../../graphql/generated";
import { useFeatureFlag } from "../../hooks/useFeatureFlags";
import { classes } from "../../utils/styles";
import { ResourceLibraryTable } from "./ResourceLibraryTable";
import styles from "./resource-library.module.scss";

gql`
  query Sources {
    sources {
      metadata {
        id
        name
        displayName
        version
      }
      spec {
        type
      }
      usage {
        configurationCount
      }
    }
  }
`;

/**
 * Tables of reusable sources, processors, and destinations
 */
const ResourceLibraryPageContent: React.FC = () => {
  const v2ConnectorsEnabled = useFeatureFlag("v2Connectors");

  // when arriving from Overview
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#destinations") {
      const targetElement = document.getElementById("destinations");
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    }
  }, []);

  return (
    <ValidationContextProvider initErrors={{}} definitions={[]}>
      <Stack spacing={3} className={classes([styles.root])}>
        <ResourceLibraryTable kind={Kind.Source} />
        <ResourceLibraryTable kind={Kind.Processor} />
        {v2ConnectorsEnabled && <ResourceLibraryTable kind={Kind.Connector} />}
        <ResourceLibraryTable kind={Kind.Destination} />
      </Stack>
    </ValidationContextProvider>
  );
};

export const ResourceLibraryPage = withRequireLogin(
  withRBAC(withEENavBar(ResourceLibraryPageContent)),
);
