import { Typography } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { Kind } from "../../../graphql/generated";
import { ResourceKind } from "../../../types/resources";
import { ConfirmDeleteResourceDialog } from "../../ConfirmDeleteResourceDialog";
import { DialogAction } from "./utils";

interface ConfirmDeleteResourceAction extends DialogAction<undefined> {
  kind: ResourceKind | Kind.Configuration;
  action: "delete" | "remove";
}

export interface ConfirmDeleteResourceDialogContextValue {
  confirmDeleteResource: (action: ConfirmDeleteResourceAction) => void;
}

const defaultValue: ConfirmDeleteResourceDialogContextValue = {
  confirmDeleteResource: () => {
    throw new Error("ConfirmDeleteResourceDialogProvider not specified");
  },
};

export const ConfirmDeleteResourceDialogContext = createContext(defaultValue);

export const ConfirmDeleteResourceDialogProvider: React.FC = ({ children }) => {
  const [action, setAction] = useState<
    ConfirmDeleteResourceAction | undefined
  >();
  const [open, setOpen] = useState<boolean>(false);

  function confirmDeleteResource(action: ConfirmDeleteResourceAction) {
    setAction(action);
    setOpen(true);
  }

  function close() {
    setAction(undefined);
    setOpen(false);
  }

  function onClose() {
    action?.onCancel?.();
    close();
  }

  function onDelete() {
    action?.onSuccess?.(undefined);
    close();
  }

  return (
    <ConfirmDeleteResourceDialogContext.Provider
      value={{ confirmDeleteResource }}
    >
      {action && (
        <ConfirmDeleteResourceDialog
          open={open}
          onClose={onClose}
          onCancel={onClose}
          onDelete={onDelete}
          action={action.action}
        >
          <Typography>
            {`Are you sure you want to ${action?.action} this ${action?.kind}?`}
          </Typography>
        </ConfirmDeleteResourceDialog>
      )}
      {children}
    </ConfirmDeleteResourceDialogContext.Provider>
  );
};

/**
 * Provides a confirmDeleteResource function that will display a confirmation dialog and
 * call the appropriate action handler based on the user's choice.
 */
export function useConfirmDeleteResourceDialog(): ConfirmDeleteResourceDialogContextValue {
  return useContext(ConfirmDeleteResourceDialogContext);
}
