import { Edge, MarkerType, Node, Position, XYPosition } from "reactflow";
import { PipelineType } from "../../graphql/generated";
import { PipelineTypeFlags } from "../../types/configuration";
import { isSourceID } from "../PipelineGraph/Nodes/ProcessorNode";
import { isNodeDisabled } from "../PipelineGraph/Nodes/nodeUtils";
import {
  AttributeName,
  V2EdgeData,
  V2NodeData,
} from "../PipelineGraphV2/types";
import { BPGraph } from "./graph";
import { LayoutElk } from "./layout-elk";
import { LayoutGrid } from "./layout-grid";
import { LayoutOverview } from "./layout-overview";

export async function layoutV2Graph(
  graph: BPGraph,
  readOnly: boolean,
  pipelineType: PipelineType,
  onAddSource: (pipelineType: PipelineType) => void,
  onAddDestination: (pipelineType: PipelineType) => void,
  algorithm: string,
): Promise<{
  nodes: Node<V2NodeData>[];
  edges: Edge<V2EdgeData>[];
}> {
  const layout =
    algorithm === "elk" ? new LayoutElk(graph) : new LayoutGrid(graph);
  await layout.perform();

  const nodes: Node<V2NodeData>[] = [];
  const edges: Edge<V2EdgeData>[] = [];

  // if there's only one source or one destination we need to layout add source and add destination cards
  // we also need to add edges between the source/destination and the add source/add destination cards

  const addSourceCard = graph.sources().length === 0;
  const addDestinationCard = graph.targets().length === 0;

  // layout sources
  if (addSourceCard) {
    //
    // +--------+     +-----------------+
    // |        |     |                 |
    // | source |-----| add-source-proc |
    // |        |     |                 |
    // +--------+     +-----------------+
    //
    nodes.push({
      id: "add-source",
      data: {
        buttonText: "Source",
        handlePosition: Position.Right,
        handleType: "source",
        onClick: () => onAddSource(pipelineType),
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.None,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
        telemetryType: pipelineType,
      },
      position: layout.getPosition("add-source", "sourceNode"),
      type: "uiControlNode",
    });

    nodes.push({
      id: "add-source-proc",
      data: {
        attributes: {},
        telemetryType: pipelineType,
      },
      position: layout.getPosition("add-source-proc", "dummyProcessorNode"),
      type: "dummyProcessorNode",
    });

    edges.push({
      id: "add-source_add-source-proc",
      source: "add-source",
      target: "add-source-proc",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.PipelineType]: pipelineType,
          [AttributeName.MetricID]: "",
        },
      },
      type: "configurationEdge",
    });
    // connect add-source-proc to all the destination processors
    for (let i = 0; i < (graph.intermediates() ?? []).length; i++) {
      const n = graph.intermediates()[i];
      if (!isSourceID(n.id)) {
        edges.push({
          id: `${n.id}_add-source-proc`,
          target: `${n.id}`,
          source: "add-source-proc",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          data: {
            attributes: {
              [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
              [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
              [AttributeName.PipelineType]: pipelineType,
              [AttributeName.MetricID]: "",
            },
          },
          type: "configurationEdge",
        });
      }
    }
  } else {
    for (let i = 0; i < (graph.sources() ?? []).length; i++) {
      const n = graph.sources()[i];

      nodes.push({
        id: `${n.id}`,
        data: {
          attributes: n.attributes,
          telemetryType: pipelineType,
          label: n.label,
        },
        position: layout.getPosition(n.id),
        sourcePosition: Position.Right,
        type: n.type,
      });
    }
  }

  // layout intermediates
  for (let i = 0; i < graph.intermediates().length; i++) {
    const n = graph.intermediates()[i];

    nodes.push({
      id: `${n.id}`,
      data: {
        attributes: n.attributes,
        telemetryType: pipelineType,
      },
      position: layout.getPosition(n.id),
      type: n.type,
    });
  }

  // Lay out destinations
  if (addDestinationCard) {
    nodes.push({
      id: "add-destination",
      data: {
        buttonText: "Destination",
        handlePosition: Position.Left,
        handleType: "target",
        isButton: false,
        onClick: () => onAddDestination(pipelineType),
        attributes: {},
        telemetryType: pipelineType,
      },
      position: layout.getPosition("add-destination", "destinationNode"),
      type: "uiControlNode",
    });
    nodes.push({
      id: "add-destination-proc",
      position: layout.getPosition(
        "add-destination-proc",
        "dummyProcessorNode",
      ),
      type: "dummyProcessorNode",
      data: {
        attributes: {},
        telemetryType: pipelineType,
      },
    });
    edges.push({
      id: "add-destination-proc_add-destination",
      source: "add-destination-proc",
      target: "add-destination",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.PipelineType]: pipelineType,
          [AttributeName.MetricID]: "",
        },
      },
      type: "configurationEdge",
    });
    // connect dummy processor to all the source processors
    for (let i = 0; i < graph.intermediates().length; i++) {
      const n = graph.intermediates()[i];
      if (isSourceID(n.id)) {
        edges.push({
          id: `${n.id}_add-destination-proc`,
          source: `${n.id}`,
          target: "add-destination-proc",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          data: {
            attributes: {
              [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
              [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
              [AttributeName.PipelineType]: pipelineType,
              [AttributeName.MetricID]: "",
            },
          },
          type: "configurationEdge",
        });
      }
    }
  } else {
    for (let i = 0; i < graph.targets().length; i++) {
      const n = graph.targets()[i];
      nodes.push({
        id: `${n.id}`,
        data: {
          attributes: n.attributes,
          telemetryType: pipelineType,
          label: n.label,
        },
        position: layout.getPosition(n.id),
        targetPosition: Position.Left,
        type: n.type,
      });
    }
  }

  // Add an Add Source button if we aren't using the Add Source Card and
  // the graph is not read only.
  if (!addSourceCard && !readOnly) {
    nodes.push({
      id: "add-source",
      data: {
        buttonText: "Source",
        handlePosition: Position.Right,
        handleType: "source",
        isButton: true,
        onClick: () => onAddSource(pipelineType),
        attributes: {
          sourceIndex: 0,
          resourceId: "add-source",
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
        telemetryType: pipelineType,
      },
      position: layout.getPosition("add-source", "uiControlNode"),
      type: "uiControlNode",
    });
  }

  // Add an Add Destination button if we aren't using the Add Destination Card and
  // the graph is not read only.
  if (!addDestinationCard && !readOnly) {
    nodes.push({
      id: "add-destination",
      data: {
        buttonText: "Destination",
        handlePosition: Position.Left,
        handleType: "target",
        isButton: true,
        onClick: () => onAddDestination(pipelineType),
        attributes: {},
        telemetryType: pipelineType,
      },
      position: layout.getPosition("add-destination", "uiControlNode"),
      type: "uiControlNode",
    });
  }
  if (addDestinationCard && addSourceCard) {
    edges.push({
      id: "add-source-proc_add-destination-proc",
      source: "add-source-proc",
      target: "add-destination-proc",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.PipelineType]: pipelineType,
          [AttributeName.MetricID]: "",
        },
      },
      type: "configurationEdge",
    });
  }

  for (const e of graph.edges() || []) {
    // skip edges that are not of the telemetry type
    const edgePipelineType = e.attributes?.["pipelineType"];
    if (edgePipelineType != null && edgePipelineType !== pipelineType) {
      continue;
    }
    if (e.target == null) {
      continue;
    }
    const edge: Edge<V2EdgeData> & { key: string } = {
      key: e.id,
      id: e.id,
      source: e.source,
      sourceHandle: e.sourceHandle ?? null,
      target: e.target,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        attributes: e.attributes,
      },
      type: "configurationEdge",
      zIndex: 1,
    };

    // Set the edge's ActiveTypeFlag attribute from the intersection of the source
    // and target node ActiveTypeFlag attribute

    // Find the source and target nodes
    if (graph.attributes()["type"] !== "routes") {
      const sourceNode = nodes.find((n) => n.id === e.source);
      const targetNode = nodes.find((n) => n.id === e.target);

      if (sourceNode && targetNode) {
        // Get the ActiveTypeFlag attribute from the source and target nodes
        const sourceNodeActiveTypeFlag =
          sourceNode.data.attributes?.[AttributeName.ActiveTypeFlags];
        const targetNodeActiveTypeFlag =
          targetNode.data.attributes?.[AttributeName.ActiveTypeFlags];

        // If the source and target nodes have ActiveTypeFlag attributes
        if (sourceNodeActiveTypeFlag && targetNodeActiveTypeFlag) {
          // Set the ActiveTypeFlag attribute as the intersection of the two nodes
          edge.data!.attributes = {
            ...(edge.data!.attributes ?? {}),
            [AttributeName.ActiveTypeFlags]:
              sourceNodeActiveTypeFlag & targetNodeActiveTypeFlag,
          } as any;
        }
      }
    }

    if (isNodeDisabled(pipelineType || "", edge.data!.attributes)) {
      edge.zIndex = 0;
    }
    edges.push(edge);
  }

  return { nodes, edges };
}

export async function layoutV2OverviewGraph(
  graph: BPGraph,
  pipelineType: PipelineType,
): Promise<{
  nodes: Node<V2NodeData>[];
  edges: Edge<V2EdgeData>[];
}> {
  const layout = new LayoutOverview(graph);
  await layout.perform();

  const nodes: Node<V2NodeData>[] = [];
  const edges: Edge<V2EdgeData>[] = [];

  // layout sources
  for (let i = 0; i < (graph.sources() ?? []).length; i++) {
    const n = graph.sources()[i];

    nodes.push({
      id: `${n.id}`,
      data: {
        attributes: n.attributes,
        telemetryType: pipelineType,
        label: n.label,
      },
      position: layout.getPosition(n.id),
      sourcePosition: Position.Right,
      type: n.type,
    });
  }

  // layout intermediates
  for (let i = 0; i < graph.intermediates().length; i++) {
    const n = graph.intermediates()[i];

    nodes.push({
      id: `${n.id}`,
      data: {
        attributes: n.attributes,
        telemetryType: pipelineType,
      },
      position: layout.getPosition(n.id),
      type: n.type,
    });
  }

  // layout targets
  for (let i = 0; i < graph.targets().length; i++) {
    const n = graph.targets()[i];
    nodes.push({
      id: `${n.id}`,
      data: {
        attributes: n.attributes,
        telemetryType: pipelineType,
        label: n.label,
      },
      position: layout.getPosition(n.id),
      targetPosition: Position.Left,
      type: n.type,
    });
  }

  // for overview graph: track which edges are on top to show metrics on
  const highestTargetForSource: Map<string, XYPosition> = new Map();
  const highestSourceForTarget: Map<string, XYPosition> = new Map();

  for (const e of graph.edges() || []) {
    // skip edges that are not of the telemetry type
    const edgePipelineType = e.attributes?.["pipelineType"];
    if (edgePipelineType != null && edgePipelineType !== pipelineType) {
      continue;
    }
    if (e.target == null) {
      continue;
    }
    const edge: Edge<V2EdgeData> & { key: string } = {
      key: e.id,
      id: e.id,
      source: e.source,
      sourceHandle: e.sourceHandle ?? null,
      target: e.target,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        attributes: e.attributes,
      },
      type: "configurationEdge",
      zIndex: 1,
    };

    // Find the source and target nodes
    const sourceNode = nodes.find((n) => n.id === e.source);
    const targetNode = nodes.find((n) => n.id === e.target);

    // track highest edges for overview page
    if (sourceNode && targetNode) {
      if (
        e.source.startsWith("configuration") ||
        e.source === "everything/configuration"
      ) {
        if (highestTargetForSource.has(e.source)) {
          if (
            edgeIsHigher(
              sourceNode,
              targetNode,
              highestTargetForSource.get(e.source)!,
              "sourceNode",
            )
          ) {
            highestTargetForSource.set(e.source, targetNode.position);
          }
        } else {
          highestTargetForSource.set(e.source, targetNode.position);
        }
      }

      if (
        e.target.startsWith("destination") ||
        e.target === "everything/destination"
      ) {
        if (highestSourceForTarget.has(e.target)) {
          if (
            edgeIsHigher(
              sourceNode,
              targetNode,
              highestSourceForTarget.get(e.target)!,
              "targetNode",
            )
          ) {
            highestSourceForTarget.set(e.target, sourceNode.position);
          }
        } else {
          highestSourceForTarget.set(e.target, sourceNode.position);
        }
      }
    }

    if (graph.attributes()["type"] !== "routes") {
      // Set the edge's ActiveTypeFlag attribute from the intersection of the source
      // and target node ActiveTypeFlag attribute
      if (sourceNode && targetNode) {
        // Get the ActiveTypeFlag attribute from the source and target nodes
        const sourceNodeActiveTypeFlag =
          sourceNode.data.attributes?.[AttributeName.ActiveTypeFlags];
        const targetNodeActiveTypeFlag =
          targetNode.data.attributes?.[AttributeName.ActiveTypeFlags];

        // If the source and target nodes have ActiveTypeFlag attributes
        if (sourceNodeActiveTypeFlag && targetNodeActiveTypeFlag) {
          // Set the ActiveTypeFlag attribute as the intersection of the two nodes
          edge.data!.attributes = {
            ...(edge.data!.attributes ?? {}),
            [AttributeName.ActiveTypeFlags]:
              sourceNodeActiveTypeFlag & targetNodeActiveTypeFlag,
          } as any;
        }
      }
    }

    if (isNodeDisabled(pipelineType || "", edge.data!.attributes)) {
      edge.zIndex = 0;
    }
    edges.push(edge);
  }

  // mark highest edges to show metrics on overview page
  for (const e of edges) {
    const sourceNode = nodes.find((n) => n.id === e.source);
    const targetNode = nodes.find((n) => n.id === e.target);
    if (
      highestTargetForSource.has(e.source) &&
      targetNode?.position === highestTargetForSource.get(e.source)
    ) {
      e.data!.attributes.showStartMetric = true;
    }
    if (
      highestSourceForTarget.has(e.target) &&
      sourceNode?.position === highestSourceForTarget.get(e.target)
    ) {
      e.data!.attributes.showEndMetric = true;
    }
  }

  return { nodes, edges };
}

function edgeIsHigher(
  sourceNode: Node<V2NodeData>,
  targetNode: Node<V2NodeData>,
  curPosition: XYPosition,
  nodeType: string,
): boolean {
  if (nodeType === "targetNode") {
    const curDx = targetNode.position.x - curPosition.x;
    const curDy = targetNode.position.y - curPosition.y;
    const curAngle = (Math.atan2(curDy, curDx) * 180) / Math.PI;

    const sourceDx = targetNode.position.x - sourceNode.position.x;
    const sourceDy = targetNode.position.y - sourceNode.position.y;
    const sourceAngle = (Math.atan2(sourceDy, sourceDx) * 180) / Math.PI;

    return sourceAngle > curAngle;
  }

  if (nodeType === "sourceNode") {
    const curDx = curPosition.x - sourceNode.position.x;
    const curDy = curPosition.y - sourceNode.position.y;
    const curAngle = (Math.atan2(curDy, curDx) * 180) / Math.PI;

    const targetDx = targetNode.position.x - sourceNode.position.x;
    const targetDy = targetNode.position.y - sourceNode.position.y;
    const targetAngle = (Math.atan2(targetDy, targetDx) * 180) / Math.PI;

    return targetAngle < curAngle;
  }

  return false;
}
