import { isEqual } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  Kind,
  PipelineType,
  ResourceConfiguration,
  useProcessorsWithParamsQuery,
  useUpdateProcessorsMutation,
} from "../../graphql/generated";
import { BPConfiguration } from "../../utils/classes/configuration";
import {
  EditProcessorsInfo,
  useV2PipelineGraph,
} from "../PipelineGraphV2/PipelineGraphV2Context";
import { ParamInputProps } from "../ResourceConfigForm/ParameterInput/ParameterInput";
import { useValidationContext } from "../ResourceConfigForm/ValidationContext";
import { validateProcessorBundleField } from "../ResourceConfigForm/validation-functions";
import { ResourceConfigurationEditor } from "../ResourceConfigurationEditor";
import { useProcessorsDialog } from "../ResourceDialog/ProcessorDialogContext";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";

export type ProcessorBundleValue = ResourceConfiguration[];
const EMPTY_VALUE: ResourceConfiguration[] = [];

export const ProcessorBundle: React.FC<
  ParamInputProps<ProcessorBundleValue>
> = ({ definition, value: paramValue, readOnly, onValueChange }) => {
  const telemetryTypes: PipelineType[] = [PipelineType.Logs];

  const [processors, setProcessors] = useState<ResourceConfiguration[]>(
    paramValue ?? [],
  );

  const [editProcessorsInfo] = useState<EditProcessorsInfo | null>(null);
  const {
    data: reuseableProcessorsData,
    refetch: refetchReusableProcessorsData,
  } = useProcessorsWithParamsQuery({ fetchPolicy: "cache-and-network" });
  const { refetchConfiguration } = useV2PipelineGraph();
  const [updateProcessors] = useUpdateProcessorsMutation({});
  const componentPath = editProcessorsInfo?.componentPath;
  const initProcessors = paramValue;
  const { configuration, closeProcessorDialog } = useProcessorsDialog();
  const { context, setContext } = useSnapshot();
  const { touched, touch, setError } = useValidationContext();
  useEffect(() => {
    setContext && setContext("bundle");
  }, [setContext]);
  if (!initProcessors) return null;

  /* -------------------------------- Functions ------------------------------- */

  function handleProcessorValueChange(processors: ResourceConfiguration[]) {
    // Clone the current processor at the given index
    setProcessors(processors);
    // // Trigger any additional value change logic if necessary
    // make an array of numbers from the processors

    if (isEqual(processors, EMPTY_VALUE)) {
      onValueChange && onValueChange([]);
    } else {
      onValueChange && onValueChange(processors);
    }

    // Example: Setting validation or error state (adjust as needed for your logic)
    setError(
      definition.name,
      validateProcessorBundleField(definition, processors),
    );

    // Example: Mark the field as touched
    if (!touched[definition.name]) {
      touch(definition.name);
    }
  }

  async function handleUpdateInlineProcessors(
    processors: ResourceConfiguration[],
  ): Promise<boolean> {
    let success = true;

    await updateProcessors({
      variables: {
        input: {
          configuration: configuration?.metadata?.name!,
          componentPath: componentPath!,
          processors: processors,
        },
      },
      onError(error) {
        success = false;
        console.error(error);
        enqueueSnackbar("Failed to save processors", {
          variant: "error",
          key: "save-processors-error",
        });
      },
    });

    return success;
  }

  let handleDelete: (() => Promise<void>) | undefined = undefined;

  // Allow a delete for non source/destination processors
  if (
    editProcessorsInfo &&
    editProcessorsInfo.componentPath.startsWith("processors/")
  ) {
    handleDelete = async () => {
      const bpConfig = new BPConfiguration(configuration);
      bpConfig.deleteComponent(editProcessorsInfo.componentPath);
      await bpConfig.apply();
      closeProcessorDialog();
      refetchConfiguration();
    };
  }

  return (
    <ResourceConfigurationEditor
      closeDialog={closeProcessorDialog}
      initItems={initProcessors}
      items={processors}
      kind={Kind.Processor}
      reusableResources={reuseableProcessorsData?.processors}
      refetchReusableResources={refetchReusableProcessorsData}
      onItemsChange={handleProcessorValueChange}
      refetchConfiguration={refetchConfiguration}
      telemetryTypes={telemetryTypes}
      updateInlineItems={handleUpdateInlineProcessors}
      readOnly={readOnly}
      onDelete={handleDelete}
      context={context}
      setContext={setContext}
    />
  );
};
