import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  GetResourceTypeQuery,
  GetResourceWithTypeQuery,
  Kind,
  ParameterizedResource,
  useGetResourcesLazyQuery,
  useGetResourceTypeQuery,
  useGetResourceWithTypeQuery,
} from "../../../graphql/generated";
import { ResourceKind } from "../../../types/resources";
import { BPResourceConfiguration } from "../../../utils/classes";
import { resourceTypeOfKind } from "../../../utils/classes/component-type";
import {
  deleteComponent,
  saveComponent,
  saveComponentToLibrary,
  togglePaused,
  unlinkComponentFromLibrary,
} from "../../../utils/forms/resource-manager";
import { useAppLoading } from "../../AppLoading/useAppLoading";
import { MinimumRequiredConfig } from "../../PipelineGraph/PipelineGraph";
import { FormValues } from "../../ResourceConfigForm";
import { DialogResource } from "../../ResourceDialog";
import { EditResourceDialog } from "../../ResourceDialog/EditResourceDialog";
import { useConfirmDeleteResourceDialog } from "../hooks/useConfirmDeleteResourceDialog";
import { useConfirmResourceInUseWarningDialog } from "../hooks/useConfirmResourceInUseWarningDialog";

// TODO: move to shared-queries?
type ResourceType =
  GetResourceWithTypeQuery["resourceWithType"]["resourceType"] &
    GetResourceTypeQuery["resourceType"];
type Resource = GetResourceWithTypeQuery["resourceWithType"]["resource"];

export interface ComponentDialogResult {
  operation: "save" | "link" | "unlink" | "delete" | "pause" | "resume";
  resource: BPResourceConfiguration;
}

export interface ComponentDialogProps {
  // current configuration if opened from a card on a configuration. will be undefined for
  // the library page.
  configuration?: MinimumRequiredConfig;
  editingKind: ResourceKind | null;
  editingComponent: BPResourceConfiguration | null;
  open: boolean;
  readOnly?: boolean;
  showLibraryBookmark?: boolean;
  onSuccess?: (result: ComponentDialogResult) => void;
  onCancel?: () => void;
  // onDelete can be specified to override the default delete behavior. This is used on
  // the library page to delete a resource from the library.
  onDelete?: () => void;
}

export const ComponentDialog: React.FC<ComponentDialogProps> = ({
  configuration,
  editingKind,
  editingComponent,
  open,
  readOnly,
  onDelete,
  onSuccess,
  onCancel,
}) => {
  const { confirmDeleteResource } = useConfirmDeleteResourceDialog();
  const { confirmResourceInUseWarning } =
    useConfirmResourceInUseWarningDialog();

  const { enqueueSnackbar } = useSnackbar();
  const { setAppLoading } = useAppLoading();

  const name = editingComponent?.name ?? "";
  const typeName = editingComponent?.type ?? "";

  const [resource, setResource] = useState<Resource | null>(null);
  const [resourceType, setResourceType] = useState<ResourceType | null>(null);

  const [libraryResources, setLibraryResources] = useState<DialogResource[]>();

  // We can only link/unlink if we have a configuration. If we don't have a configuration,
  // we are in the library already and we cannot unlink into a configuration. We also
  // don't show the library bookmark for destinations because they are always library
  // resources.
  const showLibraryBookmark =
    configuration != null && editingKind !== Kind.Destination;

  useGetResourceWithTypeQuery({
    variables: { name, kind: editingKind! },
    fetchPolicy: "network-only",
    skip: editingComponent == null || editingComponent.isInline(),
    onCompleted: ({ resourceWithType }) => {
      const { resource, resourceType } = resourceWithType;
      setResource(resource);
      setResourceType(resourceType);
    },
    onError: (e) => {
      console.error("Failed to fetch ResourceWithType", {
        error: e,
        name,
        kind: editingKind,
      });
      enqueueSnackbar("Failed to fetch ResourceWithType", {
        variant: "error",
      });
    },
  });

  useGetResourceTypeQuery({
    variables: { name: typeName, kind: resourceTypeOfKind(editingKind!) },
    skip: editingComponent == null || !editingComponent.isInline(),
    onCompleted: ({ resourceType }) => {
      setResourceType(resourceType);
    },
    onError: (e) => {
      console.error("Failed to fetch ResourceType", {
        error: e,
        name: typeName,
        kind: editingKind,
      });
      enqueueSnackbar("Failed to fetch ResourceType", {
        variant: "error",
      });
    },
  });

  const [fetchLibraryResources] = useGetResourcesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLibraryResources(
        data.resources.map((r) => ({
          metadata: {
            name: r.metadata.name,
          },
          spec: r.spec,
        })),
      );
    },
    onError: (e) => {
      console.error("Failed to fetch Library Resources", {
        error: e,
      });
      enqueueSnackbar("Failed to fetch Library Resources", {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (open && editingKind != null) {
      setLibraryResources(undefined);
      if (editingComponent?.isInline()) {
        fetchLibraryResources({ variables: { kind: editingKind } });
      }
    }
  }, [open, editingKind, editingComponent, fetchLibraryResources]);

  useEffect(() => {
    if (!open) {
      setResource(null);
      setResourceType(null);
      setLibraryResources(undefined);
    }
  }, [open]);

  async function onAddToLibrary(formValues: FormValues, name: string) {
    await saveComponentToLibrary({
      configuration,
      component: editingComponent!,
      kind: editingKind!,
      formValues,
      name,
      enqueueSnackbar,
      onSuccess: ({ reference }) => {
        onSuccess?.({ operation: "link", resource: reference });
      },
    });
  }

  async function onUnlinkFromLibrary(formValues: FormValues, name: string) {
    await unlinkComponentFromLibrary({
      configuration,
      component: editingComponent!,
      resource: resource!,
      kind: editingKind!,
      formValues,
      name,
      enqueueSnackbar,
      onSuccess: ({ inline }) => {
        onSuccess?.({ operation: "unlink", resource: inline });
      },
    });
  }

  function onClose() {
    onCancel?.();
  }

  function save(formValues: FormValues) {
    saveComponent({
      configuration,
      component: editingComponent!,
      resource: resource ?? undefined,
      kind: editingKind!,
      formValues,
      enqueueSnackbar,
      onSuccess: ({ updated }) => {
        onSuccess?.({ operation: "save", resource: updated });
      },
    });
  }

  useEffect(() => {
    const isEditing = editingComponent != null && open;
    const dataReady =
      resourceType != null &&
      (!showLibraryBookmark || libraryResources != null);

    setAppLoading(isEditing && !dataReady);
  }, [
    setAppLoading,
    open,
    editingComponent,
    resourceType,
    showLibraryBookmark,
    libraryResources,
  ]);

  if (editingComponent == null || resourceType == null) {
    return null;
  }

  function onSave(formValues: FormValues) {
    if (editingComponent == null) {
      // nothing to do
      return;
    }
    const name = editingComponent.name ?? "";
    if (name === "") {
      // no confirmation for inline resources
      save(formValues);
      return;
    }
    confirmResourceInUseWarning({
      kind: editingKind!,
      name,
      onSuccess: () => save(formValues),
    });
  }

  // Currently only sources and destinations can pause/resume
  function supportsPause(): boolean {
    switch (editingKind) {
      case Kind.Source:
      case Kind.Destination:
        return true;
      default:
        return false;
    }
  }

  function togglePause() {
    let operation: "pause" | "resume";
    if (editingComponent?.isInline()) {
      operation = !editingComponent?.disabled ? "pause" : "resume";
    } else {
      operation = !resource?.spec.disabled ? "pause" : "resume";
    }
    togglePaused({
      configuration,
      component: editingComponent!,
      resource: resource ?? undefined,
      kind: editingKind!,
      enqueueSnackbar,
      onSuccess: ({ updated }) => {
        onSuccess?.({ operation, resource: updated });
      },
    });
  }

  async function onTogglePause() {
    if (editingComponent == null) {
      // nothing to do
      return;
    }
    if (editingComponent.isInline()) {
      togglePause();
      return;
    }
    confirmResourceInUseWarning({
      kind: editingKind!,
      name: editingComponent.name ?? "",
      onSuccess: () => togglePause(),
    });
  }

  function onDeleteDefault() {
    confirmDeleteResource({
      kind: editingKind!,
      action: editingComponent?.isInline() ? "delete" : "remove",
      onSuccess: () => {
        deleteComponent({
          configuration,
          component: editingComponent!,
          kind: editingKind!,
          enqueueSnackbar,
          onSuccess: () => {
            onSuccess?.({ operation: "delete", resource: editingComponent! });
          },
        });
      },
    });
  }

  const commonProps = {
    resourceType,
    component: editingComponent,
    readOnly,
    open,
    onSave,
    onDelete: onDelete ?? onDeleteDefault,
    onClose,
    onCancel: onClose,
    showLibraryBookmark,
    onAddToLibrary,
    onUnlinkFromLibrary,
    onTogglePause: supportsPause() ? onTogglePause : undefined,
    libraryResources,
  };

  if (editingComponent.isInline()) {
    return <InlineComponentDialog {...commonProps} kind={editingKind!} />;
  } else if (resource != null) {
    return (
      <LibraryComponentDialog
        {...commonProps}
        kind={editingKind!}
        resource={resource!}
      />
    );
  }
  return null;
};

// ----------------------------------------------------------------------
interface BaseComponentDialogProps {
  kind: ResourceKind;
  resourceType: ResourceType;
  readOnly?: boolean;
  open: boolean;

  // handlers
  onSave: (values: { [key: string]: any }) => void;
  onDelete?: () => void;
  onCancel: () => void;
  onClose: () => void;

  // pause
  paused?: boolean;
  onTogglePause?: () => void;

  // library
  showLibraryBookmark: boolean;
  onAddToLibrary?: (values: { [key: string]: any }, name: string) => void;
  onUnlinkFromLibrary?: (values: { [key: string]: any }, name: string) => void;
  libraryResources?: DialogResource[];
}

// ----------------------------------------------------------------------

interface InlineComponentDialogProps extends BaseComponentDialogProps {
  component: BPResourceConfiguration;
}

const InlineComponentDialog: React.FC<InlineComponentDialogProps> = ({
  kind,
  resourceType,
  component,
  readOnly,
  open,
  onSave,
  onDelete,
  onClose,
  onTogglePause,
  showLibraryBookmark,
  onAddToLibrary,
  onUnlinkFromLibrary,
  libraryResources,
}) => {
  return (
    <EditResourceDialog
      kind={kind}
      displayName={component.displayName ?? ""}
      resourceTypeDisplayName={resourceType?.metadata.displayName ?? ""}
      description={resourceType?.metadata.description ?? ""}
      additionalInfo={resourceType?.metadata.additionalInfo}
      resourceDocLink={resourceType?.metadata.resourceDocLink ?? ""}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onClose}
      parameters={component.parameters ?? []}
      parameterDefinitions={resourceType?.spec.parameters ?? []}
      processors={component.processors ?? []}
      open={open}
      onClose={onClose}
      stability={resourceType?.metadata.stability ?? undefined}
      readOnly={readOnly}
      paused={component.disabled}
      onTogglePause={onTogglePause}
      showLibraryBookmark={
        showLibraryBookmark &&
        onAddToLibrary != null &&
        onUnlinkFromLibrary != null
      }
      onAddToLibrary={onAddToLibrary}
      onUnlinkFromLibrary={onUnlinkFromLibrary}
      libraryResources={libraryResources}
    />
  );
};

// ----------------------------------------------------------------------
interface LibraryComponentDialogProps extends BaseComponentDialogProps {
  component: BPResourceConfiguration;
  resource: ParameterizedResource;
}

const LibraryComponentDialog: React.FC<LibraryComponentDialogProps> = ({
  kind,
  resourceType,
  component,
  resource,
  readOnly,
  open,
  onSave,
  onDelete,
  onClose,
  onTogglePause,
  showLibraryBookmark,
  onAddToLibrary,
  onUnlinkFromLibrary,
  libraryResources,
}) => {
  return (
    <EditResourceDialog
      kind={kind}
      displayName={resource.metadata.displayName ?? ""}
      resourceType={resourceType?.metadata.name ?? ""}
      resourceTypeDisplayName={resourceType?.metadata.displayName ?? ""}
      resourceNameField={resource.metadata.name ?? ""}
      description={resourceType?.metadata.description ?? ""}
      additionalInfo={resourceType?.metadata.additionalInfo}
      resourceDocLink={resourceType?.metadata.resourceDocLink ?? ""}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onClose}
      parameters={resource.spec.parameters ?? []}
      parameterDefinitions={resourceType?.spec.parameters ?? []}
      processors={resource.spec.processors ?? []}
      open={open}
      onClose={onClose}
      stability={resourceType?.metadata.stability ?? undefined}
      readOnly={readOnly}
      paused={resource.spec.disabled}
      onTogglePause={onTogglePause}
      showLibraryBookmark={
        showLibraryBookmark &&
        onAddToLibrary != null &&
        onUnlinkFromLibrary != null
      }
      onAddToLibrary={onAddToLibrary}
      onUnlinkFromLibrary={onUnlinkFromLibrary}
      libraryResources={libraryResources}
    />
  );
};
