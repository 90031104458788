import { enqueueSnackbar } from "notistack";
import { NodeProps } from "reactflow";
import { Kind } from "../../../graphql/generated";
import { BPConfiguration } from "../../../utils/classes";
import { wrapResource } from "../../../utils/classes/resource";
import { RoutingConnectorCard } from "../../Cards/RoutingConnectorCard";
import { useComponentDialog } from "../../Dialogs/hooks/useComponentDialog";
import { useBPGraph } from "../BPGraphProvider";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { useRouting } from "../RoutingContext/RoutingContext";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { V2NodeData } from "../types";

interface GenericRoutingNodeV2Props {
  label: string;
  nodeProps: NodeProps<V2NodeData>;
}

export const GenericRoutingNodeV2: React.FC<GenericRoutingNodeV2Props> = ({
  label,
  nodeProps: { data, id, type, xPos, yPos },
}) => {
  const { attributes } = data;
  const { configuration } = useV2PipelineGraph();
  const { editComponent } = useComponentDialog();
  const { onMouseEnterNode, onMouseExitNode } = useBPGraph();
  const { canConnectToCurrentRouting: canConnect } = useRouting();

  const { componentPath } = attributes;
  const c = new BPConfiguration(configuration!);
  const resource = c.findResource(componentPath);

  const nodeResource = wrapResource(attributes["resource"]);

  function editConnector(componentPath: string) {
    const config = new BPConfiguration(configuration);
    const component = config.findResource(componentPath);
    if (component == null) {
      enqueueSnackbar(`Component ${componentPath} not found in Configuration`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    editComponent({ kind: Kind.Connector, resource: component });
  }

  return (
    <RoutingNodeWrapper
      componentType={"connectors"}
      componentPath={componentPath}
      nodeType={type}
      nodeID={id}
      xPos={xPos}
      yPos={yPos}
      nodeResource={nodeResource}
    >
      <div
        onMouseEnter={() => onMouseEnterNode(id)}
        onMouseLeave={onMouseExitNode}
      >
        <RoutingConnectorCard
          label={label}
          nodeId={id}
          onClick={
            canConnect(componentPath)
              ? () => {}
              : () => editConnector(componentPath)
          }
          resource={resource}
          nodeResource={nodeResource}
        />
      </div>
    </RoutingNodeWrapper>
  );
};
