import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import {
  ComponentDialogProvider,
  useComponentDialog,
} from "../../components/Dialogs/hooks/useComponentDialog";
import {
  DeleteResourcesDialogProvider,
  useDeleteResourcesDialog,
} from "../../components/Dialogs/hooks/useDeleteResourcesDialog";
import {
  NewResourceDialogProvider,
  useNewResourceDialog,
} from "../../components/Dialogs/hooks/useNewResourceDialog";
import {
  SaveToLibraryDialogProvider,
  useSaveToLibraryDialog,
} from "../../components/Dialogs/hooks/useSaveToLibraryDialog";
import { ChevronDown } from "../../components/Icons";
import { DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD } from "../../components/MeasurementControlBar/MeasurementControlBar";
import { ResourceType } from "../../components/ResourceDialog";
import { ConnectorDataGrid } from "../../components/Tables/ConnectorTable/ConnectorDataGrid";
import {
  DestinationsDataGrid,
  DestinationsDataGridProps,
  DestinationsTableField,
} from "../../components/Tables/DestinationsTable/DestinationsDataGrid";
import { ProcessorDataGrid } from "../../components/Tables/ProcessorTable/ProcessorDataGrid";
import { SourceDataGrid } from "../../components/Tables/SourceTable/SourceDataGrid";
import { RBACWrapper } from "../../contexts/RBAC";
import {
  Kind,
  Role,
  useConfigurationTableMetricsSubscription,
  useGetResourcesQuery,
} from "../../graphql/generated";
import { ResourceKind } from "../../types/resources";
import { BPResourceConfiguration } from "../../utils/classes";
import { MinimumDeleteResource } from "../../utils/rest/delete-resources";
import { classes } from "../../utils/styles";
import mixins from "../../styles/mixins.module.scss";
import styles from "./resource-library.module.scss";

interface ResourceLibraryBodyProps {
  kind: ResourceKind;
}

/**
 * Table of reusable components (body)
 */
export const ResourceLibraryBody: React.FC<ResourceLibraryBodyProps> = ({
  kind,
}) => {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const { deleteResources } = useDeleteResourcesDialog();
  const { editComponent } = useComponentDialog();
  const { saveToLibrary } = useSaveToLibraryDialog();
  const { createNewResource, closeNewResourceDialog } = useNewResourceDialog();

  const {
    data: resources,
    loading,
    refetch: refetchResources,
  } = useGetResourcesQuery({
    variables: { kind },
    fetchPolicy: "network-only",
    onError: (e) => {
      console.error(e);
      enqueueSnackbar(`There was an error retrieving ${kind}s.`, {
        variant: "error",
      });
    },
  });

  function handleSaveNew(newResource: BPResourceConfiguration) {
    const bpResource = newResource.toParameterizedResource(kind);
    saveToLibrary({
      bpResource,
      existingNames: resources?.resources.map((r) => r.metadata.name) ?? [],
      onSuccess: (data) => {
        refetchResources();
        closeNewResourceDialog();
      },
    });
  }

  function handleEdit(name: string) {
    editComponent({
      kind,
      resource: new BPResourceConfiguration({
        name,
        disabled: false,
      }),
      onSuccess: () => refetchResources(),
      onDelete: (closeDialog) => {
        deleteResources({
          // this is a little gross, but the deleteButton in ConfigureResourceView.tsx
          // already confirms if the kind is a processor or extension and we don't want to
          // confirm twice. this means that the confirmation dialog will be a little
          // different for processors. ideally ConfigureResourceView would just call
          // onDelete and let confirmation happen in the caller, but we can adjust that
          // later.
          confirm: kind !== Kind.Processor,
          resources: [
            {
              kind,
              metadata: { name },
            },
          ],
          onSuccess: () => {
            refetchResources();
            closeDialog();
          },
        });
      },
    });
  }

  function createNewResourceTypeFilter(resourceTypes: ResourceType[]) {
    if (kind === Kind.Processor) {
      const processorsThatDoNotNeedRouteReceiver =
        resourceTypes?.filter((pt) => {
          return (
            pt.metadata.displayName !== "Extract Metric" &&
            pt.metadata.displayName !== "Count Telemetry" &&
            pt.metadata.displayName !== "Count Logs"
          );
        }) ?? [];
      return processorsThatDoNotNeedRouteReceiver;
    }
    return resourceTypes;
  }

  return (
    <Accordion defaultExpanded className={classes([styles.accordion])}>
      <AccordionSummary expandIcon={<ChevronDown />}>
        <Stack
          direction={"row"}
          flexGrow={1}
          justifyContent={"space-between"}
          marginRight={"8px"}
        >
          <Typography variant="h5">{kind}s</Typography>
          <Stack direction={"row"} spacing={2}>
            <RBACWrapper requiredRole={Role.User}>
              <Button
                data-testid={`add-${kind}`}
                size="small"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation(); // otherwise accordion expands
                  createNewResource({
                    kind,
                    fromLibrary: true,
                    filterResourceTypes: createNewResourceTypeFilter,
                    onSuccess: handleSaveNew,
                  });
                }}
              >
                Add {kind}
              </Button>
            </RBACWrapper>
            {selected.length > 0 && (
              <RBACWrapper requiredRole={Role.User}>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={async (e) => {
                    e.stopPropagation(); // otherwise accordion expands

                    const items = resourcesFromSelected(selected, kind);

                    deleteResources({
                      confirm: true,
                      resources: items,
                      onSuccess: (updates) => {
                        setSelected([]);
                        refetchResources();
                      },
                    });
                  }}
                  classes={{ root: mixins["float-right"] }}
                >
                  Delete {selected.length} {kind}
                  {selected.length > 1 && "s"}
                </Button>
              </RBACWrapper>
            )}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails data-testid={`${kind}-table-details`}>
        {kind === Kind.Source && (
          <SourceDataGrid
            sources={resources?.resources}
            onEditSource={handleEdit}
            hideFooter
            rowSelectionModel={selected}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={setSelected}
          />
        )}
        {kind === Kind.Processor && (
          <ProcessorDataGrid
            processors={resources?.resources}
            onEditProcessor={handleEdit}
            hideFooter
            rowSelectionModel={selected}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={setSelected}
          />
        )}
        {kind === Kind.Connector && (
          <ConnectorDataGrid
            connectors={resources?.resources}
            onEditConnector={handleEdit}
            hideFooter
            rowSelectionModel={selected}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={setSelected}
          />
        )}
        {kind === Kind.Destination && (
          <DestinationsDataLibraryGrid
            columnFields={[
              DestinationsTableField.NAME,
              DestinationsTableField.TYPE,
              DestinationsTableField.CONFIGURATIONS,
              DestinationsTableField.DATA_OUT,
            ]}
            destinations={resources?.resources}
            loading={loading}
            allowSelection
            onEditDestination={handleEdit}
            hideFooter
            rowSelectionModel={selected}
            checkboxSelection
            onRowSelectionModelChange={setSelected}
            setSelectionModel={setSelected}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

interface ResourceLibraryTableProps {
  kind: ResourceKind;
}

/**
 * Table of reusable components
 */
export const ResourceLibraryTable: React.FC<ResourceLibraryTableProps> = ({
  kind,
}) => {
  return (
    <ComponentDialogProvider>
      <NewResourceDialogProvider>
        <DeleteResourcesDialogProvider kind={kind}>
          <SaveToLibraryDialogProvider>
            <ResourceLibraryBody kind={kind} />
          </SaveToLibraryDialogProvider>
        </DeleteResourcesDialogProvider>
      </NewResourceDialogProvider>
    </ComponentDialogProvider>
  );
};

const DestinationsDataLibraryGrid: React.FC<DestinationsDataGridProps> = (
  props,
) => {
  const { data: destinationMetrics } = useConfigurationTableMetricsSubscription(
    {
      variables: {
        period: DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD,
      },
    },
  );
  return (
    <DestinationsDataGrid
      {...props}
      configurationMetrics={destinationMetrics?.overviewMetrics.metrics}
    />
  );
};

export function resourcesFromSelected(
  selected: GridRowSelectionModel,
  kind: Kind,
): MinimumDeleteResource[] {
  return selected.reduce<MinimumDeleteResource[]>((prev, cur) => {
    if (typeof cur !== "string") {
      console.error(`Unexpected type for GridRowId: ${typeof cur}"`);
      return prev;
    }
    const name = cur;

    prev.push({ kind, metadata: { name } });
    return prev;
  }, []);
}
