import { Chip, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { NodeProps } from "reactflow";
import { useOverviewPage } from "../../pages/overview/OverviewPageContext";
import { ConfigurationIcon } from "../Icons";
import { DEFAULT_TELEMETRY_TYPE } from "../MeasurementControlBar";
import { isNodeDisabled } from "../PipelineGraph/Nodes/nodeUtils";
import { useBPGraph } from "../PipelineGraphV2/BPGraphProvider";
import { V2NodeData } from "../PipelineGraphV2/types";
import { ResourceCard } from "./ResourceCard";
import styles from "./cards.module.scss";

export const ConfigurationCard: React.FC<NodeProps<V2NodeData>> = ({
  id,
  data,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEverything = id === "everything/configuration";
  const configurationURL = isEverything
    ? "/configurations"
    : `/configurations/${id.split("/").pop()}`;
  const agentCount = data.attributes.agentCount ?? 0;

  const { selectedTelemetry } = useOverviewPage();

  // hoveredSet logic for the overview page is not currently working but will be revisited in the future
  const { hoveredSet } = useBPGraph();
  const isDisabled = isNodeDisabled(
    selectedTelemetry || DEFAULT_TELEMETRY_TYPE,
    data.attributes,
  );
  const isNotInHoverSet =
    hoveredSet.length > 0 && !hoveredSet.find((elem) => elem === id);
  const disabled = isDisabled || isNotInHoverSet;

  return (
    <div className={disabled ? styles.disabled : undefined}>
      <ResourceCard
        title={data.label ?? ""}
        disabled={disabled}
        altIcon={
          isEverything ? (
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <ConfigurationIcon height="33px" width="33px" />
                <ConfigurationIcon height="33px" width="33px" />
              </Stack>
              <Stack direction="row" spacing={1}>
                <ConfigurationIcon height="19px" width="19px" />
                <ConfigurationIcon height="19px" width="19px" />
                <ConfigurationIcon height="19px" width="19px" />
              </Stack>
            </Stack>
          ) : (
            <ConfigurationIcon height="55px" width="55px" />
          )
        }
        onClick={() =>
          navigate({ pathname: configurationURL, search: location.search })
        }
      />
      <Chip
        classes={{
          root: styles["overview-count-chip"],
          label: styles["overview-count-chip-label"],
        }}
        size="small"
        label={formatAgentCount(agentCount)}
      />
    </div>
  );
};
export function formatAgentCount(agentCount: number): string {
  switch (agentCount) {
    case 0:
      return "";
    case 1:
      return "1 agent";
    default:
      return `${agentCount} agents`;
  }
}
