import { Grid2, Paper, Stack, Typography } from "@mui/material";
import { useSnackbar, EnqueueSnackbar } from "notistack";
import { ComponentProps, useState, useEffect, useCallback } from "react";
import {
  usePreviewFeatureGateQuery,
  PipelineType,
  Kind,
} from "../../graphql/generated";
import { useConfigurationEditData } from "../../hooks/useConfigurationEditData";
import { hasPipelineTypeFlag } from "../../types/configuration";
import { UpdateStatus } from "../../types/resources";
import { BPConfiguration, BPResourceConfiguration } from "../../utils/classes";
import { telemetryTypesParameter } from "../../utils/classes/resource-configuration";
import { periodOptionsFromConfig } from "../../utils/period-options-from-config";
import { ComponentDialogProvider } from "../Dialogs/hooks/useComponentDialog";
import { useNewResourceDialog } from "../Dialogs/hooks/useNewResourceDialog";
import { LiveOrDraftSwitcher } from "../LiveOrDraftSwitcher";
import { DEFAULT_PERIOD } from "../MeasurementControlBar";
import { PeriodButtonGroup } from "../MeasurementControlBar/MeasurementControlBar";
import { PipelineGraph } from "../PipelineGraph/PipelineGraph";
import { PipelineGraphAccordion } from "./PipelineGraphAccordion";
import { PipelineGraphErrorBoundary } from "./PipelineGraphErrorBoundary";
import { V2PipelineGraphProvider } from "./PipelineGraphV2Context";
import { AttributeName } from "./types";
import styles from "./pipeline-graph-v2.module.scss";

type PipelineGraphV2Props = ComponentProps<typeof PipelineGraph>;

export const PipelineGraphV2: React.FC<PipelineGraphV2Props> = ({
  readOnly,
  agentId,
  tab,
  onTabChange,
  showLiveDraftSwitcher,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { createNewResource } = useNewResourceDialog();

  const [period, setPeriod] = useState(DEFAULT_PERIOD);
  const [accordionState, setAccordionState] = useState<{
    logs: boolean;
    metrics: boolean;
    traces: boolean;
  }>();

  const {
    live,
    draft,
    refetch: refetchConfiguration,
  } = useConfigurationEditData();

  const [previewEnabled, setPreviewEnabled] = useState(false);
  usePreviewFeatureGateQuery({
    onCompleted(data) {
      setPreviewEnabled(data.featureGate);
    },
    onError(err) {
      console.error("Error getting feature gate info", {
        error: err,
      });
      enqueueSnackbar("Error getting feature gate info", {
        variant: "error",
        key: "get-feature-gate-error",
      });
    },
  });

  const configuration = tab === "live" ? live : draft;
  const platform = configuration?.metadata.labels?.platform;

  useEffect(() => {
    if (configuration == null || configuration.graph == null) return;
    if (accordionState != null) return;

    const typeFlags =
      configuration.graph.attributes[AttributeName.ActiveTypeFlags];

    setAccordionState({
      logs: hasPipelineTypeFlag("logs", typeFlags),
      metrics: hasPipelineTypeFlag("metrics", typeFlags),
      traces: hasPipelineTypeFlag("traces", typeFlags),
    });
  }, [accordionState, configuration]);

  function handleAccordionToggle(pipelineType: "logs" | "metrics" | "traces") {
    if (!accordionState) return;
    setAccordionState({
      ...accordionState,
      [pipelineType]: !accordionState[pipelineType],
    });
  }

  const onAddSource = useCallback(
    (pipelineType?: PipelineType) => {
      createNewResource({
        kind: Kind.Source,
        defaultParameters: [telemetryTypesParameter(pipelineType)],
        platform,
        pipelineType,
        onSuccess: async (newSource: BPResourceConfiguration) => {
          const updatedConfig = new BPConfiguration(configuration);
          updatedConfig.addSource(newSource);
          await saveConfiguration(
            Kind.Source,
            updatedConfig,
            refetchConfiguration,
            enqueueSnackbar,
          );
        },
        onError: (err) => enqueueSnackbar(err, { variant: "error" }),
      });
    },
    [
      configuration,
      createNewResource,
      enqueueSnackbar,
      refetchConfiguration,
      platform,
    ],
  );

  const onAddDestination = useCallback(
    (pipelineType?: PipelineType) => {
      createNewResource({
        kind: Kind.Destination,
        defaultParameters: [telemetryTypesParameter(pipelineType)],
        platform,
        pipelineType,
        onSuccess: async (newDestination: BPResourceConfiguration) => {
          const updatedConfig = new BPConfiguration(configuration);
          const additionalResources = [];

          const existingDestination = newDestination.type == null;
          if (existingDestination) {
            updatedConfig.addDestination(newDestination);
          } else {
            const { resource, reference } = newDestination.toLibraryResource(
              Kind.Destination,
              newDestination.name ?? "",
              newDestination.displayName ?? undefined,
            );
            updatedConfig.addDestination(reference);
            additionalResources.push(resource);
          }

          await saveConfiguration(
            Kind.Destination,
            updatedConfig,
            refetchConfiguration,
            enqueueSnackbar,
            ...additionalResources,
          );
        },
        onError: (err) => enqueueSnackbar(err, { variant: "error" }),
      });
    },
    [
      configuration,
      createNewResource,
      enqueueSnackbar,
      refetchConfiguration,
      platform,
    ],
  );

  return (
    <PipelineGraphErrorBoundary>
      <V2PipelineGraphProvider
        period={period}
        configuration={configuration}
        refetchConfiguration={refetchConfiguration}
        onAddSource={onAddSource}
        onAddDestination={onAddDestination}
        readOnly={readOnly}
        agentID={agentId}
      >
        <ComponentDialogProvider
          configuration={configuration}
          onSuccess={() => refetchConfiguration()}
          readOnly={readOnly}
        >
          <GraphContainer>
            {/** Live/Draft and Period Button Group */}
            <Grid2 container width={"100%"} marginBottom={2}>
              <Grid2 size={4}>
                <Stack height="100%" justifyContent="center">
                  <Typography marginLeft={1} variant="h5">
                    Pipelines
                  </Typography>
                </Stack>
              </Grid2>
              <Grid2
                size={4}
                justifyContent={"center"}
                alignItems="center"
                container
              >
                {showLiveDraftSwitcher && (
                  <LiveOrDraftSwitcher tab={tab} onChange={onTabChange} />
                )}
              </Grid2>
              <Grid2
                size={4}
                justifyContent="flex-end"
                alignItems="center"
                container
              >
                <PeriodButtonGroup
                  period={period}
                  onPeriodChange={setPeriod}
                  periodOptions={periodOptionsFromConfig(configuration)}
                />
              </Grid2>
            </Grid2>

            <Stack spacing={1}>
              <PipelineGraphAccordion
                loading={configuration == null}
                period={period}
                pipelineType={PipelineType.Logs}
                processorPreviewEnabled={previewEnabled}
                expanded={accordionState?.logs ?? false}
                onChange={() => handleAccordionToggle("logs")}
              />
              <PipelineGraphAccordion
                loading={configuration == null}
                period={period}
                pipelineType={PipelineType.Metrics}
                processorPreviewEnabled={previewEnabled}
                expanded={accordionState?.metrics ?? false}
                onChange={() => handleAccordionToggle("metrics")}
              />
              <PipelineGraphAccordion
                loading={configuration == null}
                period={period}
                pipelineType={PipelineType.Traces}
                processorPreviewEnabled={previewEnabled}
                expanded={accordionState?.traces ?? false}
                onChange={() => handleAccordionToggle("traces")}
              />
            </Stack>
          </GraphContainer>
        </ComponentDialogProvider>
      </V2PipelineGraphProvider>
    </PipelineGraphErrorBoundary>
  );
};

const GraphContainer: React.FC = ({ children }) => {
  return (
    <Paper classes={{ root: styles.container }} elevation={1}>
      {children}
    </Paper>
  );
};

async function saveConfiguration(
  kind: Kind,
  updatedConfig: BPConfiguration,
  refetchConfiguration: () => void,
  enqueueSnackbar: EnqueueSnackbar,
  ...additionalResources: any[]
) {
  try {
    const update = await updatedConfig.apply(...additionalResources);
    if (update.status === UpdateStatus.INVALID) {
      console.error(update);
      throw new Error(`failed to add ${kind} to configuration.`, {
        cause: update.reason,
      });
    }
    refetchConfiguration();
  } catch (err) {
    console.error("Failed to save configuration", {
      error: err,
      kind,
      configurationName: updatedConfig.metadata.name,
    });
    enqueueSnackbar(`Failed to save ${kind}.`, {
      variant: "error",
    });
  }
}
